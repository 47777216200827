export const relativeTime = (time: string, isGetFullYear = false) => {
  const now = new Date();
  const now_timestamp = now.getTime();
  const datetime = new Date(time);
  const timestamp = new Date(time).getTime();
  const diff = now_timestamp - timestamp;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (seconds < 60) {
    return `Vừa xong`;
  } else if (minutes < 60) {
    return `${minutes} phút trước`;
  } else if (hours < 7) {
    return `${hours} giờ trước`;
  } else if (datetime.getFullYear() === now.getFullYear() && !isGetFullYear) {
    return (
      datetime.getDate().toString().padStart(2, "0") +
      "/" +
      (datetime.getMonth() + 1).toString().padStart(2, "0")
    );
  } else {
    return (
      datetime.getDate().toString().padStart(2, "0") +
      "/" +
      (datetime.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      datetime.getFullYear()
    );
  }
};
