<template>
  <div class="chat-list">
    <chat-item
      v-for="key in dataSource.keys()"
      :key="key"
      :data="dataSource.get(key)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ChatItem from "@/scopes/marketplace/components/mobile/list/ChatItem.vue";

@Component({
  components: { ChatItem },
})
export default class ChatList extends Vue {
  @Prop({ required: true }) private readonly dataSource!: Map<string, any>;
}
</script>
