<template>
  <div
    class="chat-item"
    :class="{ 'chat-item__active': data.id === roomId }"
    @click="onSelect"
  >
    <div class="chat-item__avatar">
      <img :src="data.avatar" alt="" />
    </div>

    <div class="chat-item__info">
      <div class="chat-item__room">
        <div class="chat-item__name">{{ data.name }}</div>

        <div class="chat-item__time" v-if="data.lastMessage">
          {{ data.lastMessage.ts | relative }}
        </div>
      </div>

      <div class="chat-item__message" v-if="data.lastMessage">
        <div class="chat-item__message__text">
          <span v-if="!data.receive">Bạn: </span>{{ data.lastMessage.msg }}
        </div>

        <div class="chat-item__count" v-if="data.unread">{{ data.unread }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import ChatDetailMixin from "@/mixins/ChatDetail.mixin";

@Component
export default class ChatItem extends Mixins(ChatDetailMixin) {
  @Prop({ required: true }) private readonly data!: any;
  @Prop({ required: false }) private readonly area!: string | null;

  public onSelect() {
    this.$emit("select");

    this.$store.dispatch("marketplace/navigator/SET_CURRENT_ROOM", {
      id: this.data.id,
      type: this.data.type,
      area: this.area,
    });
  }
}
</script>
