<template>
  <div class="operator-chat-list-left-conversation list-user">
    <div @click="goBack" class="mb-3 icon">
      <IconAngleDown />Chọn người được chỉ định xử lý
    </div>
    <div class="operator-chat-list-left-search-order fl-column mr-0">
      <div class="search-container">
        <input
          class="input-custom"
          type="text"
          v-model="keySearchData"
          @keyup="onSearchTextChange()"
          placeholder="Tìm kiếm"
        />
        <IconSearch class="search-icon" />
      </div>
      <ul class="item-list" v-if="dataFilter.length">
        <li
          v-for="item in dataFilter"
          :key="item.id"
          class="item-list__item"
          @click="selectItem(item)"
        >
          {{ item.name }}
          <span v-if="item.department_name?.trim()"
            >- {{ item.department_name }}</span
          >
        </li>
      </ul>
      <div v-else class="no-content">Không tìm thấy dữ liệu</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import IconAngleDown from "@/assets/images/operator/iconAngleDown.vue";
import IconChecked from "@/assets/images/operator/iconChecked.vue";
import IconSearch from "@/assets/images/operator/iconSearch.vue";
import ChatDetailController from "@/controllers/ChatDetail.controller";
import ChatItem from "@/scopes/operator/components/list/ChatItem.vue";
import { debounce } from "lodash";

@Component({
  components: {
    ChatItem,
    IconAngleDown,
    IconChecked,
    IconSearch,
  },
})
export default class ListUser extends Vue {
  keySearchData = "";
  dataFilter: any[] = [];

  dataSelected: any = {};

  get conversationDetail() {
    return this.$store.state.global.conversationDetail;
  }
  onSearchTextChange() {
    this.filterData();
  }
  private filterData = debounce(() => {
    this.getData();
  }, 300);
  goBack() {
    this.$emit("isShow", false);
  }
  async getData() {
    const response = await this.listAssignee({
      key_search: this.keySearchData,
      per_page: 20,
    });
    this.dataFilter = response?.data;
  }
  created() {
    this.getData();
  }
  selectItem(item: any) {
    this.dataSelected = item;
    this.$emit("choose", item);
  }
  public async listAssignee(params: any) {
    const data = await new ChatDetailController(
      this.conversationDetail?.roomId
    ).listAssignee(params);
    return data.data;
  }
}
</script>
