<template>
  <transition mode="out-in">
    <div
      class="chat-product-message"
      v-if="data && !isLoading"
      @click="openPDP"
    >
      <div class="chat-product-message__image">
        <img :src="data.image" alt="" @error="handleErrorImage" />
      </div>

      <div class="chat-product-message__info">
        <div class="chat-product-message__name">{{ data.name }}</div>

        <div class="chat-product-message__packing">{{ data.package }}</div>

        <div class="chat-product-message__price">
          {{ data.pack_price | currency }}
        </div>
      </div>
    </div>

    <div class="chat-product-message--loading" v-else>
      <div class="chat-product-message--loading__image"></div>

      <div class="chat-product-message--loading__info">
        <div class="chat-product-message--loading__name"></div>
        <div class="chat-product-message--loading__packing"></div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { IProductAttachment } from "@/interfaces/marketplace/message.interface";
import PreviewImage from "@/assets/images/marketplace/preview.svg";

@Component
export default class ProductMessage extends Vue {
  isLoading = false;
  data: IProductAttachment | null = null;

  @Prop({ required: true }) private readonly message!: any;

  public created() {
    this.getProduct();
  }

  public async getProduct() {
    const url = this.message.content;

    try {
      this.isLoading = true;
      const response = await this.$httpMarketplace.get(url);

      this.data = response.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  public openPDP() {
    window.dispatchEvent(
      new CustomEvent("dispatch", {
        detail: { name: "openPDP", body: { id: this.data?.id } },
      })
    );
  }

  public handleErrorImage(e: any) {
    e.target.src = PreviewImage;
  }
}
</script>
