<template>
  <div class="chat-not-support-message">
    <div class="chat-not-support-message__icon">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_24667_196406)">
          <path
            d="M35.1939 7.96943L34.451 7.08575L36.6634 5.90096L26.3833 2.42188L29.8623 12.3846L31.129 10.7252C31.129 10.7252 37.7795 22.4291 31.129 29.5444C24.5243 36.6105 16.738 35.0788 16.738 35.0788L18.2403 39.9816C18.2403 39.9816 30.4973 40.8522 37.0594 29.78C43.6216 18.7111 35.1939 7.96943 35.1939 7.96943Z"
            fill="#9CA5B3"
          />
          <path
            d="M4.36645 32.5283L5.1094 33.4119L2.89692 34.5967L13.1771 38.0758L9.698 28.1131L8.43139 29.7725C8.43139 29.7725 1.78087 18.0686 8.43139 10.9533C15.0361 3.88716 22.8223 5.41887 22.8223 5.41887L21.3201 0.516076C21.3201 0.516076 9.06306 -0.354516 2.5009 10.7177C-4.06125 21.7866 4.36645 32.5283 4.36645 32.5283Z"
            fill="#9CA5B3"
          />
          <path
            d="M35.1939 7.96943L34.451 7.08575L36.6634 5.90096L26.3833 2.42188L29.8623 12.3846L31.129 10.7252C31.129 10.7252 37.7795 22.4291 31.129 29.5444C24.5243 36.6105 16.738 35.0788 16.738 35.0788L18.2403 39.9816C18.2403 39.9816 30.4973 40.8522 37.0594 29.78C43.6216 18.7111 35.1939 7.96943 35.1939 7.96943Z"
            fill="#9CA5B3"
          />
          <path
            d="M4.36645 32.5283L5.1094 33.4119L2.89692 34.5967L13.1771 38.0758L9.698 28.1131L8.43139 29.7725C8.43139 29.7725 1.78087 18.0686 8.43139 10.9533C15.0361 3.88716 22.8223 5.41887 22.8223 5.41887L21.3201 0.516076C21.3201 0.516076 9.06306 -0.354516 2.5009 10.7177C-4.06125 21.7866 4.36645 32.5283 4.36645 32.5283Z"
            fill="#9CA5B3"
          />
          <g opacity="0.8">
            <path
              d="M35.1937 7.96943L34.4507 7.08575L36.6632 5.90096L26.383 2.42188L29.8621 12.3846L31.1287 10.7252C31.1287 10.7252 37.7792 22.4291 31.1287 29.5444C24.524 36.6105 16.7378 35.0788 16.7378 35.0788L18.2401 39.9816C18.2401 39.9816 30.497 40.8522 37.0592 29.78C43.6214 18.7111 35.1937 7.96943 35.1937 7.96943Z"
              fill="white"
            />
          </g>
          <g opacity="0.8">
            <path
              d="M4.36621 32.5283L5.10916 33.4119L2.89668 34.5967L13.1768 38.0758L9.69776 28.1131L8.43115 29.7725C8.43115 29.7725 1.78062 18.0686 8.43115 10.9533C15.0359 3.88716 22.8221 5.41887 22.8221 5.41887L21.3198 0.516076C21.3198 0.516076 9.06281 -0.354516 2.50066 10.7177C-4.0615 21.7866 4.36621 32.5283 4.36621 32.5283Z"
              fill="white"
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.6399 14.4394C26.1341 14.9664 26.5465 15.5522 26.8771 16.1741L28.8539 16.1675L29.587 19.352L27.8066 20.2095C27.7837 20.9132 27.6691 21.6201 27.4564 22.3107L28.8572 23.7017L27.1226 26.4739L25.257 25.8225C24.7301 26.3168 24.1442 26.7291 23.5224 27.0597L23.5289 29.0365L20.3444 29.7697L19.4869 27.9892C18.7832 27.9663 18.0763 27.8517 17.3857 27.6357L15.9947 29.0365L13.2226 27.3052L13.8739 25.4396C13.3797 24.9127 12.9673 24.3268 12.6367 23.705L10.6599 23.7115L9.92676 20.527L11.7072 19.6695C11.7301 18.9658 11.8447 18.2589 12.0574 17.5683L10.6566 16.1773L12.388 13.4052L14.2535 14.0565C14.7805 13.5623 15.3663 13.1499 15.9882 12.8193L15.9816 10.8425L19.1661 10.1094L20.0236 11.8898C20.7273 11.9127 21.4343 12.0273 22.1248 12.24L23.5158 10.8392L26.288 12.5706L25.6367 14.4361L25.6399 14.4394ZM21.7419 17.0479C20.0989 16.0202 17.9355 16.521 16.9111 18.1607C15.8834 19.8037 16.3842 21.9671 18.0239 22.9915C19.6669 24.0192 21.8303 23.5184 22.8547 21.8787C23.8824 20.2357 23.3816 18.0723 21.7419 17.0479Z"
            fill="#E8505B"
          />
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.7426 17.0497C20.0996 16.022 17.9362 16.5228 16.9118 18.1625C15.8841 19.8055 16.3848 21.9689 18.0246 22.9933C19.6676 24.021 21.8309 23.5202 22.8554 21.8805C23.883 20.2375 23.3823 18.0741 21.7426 17.0497Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_24667_196406">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <div class="chat-not-support-message__content">
      <div class="chat-not-support-message__title">Phiên bản không hỗ trợ.</div>
      <div class="chat-not-support-message__description">
        Phiên bản không hỗ trợ định dạng này. Vui lòng tải ứng dụng KiotViet
        Connect.
      </div>

      <a
        href="https://onelink.to/qbsub6"
        class="chat-not-support-message__button"
        @click="handleClickDownloadApp"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_24667_196424)">
            <path
              d="M11 8H14C14.1326 8 14.2598 8.05268 14.3536 8.14645C14.4473 8.24021 14.5 8.36739 14.5 8.5V12.5C14.5 12.6326 14.4473 12.7598 14.3536 12.8536C14.2598 12.9473 14.1326 13 14 13H2C1.86739 13 1.74021 12.9473 1.64645 12.8536C1.55268 12.7598 1.5 12.6326 1.5 12.5V8.5C1.5 8.36739 1.55268 8.24021 1.64645 8.14645C1.74021 8.05268 1.86739 8 2 8H5"
              stroke="#41AD49"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 1.5V8"
              stroke="#41AD49"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 5L8 8L11 5"
              stroke="#41AD49"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <mask id="path-4-inside-1_24667_196424" fill="white">
              <path
                d="M11.75 11C12.0261 11 12.25 10.7761 12.25 10.5C12.25 10.2239 12.0261 10 11.75 10C11.4739 10 11.25 10.2239 11.25 10.5C11.25 10.7761 11.4739 11 11.75 11Z"
              />
            </mask>
            <path
              d="M11.75 11C12.0261 11 12.25 10.7761 12.25 10.5C12.25 10.2239 12.0261 10 11.75 10C11.4739 10 11.25 10.2239 11.25 10.5C11.25 10.7761 11.4739 11 11.75 11Z"
              fill="#41AD49"
            />
            <path
              d="M11.25 10.5C11.25 10.2239 11.4739 10 11.75 10V12C12.5784 12 13.25 11.3284 13.25 10.5H11.25ZM11.75 10C12.0261 10 12.25 10.2239 12.25 10.5H10.25C10.25 11.3284 10.9216 12 11.75 12V10ZM12.25 10.5C12.25 10.7761 12.0261 11 11.75 11V9C10.9216 9 10.25 9.67157 10.25 10.5H12.25ZM11.75 11C11.4739 11 11.25 10.7761 11.25 10.5H13.25C13.25 9.67157 12.5784 9 11.75 9V11Z"
              fill="#41AD49"
              mask="url(#path-4-inside-1_24667_196424)"
            />
          </g>
          <defs>
            <clipPath id="clip0_24667_196424">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span>Tải ứng dụng</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NotSupportMessage extends Vue {
  public handleClickDownloadApp(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const device = this.$store.state.global.device;

    if (device === "mobile") {
      window.open("https://onelink.to/qbsub6", "_blank");
    } else {
      window.dispatchEvent(
        new CustomEvent("dispatch", {
          detail: { name: "openDownloadAppModal" },
        })
      );
    }
  }
}
</script>
