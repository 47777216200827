<template>
  <div>
    <div class="modal">
      <div class="modal-content modal-preview" ref="modal">
        <div class="content">
          <img :src="url" alt="" />
          <div class="close-modal" @click="closeModal">
            <IconClose />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import IconClose from "@/assets/images/operator/iconClose.vue";

@Component({
  components: {
    IconClose,
  },
})
export default class ModalPreviewImage extends Vue {
  @Prop({ required: true }) readonly url!: string;
  closeModal() {
    this.$emit("closeModal", false);
  }

  updateModal() {
    this.$emit("updateModal", false);
  }
}
</script>
