<template>
  <transition mode="out-in">
    <div
      class="chat-order-message"
      v-if="data && !isLoading"
      @click="openOrderDetail"
    >
      <div class="chat-order-message__title">
        <p class="text">Đơn hàng</p>
        <div class="chat-order-message__status" :class="statusClass()">
          {{ data.status_text }}
        </div>
      </div>
      <div class="chat-order-message__data">
        <div class="chat-order-message__image">
          <img :src="data.image" alt="" @error="handleErrorImage" />
        </div>

        <div class="chat-order-message__info">
          <div class="chat-order-message__code">Mã đơn: {{ data.code }}</div>
          <div class="chat-order-message__price">
            Tổng đơn hàng: {{ data.total | currency }}
          </div>
        </div>
      </div>
      <div class="chat-order-message__detail">Xem chi tiết</div>
    </div>

    <div class="chat-order-message--loading" v-else>
      <div class="chat-order-message--loading__image"></div>

      <div class="chat-order-message--loading__info">
        <div class="chat-order-message--loading__name"></div>
        <div class="chat-order-message--loading__packing"></div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
const STATUS = {
  AWAITING_ORDER_CONFIRMATION: 0, // 'Chờ xác nhận',
  CANCELLED: 1, // 'Đã hủy',
  CONFIRMED: 2, // 'Đã xác nhận',
  PROCESSING: 3, // 'Đang xử lý',
  COMPLETED: 4, // 'Hoàn thành',
  AWAITING_ORDER_APPROVE: 5, // 'Chờ thanh toán',
  CANCEL_APPROVED: 6, // 'Đã hủy duyệt',
};
const STATUS_ORDER = new Map([
  [STATUS.AWAITING_ORDER_APPROVE, "process"],
  [STATUS.AWAITING_ORDER_CONFIRMATION, "wait"],
  [STATUS.COMPLETED, "success"],
  [STATUS.CANCELLED, "cancel"],
  [STATUS.CANCEL_APPROVED, "cancel"],
  [STATUS.PROCESSING, "process"],
  [STATUS.CONFIRMED, "process"],
]);

import { Component, Prop, Vue } from "vue-property-decorator";
import { IOrderAttachment } from "@/interfaces/marketplace/message.interface";
import PreviewImage from "@/assets/images/marketplace/preview.svg";

@Component
export default class OrderMessage extends Vue {
  isLoading = false;
  data: IOrderAttachment | null = null;

  @Prop({ required: true }) private readonly message!: any;

  public created() {
    this.getOrder();
  }

  public async getOrder() {
    const url = this.message.content;
    try {
      this.isLoading = true;
      const response = await this.$httpMarketplace.get(url);

      this.data = response.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  public openOrderDetail() {
    window.dispatchEvent(
      new CustomEvent("dispatch", {
        detail: {
          name: "openOrderDetail",
          body: { id: this.data?.refer_id },
        },
      })
    );
  }

  public statusClass() {
    return "order-status-" + STATUS_ORDER.get(this.data?.status);
  }

  public handleErrorImage(e: any) {
    e.target.src = PreviewImage;
  }
}
</script>
