<template>
  <div class="filter-search" ref="filterBlock">
    <div class="filter-search__content" @click.prevent="showBlock = !showBlock">
      <div class="title">{{ title }}</div>
      <div class="icon">
        <IconAngleDown />
      </div>
    </div>
    <div v-if="showBlock" class="filter-search__block-search">
      <div class="search-input">
        <input
          @keyup.enter="filterData"
          class="search-input__input-search"
          type="text"
          v-model="keySearchData"
        />
        <span class="search-input__search-icon">
          <IconSearch />
        </span>
      </div>
      <div class="block-content">
        <ul class="item-list" v-if="dataFilter.length">
          <li
            v-for="item in dataFilter"
            :key="item.id"
            class="item-list__item"
            :class="{ active: checkedItem(item) }"
            @click="selectItem(item)"
          >
            <div class="item__name">
              {{ item.name }}
              <span v-if="item.department_name && item.department_name.trim()"
                >- {{ item.department_name }}</span
              >
            </div>
            <div v-if="checkedItem(item)">
              <IconChecked />
            </div>
          </li>
        </ul>
        <div v-else class="no-content">Không tìm thấy dữ liệu</div>
      </div>
      <div class="cancel-item" @click="cancelAssignee">Bỏ chỉ định</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import IconAngleDown from "@/assets/images/operator/iconAngleDown.vue";
import IconChecked from "@/assets/images/operator/iconChecked.vue";
import IconSearch from "@/assets/images/operator/iconSearch.vue";
import ChatDetailController from "@/controllers/ChatDetail.controller";
import { checkPermission } from "@/helpers/permission.helper";
import PERMISSION from "@/constants/permission";
import CommonMixin from "@/mixins/operator/Common.mixin";

@Component({
  components: {
    IconAngleDown,
    IconChecked,
    IconSearch,
  },
  mixins: [CommonMixin],
})
export default class FilterAssignUser extends Vue {
  @Prop({ required: false }) private readonly assignee!: any;
  @Prop({ required: false }) private readonly conversationDetail!: any;
  @Prop({ required: false }) private readonly roomId!: any;
  defaultTitle = "Chỉ định cuộc trò chuyện";
  title = this.defaultTitle;
  showBlock = false;
  keySearchData = "";
  dataFilter: any[] = [];

  dataSelected: any = {};

  filterData() {
    this.getData();
  }

  async getData() {
    const response = await this.listAssignee({
      key_search: this.keySearchData,
    });
    if (response) {
      this.dataFilter = response.data;
      if (this.dataSelected && !this.keySearchData) {
        this.setDataSelected();
      }
    }
  }

  created() {
    this.getData();
    if (this.conversationDetail && this.conversationDetail.userCurator) {
      this.dataSelected = JSON.parse(
        JSON.stringify(this.conversationDetail.userCurator)
      );
      this.setDataSelected();
    } else {
      this.dataSelected = {};
      this.title = this.defaultTitle;
    }
  }

  checkedItem(item: any) {
    return Number(item.id) === Number(this.dataSelected.id);
  }

  async selectItem(item: any) {
    const hasPermission = await checkPermission(PERMISSION.CHAT_ASSIGN_USER);
    if (!hasPermission) {
      (this as any).toastCustom(
        "Bạn không có quyền truy cập tính năng này",
        "error"
      );
      return;
    }
    this.dataSelected = item;
    this.showBlock = false;
    this.assignUserConversation(item);
    this.title = this.getAssigneeName(item);
  }

  async cancelAssignee() {
    const hasPermission = await checkPermission(PERMISSION.CHAT_ASSIGN_USER);
    if (!hasPermission) {
      (this as any).toastCustom(
        "Bạn không có quyền truy cập tính năng này",
        "error"
      );
      return;
    }
    this.title = this.defaultTitle;
    this.showBlock = false;
    this.dataSelected = {};

    if (this.roomId) {
      const customFields = this.conversationDetail.customFields
        ? this.conversationDetail.customFields
        : {};
      let oldCurator = customFields["userCurator"]
        ? customFields["userCurator"]
        : null;
      customFields["userCurator"] = null;
      customFields["user_joined"] = customFields["user_joined"]
        ? customFields["user_joined"]
        : [];
      if (oldCurator.id_rocket_chat) {
        let idRocketChat = oldCurator.id_rocket_chat;
        let index = customFields["user_joined"].findIndex(
          (item: any) => item == idRocketChat
        );
        if (index > -1) {
          customFields["user_joined"].splice(index, 1);
        }
      }
      const data = await new ChatDetailController(
        this.roomId
      ).assignUserConversation({
        roomId: this.roomId,
        customFields: customFields,
        roomName: this.conversationDetail.roomName,
      });
      if (data && data.success) {
        // update vào store trạng thái userCurator của conversation
        this.$store.dispatch("global/UPDATE_CONVERSATION_DETAIL_USER_CURATOR", {
          roomId: this.roomId,
          customFields: customFields,
          userCurator: null,
        });
      }
    }
  }

  getAssigneeName(assignee: any) {
    if (!assignee) return "";
    return (
      assignee.name +
      (assignee.department_name && assignee.department_name.trim()
        ? "-" + assignee.department_name
        : "")
    );
  }

  setDataSelected() {
    if (!this.dataSelected || !Object.keys(this.dataSelected).length) return;
    const index = this.dataFilter.findIndex(
      (v) => v.id === this.dataSelected.id
    );
    if (index === -1) this.dataFilter.unshift(this.dataSelected);
    this.title = this.getAssigneeName(this.conversationDetail.userCurator);
  }

  public async listAssignee(params: any) {
    if (this.roomId) {
      const data = await new ChatDetailController(this.roomId).listAssignee(
        params
      );
      return data.data;
    }
  }

  public async assignUserConversation(payload: any) {
    const hasPermission = await checkPermission(PERMISSION.CHAT_ASSIGN_USER);
    if (!hasPermission) {
      (this as any).toastCustom(
        "Bạn không có quyền truy cập tính năng này",
        "error"
      );
      return;
    }
    if (this.roomId) {
      const customFields = this.conversationDetail.customFields
        ? this.conversationDetail.customFields
        : {};
      customFields["userCurator"] = payload;
      customFields["user_joined"] = customFields["user_joined"]
        ? customFields["user_joined"]
        : [];
      if (payload.id_rocket_chat) {
        customFields["user_joined"].push(payload.id_rocket_chat);
      }
      const data = await new ChatDetailController(
        this.roomId
      ).assignUserConversation({
        roomId: this.roomId,
        customFields: customFields,
        roomName: this.conversationDetail.roomName,
      });
      if (data && data.success) {
        // update vào store trạng thái userCurator của conversation
        this.$store.dispatch("global/UPDATE_CONVERSATION_DETAIL_USER_CURATOR", {
          roomId: this.roomId,
          customFields: customFields,
          userCurator: payload,
        });
      }
    }
  }

  @Watch("conversationDetail", { deep: true })
  public reAddDataSelected(val: any) {
    if (val) {
      if (this.conversationDetail && this.conversationDetail.userCurator) {
        this.dataSelected = JSON.parse(
          JSON.stringify(this.conversationDetail.userCurator)
        );
        this.setDataSelected();
      } else {
        this.dataSelected = {};
        this.title = this.defaultTitle;
      }
    }
  }
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  }
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
  handleClickOutside(event: { target: any }) {
    if (
      this.$refs.filterBlock &&
      !(this.$refs.filterBlock as any).contains(event.target)
    ) {
      this.showBlock = false;
    }
  }
}
</script>
