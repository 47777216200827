export const checkPermission = async (permissionCode: any) => {
  try {
    if (!window.CHAT_SDK.checkPermission) return true;
    const hasPermission: boolean = await window.CHAT_SDK.checkPermission(
      permissionCode
    );
    return hasPermission;
  } catch (e) {
    return false;
  }
};
