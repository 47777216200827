<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3473 2.52798L16.4348 19.9561C16.3528 20.2512 16.182 20.5139 15.9455 20.7085C15.709 20.9031 15.4183 21.0201 15.1129 21.0436H14.9816C14.6991 21.043 14.4225 20.9626 14.1837 20.8116C13.9448 20.6607 13.7535 20.4454 13.6316 20.1905L10.2848 13.1217C10.2519 13.0508 10.2415 12.9714 10.2549 12.8944C10.2683 12.8173 10.3049 12.7462 10.3598 12.6905L15.1691 7.8811C15.2444 7.81288 15.3051 7.73008 15.3474 7.63771C15.3897 7.54535 15.4128 7.44534 15.4153 7.34378C15.4178 7.24221 15.3997 7.14119 15.3619 7.04685C15.3242 6.95252 15.2677 6.86683 15.1959 6.79499C15.124 6.72314 15.0384 6.66665 14.944 6.62893C14.8497 6.5912 14.7487 6.57304 14.6471 6.57554C14.5455 6.57804 14.4455 6.60115 14.3532 6.64347C14.2608 6.68579 14.178 6.74644 14.1098 6.82173L9.3004 11.6405C9.24468 11.6954 9.17355 11.732 9.09649 11.7454C9.01943 11.7588 8.94012 11.7483 8.86915 11.7155L1.8379 8.38735C1.5627 8.25737 1.33166 8.04955 1.17336 7.78961C1.01506 7.52966 0.936455 7.22901 0.947273 6.92485C0.963867 6.61169 1.07881 6.31173 1.27574 6.06767C1.47267 5.82361 1.74156 5.64788 2.04415 5.56548L19.5098 0.643602C19.8153 0.556362 20.1405 0.568365 20.4388 0.67788C20.737 0.787396 20.9927 0.988769 21.1691 1.25298C21.2875 1.44073 21.3646 1.6515 21.3953 1.87132C21.426 2.09114 21.4097 2.31497 21.3473 2.52798Z"
      :fill="isActive ? '#0062C2' : '#9CA5B3'"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconSend extends Vue {
  @Prop({ required: true, default: false })
  private readonly isActive!: boolean;
}
</script>
