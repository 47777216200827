<template>
  <page>
    <template #header-cta>
      <div id="chat-list-lure"></div>
    </template>

    <template #header>Danh sách tin nhắn</template>

    <template #body>
      <chat-list :data-source="dataSource" />
    </template>
  </page>
</template>

<script>
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/scopes/marketplace/device/mobile/components/layouts/Page.vue";
import ChatList from "@/scopes/marketplace/components/mobile/list/ChatList.vue";
import MarketplaceChatListMixin from "@/mixins/marketplace/ChatList.mixin";

@Component({
  components: { ChatList, Page },
})
export default class UserListPage extends Mixins(MarketplaceChatListMixin) {
  mounted() {
    window.dispatchEvent(new Event("initChatListLure"));

    // window.$redirectApp({
    //   screen: "chat-list",
    // });
  }
}
</script>
