export enum QuotationRequestStatus {
  InquiryWaitingQuotationStatus = 0,
  InquiryQuotedStatus = 2,
  InquiryCompleteStatus = 4,
  InquiryDeclineStatus = 1,
  InquiryExpiredStatus = 5,
  InquiryClosedStatus = 3,
  InquiryWaitingApproveStatus = 6,
  InquiryCancelApproveStatus = 7,
}

export const QuotationRequestStatusText = {
  [QuotationRequestStatus.InquiryWaitingQuotationStatus]: "Chờ báo giá",
  [QuotationRequestStatus.InquiryWaitingApproveStatus]: "Chờ báo giá",
  [QuotationRequestStatus.InquiryQuotedStatus]: "Đã báo giá",
  [QuotationRequestStatus.InquiryCompleteStatus]: "Hoàn thành",
  [QuotationRequestStatus.InquiryDeclineStatus]: "Từ chối",
  [QuotationRequestStatus.InquiryCancelApproveStatus]: "Từ chối",
  [QuotationRequestStatus.InquiryExpiredStatus]: "Hết hạn",
  [QuotationRequestStatus.InquiryClosedStatus]: "Đã đóng",
};

export const QuotationRequestStatusClassName = {
  [QuotationRequestStatus.InquiryWaitingQuotationStatus]:
    "inquiry__block-status inquiry__waiting-quotation",
  [QuotationRequestStatus.InquiryWaitingApproveStatus]:
    "inquiry__block-status inquiry__waiting-approve",
  [QuotationRequestStatus.InquiryQuotedStatus]:
    "inquiry__block-status inquiry__quoted",
  [QuotationRequestStatus.InquiryCompleteStatus]:
    "inquiry__block-status inquiry__complete",
  [QuotationRequestStatus.InquiryDeclineStatus]:
    "inquiry__block-status inquiry__decline",
  [QuotationRequestStatus.InquiryCancelApproveStatus]:
    "inquiry__block-status inquiry__cancel-approve",
  [QuotationRequestStatus.InquiryExpiredStatus]:
    "inquiry__block-status inquiry__expired",
  [QuotationRequestStatus.InquiryClosedStatus]:
    "inquiry__block-status inquiry__closed",
};
