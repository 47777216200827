import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import Scope from "@/scope";

export class MarketAPI {
  protected http: AxiosInstance;

  constructor(authentication?: boolean, accessToken?: string) {
    const http = axios.create({
      timeout: 15000,
    });

    http.interceptors.request.use((config: AxiosRequestConfig): any => {
      (config.headers as AxiosHeaders).set(
        "Actor-Type",
        Scope.MARKETPLACE.toLowerCase()
      );
      if (authentication) {
        config.baseURL = process.env.VUE_APP_MKP_API + "api/v1";
        (config.headers as AxiosHeaders).set(
          "Authorization",
          `Bearer ${accessToken}`
        );
      }

      return config;
    });

    http.interceptors.response.use(
      (response: AxiosResponse) => response.data,
      (error: AxiosError) => {
        console.log(error);
      }
    );

    this.http = http;
  }

  get(url: string) {
    return this.http.get(url);
  }

  post(url: string, payload: any) {
    return this.http.post(url, payload);
  }
}
