<template>
  <div class="chat-text-message" v-html="messageContent"></div>
</template>

<script lang="ts">
import CommonMixin from "@/mixins/operator/Common.mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class TextMessage extends Mixins(CommonMixin) {
  @Prop({ required: true }) private readonly message!: any;
  messageContent = "";

  mounted() {
    this.messageContent = this.replaceLinkMessage(this.message.content);
  }
}
</script>
