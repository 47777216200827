<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27290_4014)">
      <path
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
        stroke="#0062C2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.375 9.375H10V13.75H10.625"
        stroke="#0062C2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.84375 7.1875C10.1889 7.1875 10.4688 6.90768 10.4688 6.5625C10.4688 6.21732 10.1889 5.9375 9.84375 5.9375C9.49857 5.9375 9.21875 6.21732 9.21875 6.5625C9.21875 6.90768 9.49857 7.1875 9.84375 7.1875Z"
        fill="#0062C2"
      />
    </g>
    <defs>
      <clipPath id="clip0_27290_4014">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconInformation extends Vue {}
</script>
