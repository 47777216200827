<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27290_4032)">
      <path
        d="M14.4941 13.25C13.5611 11.6428 12.0677 10.4366 10.3003 9.8625C11.1475 9.35873 11.8058 8.59075 12.1742 7.67653C12.5426 6.76231 12.6006 5.75244 12.3394 4.80204C12.0782 3.85164 11.5121 3.0133 10.7282 2.4158C9.94433 1.8183 8.98595 1.49469 8.0003 1.49469C7.01466 1.49469 6.05628 1.8183 5.27239 2.4158C4.48849 3.0133 3.92246 3.85164 3.66123 4.80204C3.4 5.75244 3.45803 6.76231 3.82641 7.67653C4.19478 8.59075 4.85313 9.35873 5.7003 9.8625C3.93286 10.4366 2.43951 11.6428 1.50655 13.25C1.46075 13.3253 1.43652 13.4118 1.43652 13.5C1.43652 13.5882 1.46075 13.6747 1.50655 13.75C1.54929 13.8266 1.61191 13.8903 1.68781 13.9343C1.76371 13.9783 1.85008 14.001 1.9378 14H14.0628C14.1505 14.001 14.2369 13.9783 14.3128 13.9343C14.3887 13.8903 14.4513 13.8266 14.4941 13.75C14.5399 13.6747 14.5641 13.5882 14.5641 13.5C14.5641 13.4118 14.5399 13.3253 14.4941 13.25Z"
        fill="#9CA5B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_27290_4032">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconUser extends Vue {}
</script>
