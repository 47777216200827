import { AccountState } from "@/store/shared/account/state";
import { Account } from "@/@types/sdk.type";

export default {
  SET_ACCOUNT_INFO(state: AccountState, account: Account): void {
    Object.keys(account).forEach((key: string) => {
      state[key] = account[key as keyof Account];
    });
  },
};
