<template>
  <div class="operator-chat-list">
    <div class="modal">
      <div class="modal-content modal-confirm">
        <div class="title">{{ titleProp }}</div>
        <div class="content">
          <slot></slot>
          <div class="footer-modal">
            <button class="cancel-note close" @click="closeModal">Đóng</button>
            <button :class="classButtonConfirm" @click="updateModal">
              {{ textButtonConfirm }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import ChatDetailMixin from "@/mixins/operator/ChatDetail.mixin";
@Component({
  components: {},
})
export default class ModalConfirm extends Mixins(ChatDetailMixin) {
  @Prop({ required: true }) private readonly titleProp!: string;
  @Prop({ required: true }) private readonly textButtonConfirm!: string;
  @Prop({ required: true }) private readonly classButtonConfirm!: string;
  @Prop({ required: true }) private readonly isButtonDisabled!: any;
  closeModal() {
    this.$emit("closeModalConfirm", false);
  }

  updateModal() {
    if (!this.isButtonDisabled) {
      return;
    }
    this.$emit("updateModalConfirm", false);
  }
}
</script>
