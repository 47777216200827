<template>
  <div class="empty-customer-service">
    <img
      src="@/assets/images/marketplace/customer-service.svg"
      height="64"
      width="64"
    />

    <div class="empty-customer-service__text">
      Xin chào, KiotViet Connect có thể giúp gì cho anh/chị?
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-property-decorator";

export default class EmptyCustomerService extends Vue {}
</script>
