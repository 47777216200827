<template>
  <svg
    width="150"
    height="140"
    viewBox="0 0 150 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M131.502 70.0454C131.466 70.0454 131.43 70.0454 131.394 70.0454C130.346 57.6494 119.938 47.8555 107.216 47.8555C96.013 47.8555 86.5805 55.4449 83.7977 65.7447C82.9665 65.6363 82.1353 65.5279 81.3041 65.5279C72.1245 65.5279 64.6436 72.9727 64.6436 82.1884C64.6436 91.368 72.0884 98.8489 81.3041 98.8489C81.4125 98.8489 81.5209 98.8489 81.5932 98.8489H131.43C131.466 98.8489 131.502 98.8489 131.539 98.8489C139.489 98.8489 145.922 92.416 145.922 84.4652C145.922 76.5144 139.453 70.0454 131.502 70.0454Z"
      fill="url(#paint0_linear_24380_189009)"
    />
    <g filter="url(#filter0_d_24380_189009)">
      <path
        d="M100.675 61.4073C100.675 60.5038 100.386 59.6365 99.9163 58.9498L99.844 58.8775C99.6633 58.6607 99.4826 58.4439 99.2658 58.227L77.9071 34.2662C72.7029 28.4477 63.5595 28.4477 58.3554 34.2662L37.3942 57.7933C36.2016 58.5523 35.4065 59.8895 35.4065 61.4073V61.4435C35.4065 62.7445 35.9847 63.901 36.9244 64.6961L53.8017 83.5973L53.9101 83.7418L58.3554 78.7545C63.5595 72.8998 72.7029 72.8998 77.9432 78.7545L82.3884 83.7418L82.4246 83.7057L99.9524 64.0456C99.9163 64.0094 99.9163 63.9733 99.8801 63.9371C100.386 63.2505 100.675 62.3831 100.675 61.4073C100.675 61.4435 100.675 61.4435 100.675 61.4073Z"
        fill="url(#paint1_linear_24380_189009)"
      />
      <path
        d="M93.2666 42H42.8514C40.6107 42 38.8037 43.8431 38.8037 46.1561V49.228V51.7578V85.8016C38.8037 88.1146 40.6107 89.9577 42.8514 89.9577H93.2666C95.5073 89.9577 97.3143 88.1146 97.3143 85.8016V51.7578V49.228V46.1561C97.3143 43.8793 95.5073 42 93.2666 42Z"
        fill="white"
      />
      <path
        d="M43.9355 46.8423C44.5942 46.8423 45.1282 46.3083 45.1282 45.6496C45.1282 44.991 44.5942 44.457 43.9355 44.457C43.2769 44.457 42.7429 44.991 42.7429 45.6496C42.7429 46.3083 43.2769 46.8423 43.9355 46.8423Z"
        fill="#D4D9E4"
      />
      <path
        d="M47.7302 46.8423C48.3889 46.8423 48.9228 46.3083 48.9228 45.6496C48.9228 44.991 48.3889 44.457 47.7302 44.457C47.0716 44.457 46.5376 44.991 46.5376 45.6496C46.5376 46.3083 47.0716 46.8423 47.7302 46.8423Z"
        fill="#D4D9E4"
      />
      <path
        d="M51.4888 46.8423C52.1474 46.8423 52.6814 46.3083 52.6814 45.6496C52.6814 44.991 52.1474 44.457 51.4888 44.457C50.8301 44.457 50.2961 44.991 50.2961 45.6496C50.2961 46.3083 50.8301 46.8423 51.4888 46.8423Z"
        fill="#D4D9E4"
      />
      <path
        d="M41.4419 105.389H94.6399C96.6637 105.389 98.4346 104.377 99.5549 102.859L77.7625 78.5009C72.5945 72.7185 63.5595 72.7185 58.3915 78.5009L36.5991 102.859C37.6472 104.377 39.4542 105.389 41.4419 105.389Z"
        fill="url(#paint2_linear_24380_189009)"
      />
      <path
        d="M99.8802 63.9751C99.9163 64.0112 99.9163 64.0474 99.9524 64.0835L82.4246 83.7437L99.5549 102.898C100.278 101.886 100.711 100.657 100.711 99.3561V72.8655V67.553V61.4453C100.675 62.385 100.386 63.2523 99.8802 63.9751Z"
        fill="url(#paint3_linear_24380_189009)"
      />
      <path
        d="M35.4065 61.4453V67.5168V72.8294V99.32C35.4065 100.657 35.8402 101.886 36.563 102.862L53.7656 83.5991L36.8882 64.6979C35.9847 63.939 35.4065 62.7464 35.4065 61.4453Z"
        fill="url(#paint4_linear_24380_189009)"
      />
    </g>
    <path
      d="M92.2905 57.5025H43.8269C43.2125 57.5025 42.7427 56.9965 42.7427 56.4183V55.9123C42.7427 55.2979 43.2486 54.8281 43.8269 54.8281H92.2905C92.9049 54.8281 93.3747 55.3341 93.3747 55.9123V56.4544C93.3747 57.0327 92.9049 57.5025 92.2905 57.5025Z"
      fill="url(#paint5_linear_24380_189009)"
    />
    <path
      d="M92.2905 63.2876H43.8269C43.2125 63.2876 42.7427 62.7817 42.7427 62.2034V61.6975C42.7427 61.0831 43.2486 60.6133 43.8269 60.6133H92.2905C92.9049 60.6133 93.3747 61.1192 93.3747 61.6975V62.2396C93.3747 62.8178 92.9049 63.2876 92.2905 63.2876Z"
      fill="url(#paint6_linear_24380_189009)"
    />
    <path
      d="M92.2905 69.0689H43.8269C43.2125 69.0689 42.7427 68.5629 42.7427 67.9847V67.4787C42.7427 66.8644 43.2486 66.3945 43.8269 66.3945H92.2905C92.9049 66.3945 93.3747 66.9005 93.3747 67.4787V68.0208C93.3747 68.5991 92.9049 69.0689 92.2905 69.0689Z"
      fill="url(#paint7_linear_24380_189009)"
    />
    <g filter="url(#filter1_d_24380_189009)">
      <path
        d="M46.8627 96.7514H46.8266C46.2484 89.8848 40.5021 84.5 33.491 84.5C27.311 84.5 22.1069 88.6922 20.5529 94.3662C20.1192 94.2939 19.6494 94.2578 19.1795 94.2578C14.12 94.2578 10 98.3777 10 103.437C10 108.497 14.12 112.617 19.1795 112.617C19.2518 112.617 19.288 112.617 19.3602 112.617H46.8266C46.8627 112.617 46.8627 112.617 46.8989 112.617C51.2718 112.617 54.8497 109.075 54.8497 104.666C54.8135 100.293 51.2718 96.7514 46.8627 96.7514Z"
        fill="url(#paint8_linear_24380_189009)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_24380_189009"
        x="15.4065"
        y="13.9023"
        width="105.305"
        height="115.488"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_24380_189009"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_24380_189009"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_24380_189009"
        x="0"
        y="78.5"
        width="64.8496"
        height="48.1172"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_24380_189009"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_24380_189009"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_24380_189009"
        x1="71.3465"
        y1="73.3607"
        x2="139.184"
        y2="73.3607"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.358535" stop-color="#D5DBE9" />
        <stop offset="1" stop-color="#E8EDF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_24380_189009"
        x1="71.4057"
        y1="85.3675"
        x2="56.4381"
        y2="-21.1325"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00387173" stop-color="#429E46" />
        <stop offset="0.1951" stop-color="#60B462" />
        <stop offset="0.3873" stop-color="#76C477" />
        <stop offset="0.571" stop-color="#83CE84" />
        <stop offset="0.7356" stop-color="#88D188" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_24380_189009"
        x1="71.4177"
        y1="74.4341"
        x2="67.4368"
        y2="108.433"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D6F2D6" />
        <stop offset="0.0169455" stop-color="#D4F1D4" />
        <stop offset="0.2895" stop-color="#B3E3B3" />
        <stop offset="0.5507" stop-color="#9BD99B" />
        <stop offset="0.7936" stop-color="#8DD38D" />
        <stop offset="1" stop-color="#88D188" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_24380_189009"
        x1="35.3883"
        y1="82.1624"
        x2="100.692"
        y2="82.1624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D6F2D6" />
        <stop offset="0.0358181" stop-color="#CCEECC" />
        <stop offset="0.1566" stop-color="#AEE1AE" />
        <stop offset="0.276" stop-color="#99D899" />
        <stop offset="0.3923" stop-color="#8CD38C" />
        <stop offset="0.5019" stop-color="#88D188" />
        <stop offset="0.6003" stop-color="#90D490" />
        <stop offset="0.7514" stop-color="#A5DDA5" />
        <stop offset="0.9355" stop-color="#C8ECC8" />
        <stop offset="1" stop-color="#D6F2D6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_24380_189009"
        x1="35.3883"
        y1="82.1624"
        x2="100.692"
        y2="82.1624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D6F2D6" />
        <stop offset="0.0358181" stop-color="#CCEECC" />
        <stop offset="0.1566" stop-color="#AEE1AE" />
        <stop offset="0.276" stop-color="#99D899" />
        <stop offset="0.3923" stop-color="#8CD38C" />
        <stop offset="0.5019" stop-color="#88D188" />
        <stop offset="0.6003" stop-color="#90D490" />
        <stop offset="0.7514" stop-color="#A5DDA5" />
        <stop offset="0.9355" stop-color="#C8ECC8" />
        <stop offset="1" stop-color="#D6F2D6" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_24380_189009"
        x1="42.742"
        y1="56.1624"
        x2="93.3838"
        y2="56.1624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D4D9E4" />
        <stop offset="0.5205" stop-color="#E6E9F2" />
        <stop offset="1" stop-color="#D4D9E4" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_24380_189009"
        x1="42.742"
        y1="61.9476"
        x2="93.3838"
        y2="61.9476"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D4D9E4" />
        <stop offset="0.5205" stop-color="#E6E9F2" />
        <stop offset="1" stop-color="#D4D9E4" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_24380_189009"
        x1="42.742"
        y1="67.7288"
        x2="93.3838"
        y2="67.7288"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D4D9E4" />
        <stop offset="0.5205" stop-color="#E6E9F2" />
        <stop offset="1" stop-color="#D4D9E4" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_24380_189009"
        x1="10"
        y1="98.5693"
        x2="54.8189"
        y2="98.5693"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.598958" stop-color="#FAFBFC" />
        <stop offset="1" stop-color="#EDEFF4" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class EmptyImage extends Vue {}
</script>
