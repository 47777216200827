<template>
  <div class="operator-chat-list">
    <div class="modal">
      <div class="modal-content modal-list-sample-message modal-confirm">
        <div class="title">Quản lý tin nhắn mẫu</div>
        <div class="content">
          <div class="search-input">
            <input
              @keyup.enter="filterData"
              class="search-input__input-search"
              type="text"
              v-model="keySearchData"
              placeholder="Tìm tin nhắn mẫu"
            />
            <span class="search-input__search-icon">
              <IconSearch />
            </span>
          </div>
          <div class="block-content">
            <ul
              class="item-list"
              v-if="dataFilter.length"
              @scroll="handleScroll"
            >
              <li
                v-for="item in dataFilter"
                :key="item.id"
                class="item-list__item"
              >
                <div class="item__name">
                  <div class="item__title-message">
                    <div class="title-message">
                      {{ item.title }}
                    </div>
                    <div class="icon-title">
                      <div
                        class="icon-pencil-simple"
                        @click.stop="editSampleMessage(item)"
                        title="Sửa"
                      >
                        <IconPencilSimple />
                      </div>
                      <div
                        class="icon-trash"
                        title="Xóa"
                        @click.stop="triggerDeleteSampleMessage(item.id)"
                        @mouseover.stop="hoverItem(item.id)"
                        @mouseleave.stop="hoveredId = 0"
                      >
                        <IconTrashActive v-if="hoveredId === item.id" />
                        <IconTrash v-else />
                      </div>
                    </div>
                  </div>
                  <div class="item__content-message">
                    {{ item.content }}
                  </div>
                </div>
              </li>
            </ul>
            <div v-else class="no-content">
              Không tìm thấy tin nhắn mẫu phù hợp
            </div>
          </div>
          <div class="footer-modal">
            <button class="cancel-note close" @click="closeModal">Đóng</button>
            <button :class="'success'" @click="updateModal">
              <span class="icon-plus-circle-thin">
                <IconPlusCircleThin />
              </span>
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </div>
    <SampleMessageCreate
      v-if="showEditSampleMessage"
      @closeModalCreate="showEditSampleMessage = false"
      @triggerCreate="handleAfterEdit"
      :sampleMessage="sampleMessageSelected"
      :modalTitle="'Cập nhật tin nhắn mẫu'"
      :buttonTitle="'Lưu'"
    />
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import ChatDetailMixin from "@/mixins/operator/ChatDetail.mixin";
import ModalConfirm from "@/scopes/operator/components/modal/ModalConfirm.vue";
import CommonMixin from "@/mixins/operator/Common.mixin";
import IconSearch from "@/assets/images/operator/iconSearch.vue";
import IconPlusCircleThin from "@/assets/images/operator/iconPlusCircleThin.vue";
import IconPencilSimple from "@/assets/images/operator/iconPencilSimple.vue";
import IconTrash from "@/assets/images/operator/iconTrash.vue";
import IconTrashActive from "@/assets/images/operator/iconTrashActive.vue";
import SampleMessageCreate from "@/scopes/operator/components/modal/SampleMessageCreate.vue";
import { checkPermission } from "@/helpers/permission.helper";
import PERMISSION from "@/constants/permission";

@Component({
  components: {
    IconTrash,
    IconPencilSimple,
    IconPlusCircleThin,
    ModalConfirm,
    IconSearch,
    SampleMessageCreate,
    IconTrashActive,
  },
})
export default class SampleMessageList extends Mixins(
  ChatDetailMixin,
  CommonMixin
) {
  keySearchData = "";
  dataFilter: any[] = [];
  page = 1;
  totalPage = 1;
  isLoadingMore = true;
  sampleMessageSelected = {};
  showEditSampleMessage = false;
  dataEdited = {};
  hoveredId = 0;
  filterData() {
    this.page = 1;
    this.getData();
  }
  created() {
    this.getData();
  }
  async getData() {
    const response = await this.listSampleMessages({
      keyword: this.keySearchData,
      page: this.page,
    });

    this.page = response.page;
    this.totalPage = response.total_page;
    if (response.data.length && Number(response.page) > 1) {
      this.dataFilter.push(...response.data);
    } else {
      this.dataFilter = response.data;
    }
  }
  closeModal(data: any) {
    if (data) {
      this.$emit("closeModalList", data);
      return;
    }
    this.$emit("closeModalList", this.dataEdited);
  }

  async updateModal() {
    const hasPermission = await checkPermission(
      PERMISSION.CHAT_CREATE_SAMPLE_MESSAGE
    );
    if (!hasPermission) {
      this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
      return;
    }
    this.$emit("updateModalList", false);
  }

  handleScroll(event: Event) {
    const { target } = event as unknown as { target: HTMLElement };
    const { scrollTop, clientHeight, scrollHeight } = target;

    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      let nextPage = this.page + 1;
      if (nextPage <= this.totalPage) {
        this.page = nextPage;
        this.getData();
      }
    }
  }

  async editSampleMessage(sampleMessage: any) {
    const hasPermission = await checkPermission(
      PERMISSION.CHAT_UPDATE_SAMPLE_MESSAGE
    );
    if (!hasPermission) {
      this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
      return;
    }
    this.sampleMessageSelected = sampleMessage;
    this.showEditSampleMessage = true;
  }
  handleAfterEdit(data: any) {
    let indexEdit = this.dataFilter.findIndex((v) => v.id == data.id);
    if (indexEdit > -1) {
      this.dataFilter[indexEdit] = data;
      this.dataEdited = data;
    }
    this.showEditSampleMessage = false;
  }
  async triggerDeleteSampleMessage(id: number) {
    try {
      const hasPermission = await checkPermission(
        PERMISSION.CHAT_DELETE_SAMPLE_MESSAGE
      );
      if (!hasPermission) {
        this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
        return;
      }
      const response = await this.deleteSampleMessage(id);
      if (response) {
        this.toastCustom("Xóa tin nhắn mẫu thành công");
        this.dataFilter = this.dataFilter.filter((v) => v.id != id);
        this.closeModal({ id: id, type: "remove" });
      }
    } catch (error: any) {
      if (error?.response && error?.response?.status === 403) {
        this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
        return;
      }
      this.toastCustom("Có lỗi xảy ra", "error");
    }
  }
  hoverItem(id: number) {
    this.hoveredId = id;
  }
}
</script>
