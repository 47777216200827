import { Component, Mixins, Watch } from "vue-property-decorator";
import ChatDetailMixin from "@/mixins/ChatDetail.mixin";
import { AttachmentEnum } from "@/enums/attachment.enum";
import { CHAT_LINK_MKP_API } from "@/constants/app";
import { IAttachment } from "@/interfaces/marketplace/message.interface";

@Component
export default class MarketplaceChatDetailMixin extends Mixins(
  ChatDetailMixin
) {
  attachment: IAttachment | null = null;
  supplierInfo: any = null;

  @Watch("room", { deep: true })
  public onRoomChange(val: any) {
    if (val && val.supplier_name) {
      this.getSupplierInfo();
    } else {
      this.supplierInfo = null;
    }
  }

  /** Before Destroyed */
  public beforeDestroy() {
    this.channel = null;
    this.user = null;
    this.room = null;
  }

  public parseAttachment() {
    const timestamp = new Date().getTime();
    const isFromMarket = 1;
    switch (this.attachment?.type) {
      case AttachmentEnum.Product:
        return (
          CHAT_LINK_MKP_API +
          "product?refer_id=" +
          this.attachment?.payload.refer_id +
          "&message_id=" +
          timestamp
        );
      case AttachmentEnum.OrderDetail:
        return (
          CHAT_LINK_MKP_API +
          "order?refer_id=" +
          this.attachment?.payload.refer_id +
          "&message_id=" +
          timestamp
        );
        break;
      default:
        return null;
    }
  }

  public redirectApp() {
    setTimeout(() => {
      (window as any).$redirectApp({
        screen: "chat-detail",
        hiddenClose: true,
      });
    }, 400);
  }

  public async handleSendMessage() {
    if (this.message) {
      await this.sendTextMessage();

      setTimeout(async () => {
        if (this.attachment) {
          try {
            const attachmentPlaintext = this.parseAttachment();

            await this.sendTextMessage({
              type: this.attachment.type.toUpperCase(),
              msg: attachmentPlaintext,
            });
          } catch (error) {
            console.log(error);
          } finally {
            this.attachment = null;

            setTimeout(
              () => window.dispatchEvent(new Event("initChatDetailLure")),
              0
            );
          }
        }
      }, 150);

      (this.$refs.input as HTMLElement).style.height = "3em";
      (this.$refs.input as HTMLElement).focus();
    }
  }

  public async openSupplierDetail() {
    const id = this.user.username.replace("supplier", "");
    if (Number(id)) {
      window.dispatchEvent(
        new CustomEvent("dispatch", {
          detail: {
            name: "openSupplierDetail",
            body: { id: Number(id), isReferId: true },
          },
        })
      );
    }
  }

  public async handleUploadImage(e: any) {
    const image = e.target.files[0];

    await this.sendFileMessage({ file: image });

    e.target.value = null;
  }

  async getSupplierInfo() {
    try {
      const response = await this.$httpMarketplace.get(
        "/chat/other-user-info?username=" + this.room.supplier_name
      );
      this.supplierInfo = response.data;
    } catch (error) {
      console.log("GET_SUPPLIER_INFO_ERROR", error);
    }
  }
}
