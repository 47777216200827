<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27379_248158)">
      <path
        d="M17.7578 6.26562L13.7344 2.24219C13.4999 2.00836 13.1827 1.87642 12.8516 1.875H7.14844C6.81731 1.87642 6.50009 2.00836 6.26562 2.24219L2.24219 6.26562C2.00836 6.50009 1.87642 6.81731 1.875 7.14844V12.8516C1.87642 13.1827 2.00836 13.4999 2.24219 13.7344L6.26562 17.7578C6.50009 17.9916 6.81731 18.1236 7.14844 18.125H12.8516C13.1827 18.1236 13.4999 17.9916 13.7344 17.7578L17.7578 13.7344C17.9916 13.4999 18.1236 13.1827 18.125 12.8516V7.14844C18.1236 6.81731 17.9916 6.50009 17.7578 6.26562ZM9.375 6.25C9.375 6.08424 9.44085 5.92527 9.55806 5.80806C9.67527 5.69085 9.83424 5.625 10 5.625C10.1658 5.625 10.3247 5.69085 10.4419 5.80806C10.5592 5.92527 10.625 6.08424 10.625 6.25V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V6.25ZM10 14.375C9.81458 14.375 9.63332 14.32 9.47915 14.217C9.32498 14.114 9.20482 13.9676 9.13386 13.7963C9.06291 13.625 9.04434 13.4365 9.08051 13.2546C9.11669 13.0727 9.20598 12.9057 9.33709 12.7746C9.4682 12.6435 9.63525 12.5542 9.8171 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z"
        fill="#E03E59"
      />
    </g>
    <defs>
      <clipPath id="clip0_27379_248158">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconWarningActive extends Vue {}
</script>
