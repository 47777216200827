import ImageModal from "@/scopes/marketplace/components/modals/ImageModal.vue";
import { Vue } from "vue-property-decorator";

interface IImageModalPlugin {
  component: ImageModal | null;
  visible: boolean;
  modalOpenedCount: number;
  show: (images: string[]) => void;
  hide: () => void;
}

const ImageModalPlugin: IImageModalPlugin = {
  component: null,
  visible: false,
  modalOpenedCount: 0,
  show: function (images: string[]) {
    const oldVisible = this.visible;
    this.visible = true;

    if (!oldVisible) {
      this.component = new Vue(ImageModal);

      const modal = document.createElement("div");
      modal.setAttribute("id", "image-modal");
      document.body.appendChild(modal);

      const body = document.body;
      const modalOpenedCount = +(
        body.getAttribute("data-modal-open-count") || 0
      );

      this.modalOpenedCount = modalOpenedCount + 1;

      document.body.classList.add("modal-open");
      body.setAttribute("data-modal-open-count", `${this.modalOpenedCount}`);

      this.component.$mount("#image-modal");

      (this.component as any).show(images);
    }
  },
  hide: function () {
    if (this.component) {
      (this.component as any).hide();

      const modal = document.getElementById("image-modal");

      const body = document.body;
      const modalOpenedCount = +(
        body.getAttribute("data-modal-open-count") || 0
      );

      this.modalOpenedCount = modalOpenedCount - 1;

      if (this.modalOpenedCount <= 0) {
        this.modalOpenedCount = 0;
        document.body.classList.remove("modal-open");
      }

      body.setAttribute("data-modal-open-count", `${this.modalOpenedCount}`);
      modal?.remove();

      this.visible = false;
      this.component.$destroy();
      this.component = null;
    }
  },
};

export default ImageModalPlugin;
