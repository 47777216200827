import MESSAGE_TYPE from "@/constants/message-type";
import { AttachmentEnum } from "@/enums/attachment.enum";
import { CHAT_LINK_MKP_API } from "@/constants/app";

export const getMessageTypeAndValue = (payload: any) => {
  let type = MESSAGE_TYPE.PLAIN_TEXT;
  const value = [];
  let msg = null;

  if (payload) {
    if (!payload.md) {
      if (payload.attachments && payload.attachments.length) {
        type = MESSAGE_TYPE.IMAGE;
        msg = getPathImageMessage(payload.attachments[0].image_url);
      } else {
        type = MESSAGE_TYPE.NOT_SUPPORT;
      }
    } else {
      for (const item of payload.md) {
        if (item.type === MESSAGE_TYPE.BREAK_LINE) {
          value.push(item);
        }

        if (item.type === MESSAGE_TYPE.PARAGRAPH) {
          let _break = false;

          for (const child of item.value) {
            if (
              child.type === MESSAGE_TYPE.PLAIN_TEXT ||
              child.type === MESSAGE_TYPE.EMOJI
            ) {
              value.push(child);
              type = MESSAGE_TYPE.PLAIN_TEXT;
            } else if (child.type === MESSAGE_TYPE.LINK) {
              try {
                const url = new URL(child.value.src.value);

                if (
                  [
                    process.env.VUE_APP_OLD_MKP_API,
                    process.env.VUE_APP_MKP_API,
                  ].includes(url.origin + "/")
                ) {
                  const urlPath = url.pathname.split("/");
                  const messageType = urlPath[urlPath.length - 1];

                  switch (messageType) {
                    case AttachmentEnum.Product:
                      type = MESSAGE_TYPE.PRODUCT;
                      break;
                    case AttachmentEnum.OrderDetail:
                      type = MESSAGE_TYPE.ORDER;
                      break;
                    default:
                      type = MESSAGE_TYPE.NOT_SUPPORT;
                      break;
                  }
                } else {
                  type = MESSAGE_TYPE.PLAIN_TEXT;
                }
                _break = true;
                break;
              } catch (error) {
                type = MESSAGE_TYPE.PLAIN_TEXT;
                _break = true;
                break;
              }
            } else {
              type = MESSAGE_TYPE.NOT_SUPPORT;
              _break = true;
              break;
            }
          }

          if (_break) break;
        }
      }

      msg = payload.msg;
    }
  }

  return { type, value, msg };
};

export const parseChatListMessage = (payload: any) => {
  const { type, msg } = payload;

  switch (type) {
    case MESSAGE_TYPE.PLAIN_TEXT:
      return msg.split("\n").join(" ");

    case MESSAGE_TYPE.IMAGE:
      return "Đã gửi một ảnh";

    case MESSAGE_TYPE.LINK:
    case MESSAGE_TYPE.PRODUCT:
      return "Đã gửi một liên kết";

    case MESSAGE_TYPE.ORDER:
      return "Đã gửi 1 đơn hàng";

    case MESSAGE_TYPE.NOT_SUPPORT:
      return "Đã gửi một định dạng chưa được hỗ trợ";
  }
};

export const parseChatDetailMessage = (payload: any) => {
  const { type, msg } = payload;

  switch (type) {
    case MESSAGE_TYPE.PLAIN_TEXT:
      return msg.split("\n").join("<br/>");

    default:
      return msg;
  }
};

export const getPathImageMessage = (url: string) => {
  if (!url) return "";
  return process.env.VUE_APP_API_HOST + url.replace("/", "");
};
