export enum OrderStatus {
  OrderStatusAwaitingApprove = 5,
  OrderStatusCancelApproved = 6,
  OrderStatusAwaitingConfirmation = 0,
  OrderStatusCancelled = 1,
  OrderStatusConfirmed = 2,
  OrderStatusProcessing = 3,
  OrderStatusCompleted = 4,
}

export const OrderStatusText = {
  [OrderStatus.OrderStatusAwaitingApprove]: "Chờ thanh toán",
  [OrderStatus.OrderStatusAwaitingConfirmation]: "Chờ xác nhận",
  [OrderStatus.OrderStatusCancelApproved]: "Đã hủy",
  [OrderStatus.OrderStatusCancelled]: "Đã hủy",
  [OrderStatus.OrderStatusConfirmed]: "Đã xác nhận",
  [OrderStatus.OrderStatusProcessing]: "Đang xử lý",
  [OrderStatus.OrderStatusCompleted]: "Hoàn thành",
};

export const OrderStatusClassName = {
  [OrderStatus.OrderStatusAwaitingApprove]:
    "order__block-status order__awaiting-approve",
  [OrderStatus.OrderStatusAwaitingConfirmation]:
    "order__block-status order__awaiting-confirmation",
  [OrderStatus.OrderStatusCancelApproved]:
    "order__block-status order__cancel-approved",
  [OrderStatus.OrderStatusCancelled]: "order__block-status order__cancelled",
  [OrderStatus.OrderStatusConfirmed]: "order__block-status order__confirmed",
  [OrderStatus.OrderStatusProcessing]: "order__block-status order__processing",
  [OrderStatus.OrderStatusCompleted]: "order__block-status order__completed",
};
