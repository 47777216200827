<template>
  <div class="chat-detail__block">
    <div class="chat-detail__block-date">
      <div class="item"><div class="item__cross"></div></div>
      <div class="item-date">{{ date }}</div>
      <div class="item"><div class="item__cross"></div></div>
    </div>
    <div
      v-for="key in dataSource.keys()"
      :key="'group' + key"
      class="chat-detail__block-content"
    >
      <div class="author" v-if="!dataSource.get(key).receive">
        Được hỗ trợ bởi
        <span class="author__name">{{
          dataSource.get(key).author ? dataSource.get(key).author : ""
        }}</span>
      </div>
      <chat-detail-item
        v-for="message in dataSource.get(key).messages"
        :key="message._id"
        :message="message"
      />
      <div
        v-if="
          dataSource.get(key).messages &&
          dataSource.get(key).messages[dataSource.get(key).messages.length - 1]
            .status_read &&
          key == dataSource.length - 1
        "
        class="read-time"
      >
        Đã đọc lúc
        {{
          dataSource.get(key).messages[dataSource.get(key).messages.length - 1]
            ?.read_at
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
// import ChatDetailItem from "@/scopes/marketplace/components/supplier/detail/ChatDetailItem.vue";
import ChatDetailItem from "@/scopes/operator/components/detail/ChatDetailItem.vue";

@Component({
  components: { ChatDetailItem },
})
export default class ChatDetailByDate extends Vue {
  @Prop({ required: true }) private readonly date!: string;
  @Prop({ required: true }) private readonly dataSource!: any[];
}
</script>
