import { cloneDeep } from "lodash";
import { Account } from "@/@types/sdk.type";

export interface AccountState {
  [key: string]: Account[keyof Account];
}

const initialState: AccountState = {};

export default (): AccountState => cloneDeep(initialState);
