import Vue from "vue";
import Vuex from "vuex";
import account from "@/store/shared/account";
import global from "@/store/shared/global";
import * as navigator from "@/store/marketplace/navigator";
import * as conversation from "@/store/marketplace/conversation";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    global: global,
    account: account,
    marketplace: {
      namespaced: true,
      modules: {
        navigator: {
          namespaced: true,
          state: navigator.state(),
          mutations: navigator.mutations,
          actions: navigator.actions,
        },
        conversation: {
          namespaced: true,
          state: conversation.state(),
          mutations: conversation.mutations,
          actions: conversation.actions,
        },
      },
    },
  },
});
