export enum RocketChatCollection {
  StreamNotififyUser = "stream-notify-user",
  StreamNotififyRoom = "stream-notify-room",
  StreamNotififyAll = "stream-notify-all",
  StreamNotififyLogged = "stream-notify-logged",
  StreamRoomMessages = "stream-room-messages",
  StreamUserPresence = "stream-user-presence",
}

export enum RocketChatMethod {
  SendMessage = "sendMessage",
  readMessages = "readMessages",
}

export enum RocketChatMsgTypes {
  Sub = "sub",
  Unsub = "unsub",
  Ping = "ping",
  Pong = "pong",
  Changed = "changed",
  Method = "method",
}

export enum MessagetypeEnum {
  Text = "text",
  Image = "image",
  Link = "link",
  File = "file",
}

export enum RocketChatEventName {
  RoomsChanged = "rooms-changed",
  SubscriptionsChanged = "subscriptions-changed",
  TYPING = "typing",
}
