import { Component, Vue, Watch } from "vue-property-decorator";
import MESSAGE_TYPE from "@/constants/message-type";
import {
  RocketChatCollection,
  RocketChatEventName,
  RocketChatMsgTypes,
} from "@/enums";
import {
  getMessageTypeAndValue,
  parseChatDetailMessage,
  parseChatListMessage,
} from "@/helpers/message.helper";
import { cloneDeep } from "lodash";
import ChatDetailController from "@/controllers/ChatDetail.controller";
import SCOPE from "@/scope";
import { RoomType } from "@/enums/room.enum";

const { MARKETPLACE, OPERATOR } = SCOPE;

@Component
export default class SocketMixin extends Vue {
  messageQueue: Map<string, any> = new Map<string, any>();

  get account() {
    return this.$store.state.account;
  }
  async created() {
    this.$socket.onMessage((payload: any) => {
      if (!payload.fields) return;

      if (!payload.fields.eventName) return;
      const eventName = payload.fields.eventName.split("/")[1];
      switch (eventName) {
        case RocketChatEventName.RoomsChanged:
          this.onRoomsChanged(payload);
          break;

        case RocketChatEventName.SubscriptionsChanged:
          this.onSubscriptionsChanged(payload);
          break;

        case RocketChatEventName.TYPING:
          this.handleEventTyping(payload);
          break;
      }

      if (
        payload?.msg == RocketChatMsgTypes.Changed &&
        payload?.collection == RocketChatCollection.StreamRoomMessages
      ) {
        this.onSubMessageDetail(payload);
      }
    });

    this.messageQueueProcess();
  }

  public async messageQueueProcess() {
    const TRUE = true;

    while (TRUE) {
      if (this.messageQueue.size) {
        const [fistKey] = this.messageQueue.keys();

        const message = this.messageQueue.get(fistKey);
        const messageClone = cloneDeep(message);
        if (messageClone.detail && messageClone.list) {
          messageClone.list.lastMessage = messageClone.detail;
          messageClone.list.customFields = messageClone.detail?.customFields;
          messageClone.list.lastMessage.msg = parseChatListMessage({
            type: messageClone.detail.type,
            msg: messageClone.detail.content,
          });
          messageClone.list.receive = messageClone.detail.receive;
          this.$store.dispatch("global/UPDATE_CHAT_ITEM", messageClone);
          this.$store.dispatch("global/UPDATE_CHAT_DETAIL", messageClone);
          // nếu có tin nhăn mới đên thì bỏ trạng thái hoàn thành của đoạn chat
          this.handleChatItem(messageClone);
        }

        this.messageQueue.delete(fistKey);
      } else {
        await new Promise((resolve) => setTimeout(resolve, 150));
      }
    }
  }

  public handleEventTyping(payload: any) {
    const { fields, msg, collection } = payload;
    if (msg == RocketChatMsgTypes.Changed) {
      const rid = payload.fields.eventName.split("/")[0];
      const isSending = payload.fields.args[1];
      this.$store.dispatch("global/UPDATE_CHAT_TYPING", { rid, isSending });
    }
  }

  public onSubMessageDetail(payload: any) {
    const { fields, msg, collection } = payload;
    const { eventName, args } = fields;
    if (
      msg == RocketChatMsgTypes.Changed &&
      collection == RocketChatCollection.StreamRoomMessages
    ) {
      const data = args[0];
      const date = new Date(data.ts.$date);
      const { type, value, msg } = getMessageTypeAndValue(data);
      const lastMessage = {
        _id: data._id,
        msg: data.msg,
        ts: data.ts.$date,
        content: msg ? parseChatDetailMessage({ type: type, msg: msg }) : "",
        type: type,
        user: data.u,
        receive: data.u.username !== this.account.user_name,
        date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
      };
      const messageClone = {
        rid: eventName,
        detail: lastMessage,
      };
      this.$store.dispatch("global/UPDATE_CHAT_DETAIL", messageClone);

      const oldRoom = this.$store.state.global.users.get(eventName);
      if (oldRoom) {
        const newLastMessage = cloneDeep(lastMessage);
        newLastMessage.msg = parseChatListMessage({
          type: lastMessage.type,
          msg: lastMessage.msg,
        });
        const lastItem = {
          id: oldRoom.id,
          name: oldRoom.name,
          unread: 0,
          user: oldRoom.user,
          lastMessage: newLastMessage,
        };
        const itemClone = {
          rid: eventName,
          list: lastItem,
        };
        this.$store.dispatch("global/UPDATE_CHAT_ITEM", itemClone);
      }
    }
  }

  public onRoomsChanged(payload: any) {
    const { fields } = payload;
    const { eventName, args } = fields;
    if (args[0] === "updated") {
      const room = args[1];
      if (
        room.customFields &&
        room.customFields.type == "customer_service" &&
        !room.lastMessage
      ) {
        this.onChannelsChanged(room);
      } else {
        const roomId = room._id;
        const date = new Date(room.lastMessage.ts.$date);
        const { type, value, msg } = getMessageTypeAndValue(room.lastMessage);
        // chỉ lấy những sự kiện là thay đổi lastMessage
        if (type !== MESSAGE_TYPE.NOT_SUPPORT) {
          const update = {
            _id: room.lastMessage._id,
            msg: room.lastMessage.msg,
            ts: room.lastMessage.ts.$date,
            content: msg
              ? parseChatDetailMessage({ type: type, msg: msg })
              : "",
            type: type,
            user: room.lastMessage.u,
            customFields: room.customFields,
            receive: room.lastMessage.u.username !== this.account.user_name,
            date: `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`,
          };

          if (this.messageQueue.has(roomId)) {
            const queue = this.messageQueue.get(roomId);
            queue.detail = update;
          } else {
            this.messageQueue.set(roomId, { rid: roomId, detail: update });
          }
        }
      }
    }
  }

  public onChannelsChanged(room: any) {
    const roomId = room._id;
    const date = new Date(room.ts.$date);
    const { type, value, msg } = getMessageTypeAndValue(room.lastMessage);
    const update = {
      _id: room.lastMessage ? room.lastMessage._id : "",
      msg: room.lastMessage ? room.lastMessage.msg : "",
      ts: room.lastMessage ? room.lastMessage.ts : "",
      content: msg ? parseChatDetailMessage({ type: type, msg: msg }) : "",
      type: type,
      receive: true,
      date: `${date.getDate()}/
          ${date.getMonth() + 1}/${date.getFullYear()}`,
    };
    if (this.messageQueue.has(roomId)) {
      const queue = this.messageQueue.get(roomId);
      queue.detail = update;
    } else {
      this.messageQueue.set(roomId, { rid: roomId, detail: update });
    }
  }

  public onSubscriptionsChanged(payload: any) {
    const { fields } = payload;
    const { eventName, args } = fields;
    const room = args[1];
    const roomId = room.rid;
    if (args[0] === "updated") {
      const update = {
        id: room.rid,
        name: room.name,
        unread: room.unread,
        user: {
          _id: room.u._id,
          name: room.fname,
          username: room.name,
          avatar: process.env.VUE_APP_API_HOST + "avatar/" + room.name,
        },
      };

      if (this.messageQueue.has(roomId)) {
        const queue = this.messageQueue.get(roomId);
        queue.list = update;
      } else {
        this.messageQueue.set(roomId, { rid: roomId, list: update });
      }

      this.$store.dispatch("global/UPDATE_UNREAD_COUNT", {
        rid: room.rid,
        data: room.unread,
      });
    }
  }

  public async handleChatItem(payload: any) {
    if (payload.list.unread) {
      const customFields = payload.detail.customFields;
      if (customFields && customFields.status == "success") {
        customFields.status = "normal";
        const data = await new ChatDetailController(
          payload.rid
        ).addCustomFieldForConversation({
          roomId: payload.rid,
          customFields: customFields,
        });

        if (data && data.success) {
          // update vào store trạng thái statusRoom của room
          this.$store.dispatch(
            "global/UPDATE_CONVERSATION_DETAIL_CUSTOM_FIELD",
            {
              roomId: payload.rid,
              customFields: customFields,
              statusRoom: customFields.status,
            }
          );
        }
      }
    }
  }

  public isMKPCurrentRoom(roomId: string) {
    return (
      this.$store.state.global.scope === SCOPE.MARKETPLACE &&
      this.$store.state.marketplace.navigator.currentRoomId === roomId
    );
  }
}
