<template>
  <div class="chat-list">
    <div class="chat-list__header">Chat</div>

    <div class="chat-list__body">
      <chat-item
        v-for="key in dataSource.keys()"
        :key="key"
        :data="dataSource.get(key)"
        @select="onSelect"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ChatItem from "@/scopes/marketplace/device/desktop/components/list/ChatItem.vue";
import MarketplaceChatListMixin from "@/mixins/marketplace/ChatList.mixin";

@Component({
  components: { ChatItem },
})
export default class Chat extends Mixins(MarketplaceChatListMixin) {
  public onSelect() {
    this.$emit("select");
  }
}
</script>
