<template>
  <ModalConfirm
    :titleProp="modalTitle"
    :textButtonConfirm="buttonTitle"
    :classButtonConfirm="classButtonConfirm"
    :isButtonDisabled="isButtonDisabled"
    @closeModalConfirm="closeModal()"
    @updateModalConfirm="triggerCreate()"
  >
    <div class="create-sample-message">
      <div>
        <input
          class="title-sample-message"
          type="text"
          v-model="titleMessage"
          placeholder="Tiêu đề"
          :maxlength="maxLengthTitleMsg"
          :class="{ error: failValidateTitle && !titleMessage }"
        />
        <div class="count-limit-length-input">
          {{ titleMessage.length }}/{{ maxLengthTitleMsg }}
        </div>
        <div class="text-red" v-if="failValidateTitle && !titleMessage">
          Vui lòng nhập tiêu đề
        </div>
      </div>
      <div>
        <textarea
          class="content-sample-message"
          v-model="contentMessage"
          placeholder="Nội dung"
          :class="{ error: failValidateContent && !contentMessage }"
        />
        <div class="text-red" v-if="failValidateContent && !contentMessage">
          Vui lòng nhập nội dung
        </div>
      </div>
    </div>
  </ModalConfirm>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import ChatDetailMixin from "@/mixins/operator/ChatDetail.mixin";
import ModalConfirm from "@/scopes/operator/components/modal/ModalConfirm.vue";
import CommonMixin from "@/mixins/operator/Common.mixin";
import { checkPermission } from "@/helpers/permission.helper";
import PERMISSION from "@/constants/permission";

@Component({
  components: { ModalConfirm },
})
export default class SampleMessageCreate extends Mixins(
  ChatDetailMixin,
  CommonMixin
) {
  @Prop({ required: false }) private readonly sampleMessage!: any;
  @Prop({ required: false, default: "Thêm tin nhắn mẫu" })
  readonly modalTitle!: string;
  @Prop({ required: false, default: "Tạo" }) readonly buttonTitle!: string;
  titleMessage = "";
  contentMessage = "";
  failValidateTitle = false;
  failValidateContent = false;
  maxLengthTitleMsg = 255;
  get isButtonDisabled(): boolean {
    return !(!this.titleMessage.trim() || !this.contentMessage.trim());
  }
  get classButtonConfirm(): string {
    return this.titleMessage.trim() && this.contentMessage.trim()
      ? "success"
      : "disabled";
  }

  async triggerCreate() {
    const permissionCheck = this.sampleMessage?.id
      ? PERMISSION.CHAT_UPDATE_SAMPLE_MESSAGE
      : PERMISSION.CHAT_CREATE_SAMPLE_MESSAGE;
    const hasPermission = await checkPermission(permissionCheck);
    if (!hasPermission) {
      this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
      return;
    }
    if (this.validateBeforeCreate()) {
      this.toastCustom("Vui lòng nhập đầy đủ thông tin yêu cầu", "error");
      return;
    }

    const response = await this.createSampleMessage({
      title: this.titleMessage,
      content: this.contentMessage,
      id: this.sampleMessage?.id,
    });

    if (response) {
      this.$emit("triggerCreate", {
        title: this.titleMessage,
        content: this.contentMessage,
        id: this.sampleMessage?.id,
      });
      if (this.sampleMessage) {
        this.toastCustom("Sửa tin nhắn mẫu thành công");
        return;
      }
      this.toastCustom("Thêm tin nhắn mẫu thành công");
    }
  }

  validateBeforeCreate() {
    let hasError = false;
    this.resetValidateParam();
    if (!this.titleMessage || !this.titleMessage.trim().length) {
      this.failValidateTitle = true;
      hasError = true;
    }

    if (!this.contentMessage || !this.contentMessage.trim().length) {
      this.failValidateContent = true;
      hasError = true;
    }
    return hasError;
  }

  resetValidateParam() {
    this.failValidateTitle = false;
    this.failValidateContent = false;
  }

  closeModal() {
    this.titleMessage = "";
    this.contentMessage = "";
    this.resetValidateParam();
    this.$emit("closeModalCreate", true);
  }
  created() {
    if (this.sampleMessage && Object.keys(this.sampleMessage).length) {
      this.titleMessage = this.sampleMessage.title;
      this.contentMessage = this.sampleMessage.content;
    }
  }
}
</script>
