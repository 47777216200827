import { Component, Vue, Watch } from "vue-property-decorator";
import ChatListController from "@/controllers/ChatList.controller";
import { CHAT_LIST_PAGE_SIZE } from "@/constants/app";

@Component
export default class ChatListMixin extends Vue {
  get dataSource() {
    return this.$store.state.global.users;
  }

  get offset() {
    return this.$store.state.global.offset;
  }

  get conversationDetail() {
    return this.$store.state.global.conversationDetail;
  }
  get isFirstPage() {
    return !(this.offset - CHAT_LIST_PAGE_SIZE);
  }

  @Watch("offset", { deep: true })
  public onDataOffset(val: any, oldVal: any) {
    if (this.isFirstPage) {
      //this.goToDetail();
    }
  }

  async created() {
    if (!this.dataSource.size) {
      // await new ChatListController().getChatChannelList(this.offset, null);
    }
  }

  public goToDetail() {
    if (this.dataSource.size) {
      const [firstValue] = this.dataSource.values();
      this.$store.dispatch("global/UPDATE_CONVERSATION_DETAIL", {
        roomId: firstValue.id,
        customFields: firstValue.customFields,
        roomName: firstValue.name,
        statusRoom: firstValue.statusRoom,
        user: firstValue.user,
        userCurator: firstValue.userCurator || null,
      });
    } else {
      this.$store.dispatch("global/UPDATE_CONVERSATION_DETAIL", null);
      this.$store.dispatch("global/RESET_DETAIL", new Map());
    }
  }
}
