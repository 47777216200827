import { RealTimeAPI } from "rocket.chat.realtime.api.rxjs";
import { IRocketChatOptions } from "@/interfaces/rocket-chat/options.interface";
import {
  RocketChatCollection,
  RocketChatMethod,
  RocketChatMsgTypes,
} from "@/enums/msg.enum";
import {
  IEventRequest,
  ISendMessageText,
} from "@/interfaces/rocket-chat/request.interface";
import { randomString } from "@/helpers/string.helper";
import SCOPE from "@/scope";

const { MARKETPLACE, OPERATOR } = SCOPE;
export class SocketAPI {
  private _socket: RealTimeAPI;

  private _options?: IRocketChatOptions;
  constructor(wsUrl?: string) {
    this._socket = new RealTimeAPI({
      url: wsUrl || process.env.VUE_APP_WEBSOCKET_HOST,
    });
  }

  getObservable() {
    return this._socket.getObservable();
  }

  _genId(): string {
    return randomString(32);
  }

  async connect(options: IRocketChatOptions) {
    this._options = options;

    await new Promise((resolve, reject) => {
      this._socket.connectToServer().subscribe(
        async () => {
          this._socket.keepAlive().subscribe();
          this._socket.loginWithAuthToken(options.authToken);
          this.subscribeNotifyUserMessages(options.userId);
          this.subscribeSubscriptionsChanged(options.userId);
          this.subscribeRoomsChanged(options.userId);

          resolve(null);
        },
        (err) => reject(err)
      );
    });

    return this;
  }

  disconnect() {
    return this._socket.disconnect();
  }

  onMessage(handler: (val: any) => void) {
    this._socket.onMessage(handler);
  }

  offMessage(handler: (val: any) => void) {
    this._socket.subscribe(handler);
  }

  sendMessage(msg: any) {
    return this._socket.sendMessage(msg);
  }

  sendMessageText(data: ISendMessageText) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Method,
      id: this._genId(),
      method: RocketChatMethod.SendMessage,
      params: [data],
    } as IEventRequest);
  }

  subscribeNotifyUserMessages(userId: string) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Sub,
      id: this._genId(),
      name: RocketChatCollection.StreamNotififyUser,
      params: [
        userId + "/notification",
        {
          useCollection: false,
          args: [],
        },
      ],
    } as IEventRequest);
  }

  subscribeRoomMessages(roomId: string, id?: string) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Sub,
      id: id || this._genId(),
      name: RocketChatCollection.StreamRoomMessages,
      params: [
        roomId,
        {
          useCollection: false,
          args: [],
        },
      ],
    } as IEventRequest);
  }

  subscribeRoomTyping(roomId: string, id?: string) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Sub,
      id: id || this._genId(),
      name: RocketChatCollection.StreamNotififyRoom,
      params: [roomId + "/typing", true],
    });
  }

  sendRoomTyping(roomId: string, userName: string, isSending: any) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Method,
      method: RocketChatCollection.StreamNotififyRoom,
      id: this._genId(),
      params: [roomId + "/typing", userName, isSending],
    });
  }

  subscribeUserPresence(userIds: string[]) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Sub,
      id: this._genId(),
      name: RocketChatCollection.StreamUserPresence,
      params: [
        "",
        {
          added: userIds,
        },
      ],
    } as IEventRequest);
  }

  subscribeRoomsChanged(userId: string) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Sub,
      id: this._genId(),
      name: RocketChatCollection.StreamNotififyUser,
      params: [
        userId + "/rooms-changed",
        {
          useCollection: false,
          args: [],
        },
      ],
    } as IEventRequest);
  }

  subscribeSubscriptionsChanged(userId: string) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Sub,
      id: this._genId(),
      name: RocketChatCollection.StreamNotififyUser,
      params: [
        userId + "/subscriptions-changed",
        {
          useCollection: false,
          args: [],
        },
      ],
    } as IEventRequest);
  }

  unsubEvent(id: string) {
    return this.sendMessage({
      msg: RocketChatMsgTypes.Unsub,
      id,
    });
  }
}
