<template>
  <div class="empty-detail">
    <div class="empty-detail__image">
      <empty />
    </div>

    <div class="empty-detail__text">
      <h3 class="empty-detail__text__title">Bắt đầu cuộc trò chuyện</h3>
      <p class="empty-detail__text__description">
        Hãy bắt đầu cuộc trò chuyện để thương lượng những deals hot với NCC ngay
        nhé!
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Empty from "@/assets/images/marketplace/empty.vue";

@Component({
  components: { Empty },
})
export default class EmptyDetail extends Vue {}
</script>
