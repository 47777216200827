<template>
  <div id="operator-chat">
    <component :is="ChatComponent" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ROUTER from "@/scopes/operator/constants/router";
import GlobalMixin from "@/mixins/Global.mixin";

@Component
export default class App extends Mixins(GlobalMixin) {
  get ChatComponent() {
    return ROUTER[this.activeComponent].component;
  }
}
</script>
