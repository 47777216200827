import UserListPage from "@/scopes/marketplace/device/mobile/pages/index.vue";
import ChatDetail from "@/scopes/marketplace/device/mobile/pages/detail/_id.vue";
import PAGE_NAME from "@/constants/pages-name";

const ROUTER = {
  [PAGE_NAME.CHAT_LIST]: {
    name: PAGE_NAME.CHAT_LIST,
    component: UserListPage,
  },

  [PAGE_NAME.CHAT_DETAIL]: {
    name: PAGE_NAME.CHAT_DETAIL,
    component: ChatDetail,
  },
};

export default ROUTER;
