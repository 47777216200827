<template>
  <div
    class="chat-item"
    :class="[
      { 'chat-item--unread': data.unread },
      {
        'chat-item--focus': conversationDetail?.roomId === data?.id,
      },
    ]"
    @click="goToDetailOfConversation()"
  >
    <div class="chat-item__avatar">
      {{ firstCharacter }}
      <!--      <img class="chat-item__avatar" :src="data?.user?.avatar" />-->
    </div>

    <div class="chat-item__room">
      <div class="chat-item__user">
        <div class="chat-item__name">
          <span class="hidden-text">{{ data?.name }}</span>
          <icon-star v-if="data?.statusRoom == 'important'" />
          <icon-check-circle v-if="data?.statusRoom == 'success'" />
          <icon-warning-octagon v-if="data?.statusRoom == 'spam'" />
        </div>
        <div class="chat-item__time" v-if="!dataStateOfRoom?.state">
          {{ data?.lastMessage?.ts | relative(true) }}
        </div>
        <div
          class="chat-item__time msg-draft"
          v-if="dataStateOfRoom?.state == 'draft' && !data?.unread"
        >
          Nháp
        </div>
        <div
          class="chat-item__time msg-sending"
          v-if="dataStateOfRoom?.state == 'sending'"
        >
          Đang gửi
        </div>
        <div
          class="chat-item__time msg-error"
          v-if="dataStateOfRoom?.state == 'sendError'"
        >
          Gửi lỗi
        </div>
      </div>

      <div class="chat-item__message">
        <div class="chat-item__message-content">
          <span>
            <span v-if="data.isOpsRep">KVC:</span>
          </span>
          {{ lastMessageTitle }}
        </div>
        <div class="chat-item__message-count" v-if="data.unread">
          {{ data.unread > 9 ? "9+" : data.unread }}
        </div>
      </div>
      <div class="chat-item__typing" v-if="data?.isSending">
        <div class="dot-pulse"></div>
        đang soạn tin
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconStar from "@/assets/images/operator/iconStarActive.vue";
import IconCheckCircle from "@/assets/images/operator/iconCheckCircleActive.vue";
import IconWarningOctagon from "@/assets/images/operator/iconWarningActive.vue";
import { getStateRoom } from "@/helpers/localstorage.helper";
import moment from "moment";
import { checkPermission } from "@/helpers/permission.helper";
import PERMISSION from "@/constants/permission";
import CommonMixin from "@/mixins/operator/Common.mixin";

@Component({
  components: { IconWarningOctagon, IconCheckCircle, IconStar },
  mixins: [CommonMixin],
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  computed: {
    lastMessageTitle() {
      if (!this.dataStateOfRoom?.message || this.data.unread) {
        return this.data?.lastMessage?.msg?.replace(/(<([^>]+)>)/gi, "");
      } else {
        return this.dataStateOfRoom?.message?.replace(/(<([^>]+)>)/gi, "")
      }
    },
    conversationDetail() {
      return this.$store.state.global.conversationDetail;
    },
    firstCharacter() {
      if (this.data?.customFields?.user_create_name) {
        return this.data?.customFields?.user_create_name.charAt(0)
      }
      return ""
    }
  },
  data() {
    return {
      dataStateOfRoom: null,
      stateOfRoomLocalStorage: localStorage.getItem("dataStateRoom")
    }
  },
  created() {
    this.initData()
  },
  mounted() {
    window.addEventListener('dataStateRoom-localstorage-changed', (event) => {
      this.initData()
    });
    this.autoSelectChannelFromUrl();
  },
  methods: {
    autoSelectChannelFromUrl() {
      const queryParams = new URLSearchParams(window.location.search);
      for (const [key, value] of queryParams.entries()) {
        if(key !== "key_search") continue;
        if (this.data.customFields.user_create_name === value) {
          this.goToDetailOfConversation();
        }
      }
    },
   initData() {
     this.dataStateOfRoom = getStateRoom(this.data.id)
   },
   async goToDetailOfConversation() {
    const hasPermission = await checkPermission(PERMISSION.CHAT_DETAIL);
    if (!hasPermission) {
      this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
      return;
    }
    document.title = '[' + this.data.name + '] - Chat';
     this.$store.dispatch("global/UPDATE_CONVERSATION_DETAIL", {
       roomId: this.data.id,
       roomName: this.data.name,
       customFields: this.data.customFields,
       user: this.data.user,
       statusRoom: this.data.statusRoom || undefined,
       userCurator: this.data.userCurator || null,
     });
   }
  }
})
export default class ChatItem extends Vue {}
</script>
