import { cloneDeep } from "lodash";

export function pushToTop<K, V>(map: Map<K, V>, key: K) {
  const clone = cloneDeep(map);
  const result = new Map();

  const value = clone.get(key);
  result.set(key, value);
  clone.delete(key);

  clone.forEach((value, key) => result.set(key, value));

  return result;
}
