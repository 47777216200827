<template>
  <div class="chat-detail">
    <div
      class="chat-detail__header"
      :class="{ 'chat-detail__header--in': room }"
    >
      <div class="chat-detail__room">
        <template v-if="room">
          <div class="chat-detail__avatar">
            <img :src="room.avatar" alt="" />
          </div>
          <div class="chat-detail__name">{{ room.name }}</div>
        </template>
      </div>

      <img
        src="../../../../../../assets/images/marketplace/close.svg"
        alt=""
        @click="onClose"
      />
    </div>

    <warning-section v-if="supplierInfo && supplierInfo.warning_title">
      <template #title>{{ supplierInfo.warning_title }}</template>
      <template #description>{{ supplierInfo.warning_content }}</template>
    </warning-section>

    <div class="chat-detail__wrapper">
      <template v-if="messages.size">
        <chat-detail-by-date
          v-for="date in messages.keys()"
          :key="date"
          :date="date"
          :data-source="messages.get(date)"
        />
      </template>

      <template v-else-if="!room?.isCustomerService">
        <empty-detail />
      </template>

      <template v-if="room?.isCustomerService">
        <empty-customer-service />
      </template>
    </div>

    <div class="chat-attachment" v-if="attachment">
      <product-attachment v-if="attachment.type" :data="attachment.payload" />
    </div>

    <div class="chat-detail__footer" v-if="roomId">
      <label class="chat-detail__image" for="image-input">
        <image-icon />
        <input
          id="image-input"
          type="file"
          accept="image/*"
          @change="handleUploadImage"
        />
      </label>

      <textarea
        autofocus
        class="chat-detail__input"
        placeholder="Nhập tin nhắn"
        @focus="toggleFocus"
        @focusout="toggleFocus"
        v-model="message"
        @keypress="handleKeyPress"
        @keyup.delete="handleDelete"
        ref="input"
      />

      <div class="chat-detail__button">
        <div class="chat-detail__send" @click="handleSendMessage">
          <send :is-active="!!message?.trim()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import ChatDetailByDate from "@/scopes/marketplace/components/detail/ChatDetailByDate.vue";
import EmptyDetail from "@/scopes/marketplace/components/detail/EmptyDetail.vue";
import Send from "@/assets/images/send.vue";
import ImageIcon from "@/assets/images/marketplace/image.vue";
import MarketplaceChatDetailMixin from "@/mixins/marketplace/MarketplaceChatDetail.mixin";
import ProductAttachment from "@/scopes/marketplace/components/detail/attachments/ProductAttachment.vue";
import { RoomType } from "@/enums/room.enum";
import EmptyCustomerService from "@/scopes/marketplace/components/detail/EmptyCustomerService.vue";
import WarningSection from "@/scopes/marketplace/components/WarningSection.vue";

@Component({
  components: {
    WarningSection,
    EmptyCustomerService,
    ProductAttachment,
    Send,
    ImageIcon,
    EmptyDetail,
    ChatDetailByDate,
  },
})
export default class ChatDetail extends Mixins(MarketplaceChatDetailMixin) {
  wrapper!: HTMLElement;

  @Watch("roomId")
  public async onRoomIdChange(val: string, oldVal: string | null) {
    if (this.wrapper)
      this.wrapper.removeEventListener("scroll", this.handleScroll);

    if (this.attachment && val !== oldVal && oldVal !== null) {
      this.attachment = null;
    }

    if (!this.details.has(val)) {
      this.count = null;
      this.offset = 0;
      await this.getChatDetail();
    } else {
      this.parseMessages();
    }

    await this.getRoom();

    this.$nextTick(() => {
      this.wrapper.scrollTop = this.wrapper.scrollHeight;
      this.wrapper.addEventListener("scroll", this.handleScroll);
    });

    if (this.room && this.room.supplier_refer_id) {
      window.dispatchEvent(
        new CustomEvent("dispatch", {
          detail: {
            name: "openChatDetail",
            body: {
              supplier_refer_id: this.room.supplier_refer_id,
            },
          },
        })
      );
    }
  }

  public async created(): Promise<void> {
    await this.getUser();

    window.addEventListener("dispatch", this.handleOpenChat);
  }

  mounted() {
    this.wrapper = document.querySelector(
      ".chat-detail__wrapper"
    ) as HTMLElement;
  }

  public beforeDestroy() {
    if (this.wrapper)
      this.wrapper.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("dispatch", this.handleOpenChat);
  }

  public async handleScroll() {
    if (this.wrapper) {
      const { scrollTop, scrollHeight, clientHeight } = this.wrapper;

      if (
        scrollHeight - Math.abs(scrollTop) <= clientHeight * 2 &&
        !this.isLoadingMore
      ) {
        await this.loadMore();
      }
    }
  }

  public handleKeyPress(e: any) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (this.message?.trim()) {
        this.handleSendMessage();
      }
    }

    const element = e.target;

    setTimeout(() => {
      this.onChangeMessage(e);
      element.scrollTop = element.scrollHeight;
      element.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }

  public handleDelete(e: any) {
    e.preventDefault();
    const element = e.target;
    if (
      !this.message ||
      (element.selectionStart === 0 &&
        element.selectionEnd === element.value.length)
    ) {
      (e.target as any).style.height = "42px";
    }
  }

  public onClose() {
    this.$emit("close");
  }

  public onChangeRoom() {
    if (this.wrapper)
      this.wrapper.removeEventListener("scroll", this.handleScroll);
  }

  async handleOpenChat(event: any) {
    if (event.detail.name === "openChat") {
      this.$store.commit(
        "marketplace/navigator/UPDATE_CURRENT_ROOM_ID",
        event.detail.body.payload.room_id
      );
      this.$store.commit(
        "marketplace/navigator/UPDATE_CURRENT_ROOM_TYPE",
        event.detail.body.payload.type || RoomType.DIRECT
      );

      switch (event.detail.body.type) {
        case "product":
          await this.getRoom(event.detail.body.payload.usernames);
          this.attachment = {
            type: event.detail.body.type,
            payload: event.detail.body.payload.data,
          };
          break;

        case "customer_service":
        default:
          break;
      }

      this.$emit("open");
    }
  }

  public markRoomAsRead() {
    if (
      this.room?.id &&
      this.$store.state.marketplace.conversation.data.get(this.room.id).unread >
        0
    ) {
      this.$http.markRoomAsRead(this.room.id);
    }
  }
}
</script>
