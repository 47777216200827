<template>
  <div
    class="chat-detail-item"
    :class="{ 'chat-detail-item--receive': message.receive }"
  >
    <div class="chat-detail-item-content">
      <div class="custom-tooltip">{{ parseDateTime(message.ts) }}</div>
      <component
        :is="MessageComponent"
        :message="message"
        v-if="message.type == TYPE_PLAINTEXT"
        class="message-component"
      />
      <div v-else-if="message.type == TYPE_IMAGE" class="image-message">
        <img
          @click="previewImage(message.content)"
          :src="message.content"
          alt=""
        />
      </div>
      <a
        v-else-if="message.type == TYPE_LINK"
        class="chat-text-message mt-1"
        target="_blank"
        :href="message.content"
        >{{ message.content }}</a
      >
    </div>
    <ModalPreviewImage
      v-if="showPreview"
      :url="url"
      @closeModal="showPreview = false"
      @updateModal="showPreview = $event"
    ></ModalPreviewImage>
  </div>
</template>

<script>
import { Component, Mixins } from "vue-property-decorator";
import ChatDetailItemMixin from "@/mixins/ChatDetailItem.mixin";
import ModalPreviewImage from "@/scopes/operator/components/modal/ModalPreviewImage.vue";
import moment from "moment";
import MESSAGE_TYPE from "@/constants/message-type";

@Component({
  components: {
    ModalPreviewImage,
  },
})
export default class ChatDetailItem extends Mixins(ChatDetailItemMixin) {
  showPreview = false;
  url = "";
  TYPE_IMAGE = MESSAGE_TYPE.IMAGE;
  TYPE_PLAINTEXT = MESSAGE_TYPE.PLAIN_TEXT;
  TYPE_LINK = MESSAGE_TYPE.LINK;
  previewImage(url) {
    this.url = url;
    this.showPreview = true;
  }
  parseDateTime(time) {
    return moment(moment(time)).format("HH:mm DD/MM/YYYY");
  }
}
</script>
