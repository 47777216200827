import { GlobalState } from "@/store/shared/global/state";
import { cloneDeep } from "lodash";
import { pushToTop } from "@/helpers/map.helper";
import { CHAT_LIST_PAGE_SIZE } from "@/constants/app";

export default {
  SET_SCOPE(state: GlobalState, scope: string): void {
    state.scope = scope;
  },

  SET_DEVICE(state: GlobalState, device: string): void {
    state.device = device;
  },

  SET_TYPE_COMPONENT(state: GlobalState, typeComponent: string): void {
    state.typeComponent = typeComponent;
  },

  SET_CONVERSATION_DETAIL(state: GlobalState, detail: any): void {
    state.conversationDetail = detail;
  },

  SET_PAGE_STACK(state: GlobalState, payload: any): void {
    if (Array.isArray(payload)) {
      state.pageStack = payload;
    } else {
      state.pageStack = [payload];
    }
  },

  PUSH_PAGE(state: GlobalState, payload: any): void {
    state.pageStack.push(payload);
  },

  POP_PAGE(state: GlobalState): void {
    state.pageStack.pop();
  },
  CLEAR_STACK(state: GlobalState): void {
    state.pageStack = [];
  },

  SET_CHAT_LIST(state: GlobalState, payload: any): void {
    const newUsers = payload.users;
    const newUsersMap = new Map();
    if (payload.offset) {
      state.users.forEach((user: any) => newUsersMap.set(user.id, user));
    }
    newUsers.forEach((user: any) => newUsersMap.set(user.id, user));

    state.users = newUsersMap;
    state.total = payload.total;
    state.offset = payload.offset + CHAT_LIST_PAGE_SIZE;
    state.count = payload.count;
    state.isGotAll = payload.count === 0;
  },

  SET_OFFSET_CHAT_LIST(state: GlobalState, offset: number): void {
    state.offset = offset;
  },

  UPDATE_DETAIL(state: GlobalState, payload: any): void {
    const { rid, data } = payload;
    const detailsClone = cloneDeep(state.details);

    if (detailsClone.has(rid)) {
      const current = detailsClone.get(rid);
      detailsClone.set(rid, current.concat(data.messages));
    } else {
      detailsClone.set(rid, data.messages);
    }

    state.details = detailsClone;
  },

  RESET_DETAIL(state: GlobalState): void {
    state.details = new Map();
  },

  PUSH_MESSAGE(state: GlobalState, payload: any): void {
    const { rid, message } = payload;

    const detailsClone = cloneDeep(state.details);

    if (detailsClone.has(rid)) {
      const current = detailsClone.get(rid);
      detailsClone.set(rid, [message].concat(current));
    } else {
      detailsClone.set(rid, [message]);
    }

    state.details = detailsClone;
  },

  SET_CHAT_ITEM(state: GlobalState, payload: any): void {
    const { rid, list } = payload;
    const usersClone = cloneDeep(state.users);

    if (!usersClone.has(payload.rid)) {
      usersClone.set(rid, { ...list });
    } else {
      const current = usersClone.get(rid);
      usersClone.set(rid, { ...current, ...list });
    }

    state.users = pushToTop(usersClone, rid);
  },

  SET_CHAT_DETAIL(state: GlobalState, payload: any): void {
    if (payload) {
      const { rid, detail } = payload;
      const detailsClone = cloneDeep(state.details);
      if (detailsClone.has(rid)) {
        const current = detailsClone.get(rid);
        const messageExit = current.findIndex(
          (item: { _id: string }) => item._id == detail._id
        );
        if (messageExit < 0) {
          detailsClone.set(rid, [detail].concat(current));
        }
      }

      state.details = detailsClone;
    }
  },

  SET_UNREAD_COUNT(state: GlobalState, payload: any): void {
    const { rid, data } = payload;
    const usersClone = cloneDeep(state.users);

    if (usersClone.has(rid)) {
      const current = usersClone.get(rid);
      usersClone.set(rid, {
        ...current,
        unread: data,
      });
    }

    state.users = usersClone;
  },

  SET_CONVERSATION_DETAIL_CUSTOM_FIELD(state: GlobalState, payload: any): void {
    const usersClone = cloneDeep(state.users);
    const rid = payload.roomId;
    const current = usersClone.get(rid);
    const update = {
      customFields: payload.customFields,
      statusRoom: payload.statusRoom,
    };
    usersClone.set(rid, { ...current, ...update });

    state.users = usersClone;
    const conversationDetailClone = cloneDeep(state.conversationDetail);
    state.conversationDetail = { ...conversationDetailClone, ...update };
  },

  SET_CONVERSATION_DETAIL_USER_CURATOR(state: GlobalState, payload: any): void {
    const usersClone = cloneDeep(state.users);
    const rid = payload.roomId;
    const current = usersClone.get(rid);
    const update = {
      customFields: payload.customFields,
      userCurator: payload.userCurator,
    };
    usersClone.set(rid, { ...current, ...update });

    state.users = usersClone;
    const conversationDetailClone = cloneDeep(state.conversationDetail);
    state.conversationDetail = { ...conversationDetailClone, ...update };
  },

  SET_CHAT_TYPING(state: GlobalState, payload: any): void {
    const { rid, isSending } = payload;
    const usersClone = cloneDeep(state.users);

    const current = usersClone.get(rid);
    current.isSending = isSending;
    usersClone.set(rid, { ...current });
    state.users = pushToTop(usersClone, rid);
  },
};
