export enum InvoiceStatus {
  InvoiceStatusWaitConfirm = 1,
  InvoiceStatusConfirmed = 2,
  InvoiceStatusCancelled = 3,
}

export const InvoiceStatusText = {
  [InvoiceStatus.InvoiceStatusWaitConfirm]: "Chờ xác nhận",
  [InvoiceStatus.InvoiceStatusConfirmed]: "Đã xác nhận",
  [InvoiceStatus.InvoiceStatusCancelled]: "Đã hủy",
};

export const InvoiceStatusClassName = {
  [InvoiceStatus.InvoiceStatusWaitConfirm]:
    "invoice__block-status invoice__waiting-confirm",
  [InvoiceStatus.InvoiceStatusConfirmed]:
    "invoice__block-status invoice__confirmed",
  [InvoiceStatus.InvoiceStatusCancelled]:
    "invoice__block-status invoice__cancelled",
};
