<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27290_4046)">
      <path
        d="M14.9375 12.8875L14.0437 4.88751C14.0174 4.64277 13.9012 4.41651 13.7176 4.25255C13.534 4.08859 13.2961 3.9986 13.05 4.00001H10.9562C10.8359 3.30318 10.4733 2.67126 9.9324 2.21577C9.39151 1.76027 8.70711 1.51048 7.99997 1.51048C7.29284 1.51048 6.60843 1.76027 6.06754 2.21577C5.52665 2.67126 5.16405 3.30318 5.04372 4.00001H2.94997C2.70383 3.9986 2.46592 4.08859 2.28233 4.25255C2.09874 4.41651 1.98253 4.64277 1.95622 4.88751L1.06247 12.8875C1.04733 13.028 1.06176 13.17 1.10481 13.3046C1.14787 13.4391 1.2186 13.5632 1.31247 13.6688C1.40602 13.7734 1.52067 13.857 1.64886 13.914C1.77706 13.9711 1.91589 14.0004 2.05622 14H13.9437C14.0841 14.0004 14.2229 13.9711 14.3511 13.914C14.4793 13.857 14.5939 13.7734 14.6875 13.6688C14.7813 13.5632 14.8521 13.4391 14.8951 13.3046C14.9382 13.17 14.9526 13.028 14.9375 12.8875ZM5.99997 6.50001C5.99997 6.63262 5.94729 6.75979 5.85352 6.85356C5.75976 6.94733 5.63258 7.00001 5.49997 7.00001C5.36736 7.00001 5.24019 6.94733 5.14642 6.85356C5.05265 6.75979 4.99997 6.63262 4.99997 6.50001V5.50001C4.99997 5.3674 5.05265 5.24022 5.14642 5.14645C5.24019 5.05269 5.36736 5.00001 5.49997 5.00001C5.63258 5.00001 5.75976 5.05269 5.85352 5.14645C5.94729 5.24022 5.99997 5.3674 5.99997 5.50001V6.50001ZM6.06247 4.00001C6.17262 3.5698 6.42282 3.18849 6.77363 2.91619C7.12443 2.64389 7.55589 2.49608 7.99997 2.49608C8.44406 2.49608 8.87551 2.64389 9.22632 2.91619C9.57712 3.18849 9.82732 3.5698 9.93747 4.00001H6.06247ZM11 6.50001C11 6.63262 10.9473 6.75979 10.8535 6.85356C10.7598 6.94733 10.6326 7.00001 10.5 7.00001C10.3674 7.00001 10.2402 6.94733 10.1464 6.85356C10.0526 6.75979 9.99997 6.63262 9.99997 6.50001V5.50001C9.99997 5.3674 10.0526 5.24022 10.1464 5.14645C10.2402 5.05269 10.3674 5.00001 10.5 5.00001C10.6326 5.00001 10.7598 5.05269 10.8535 5.14645C10.9473 5.24022 11 5.3674 11 5.50001V6.50001Z"
        fill="#9CA5B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_27290_4046">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconHandbag extends Vue {}
</script>
