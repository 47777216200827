<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_25863_2328)">
      <path
        d="M21.3468 4.52798L16.4343 21.9561C16.3524 22.2512 16.1815 22.5139 15.945 22.7085C15.7085 22.9031 15.4178 23.0201 15.1124 23.0436H14.9812C14.6986 23.043 14.422 22.9626 14.1832 22.8116C13.9444 22.6607 13.753 22.4454 13.6312 22.1905L10.2843 15.1217C10.2514 15.0508 10.241 14.9714 10.2544 14.8944C10.2678 14.8173 10.3044 14.7462 10.3593 14.6905L15.1687 9.8811C15.2439 9.81288 15.3046 9.73008 15.3469 9.63771C15.3892 9.54535 15.4123 9.44534 15.4148 9.34378C15.4173 9.24221 15.3992 9.14119 15.3615 9.04685C15.3237 8.95252 15.2672 8.86683 15.1954 8.79499C15.1236 8.72314 15.0379 8.66665 14.9435 8.62893C14.8492 8.5912 14.7482 8.57304 14.6466 8.57554C14.545 8.57804 14.445 8.60115 14.3527 8.64347C14.2603 8.68579 14.1775 8.74644 14.1093 8.82173L9.29991 13.6405C9.24419 13.6954 9.17306 13.732 9.096 13.7454C9.01895 13.7588 8.93963 13.7483 8.86866 13.7155L1.83741 10.3874C1.56221 10.2574 1.33117 10.0495 1.17287 9.78961C1.01457 9.52966 0.935966 9.22901 0.946784 8.92485C0.963379 8.61169 1.07832 8.31173 1.27525 8.06767C1.47218 7.82361 1.74107 7.64788 2.04366 7.56548L19.5093 2.6436C19.8148 2.55636 20.1401 2.56836 20.4383 2.67788C20.7365 2.7874 20.9922 2.98877 21.1687 3.25298C21.287 3.44073 21.3641 3.6515 21.3948 3.87132C21.4255 4.09114 21.4092 4.31497 21.3468 4.52798Z"
        :fill="isActive ? '#41AD49' : '#9CA5B3'"
      />
    </g>
    <defs>
      <clipPath id="clip0_25863_2328">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Send extends Vue {
  @Prop({ required: true, default: false })
  private readonly isActive!: boolean;
}
</script>
