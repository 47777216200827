import { MarketAPI } from "@/services/MarketAPI";
import { Account } from "@/@types/sdk.type";
import { Store } from "vuex";
import ChatListController from "@/controllers/ChatList.controller";

export default class MarketplaceAdapter {
  private static instance?: MarketplaceAdapter;
  private static $api: MarketAPI;
  private static $account: Account;
  private static $store: Store<object>;
  private static CS_ROOM_ID = "";

  constructor(account?: Account, store?: Store<object>) {
    if (!MarketplaceAdapter.instance) {
      MarketplaceAdapter.instance = this;
      MarketplaceAdapter.$account = account as Account;
      MarketplaceAdapter.$api = new MarketAPI(
        true,
        (account as Account).accessToken
      );
      MarketplaceAdapter.$store = store as Store<object>;
    }

    return MarketplaceAdapter.instance;
  }

  public async getCustomerServiceRoom() {
    try {
      const { data } = await MarketplaceAdapter.$api.post(
        "chat/channel/customer-service",
        {}
      );

      window.CHAT_SDK.$customerServiceRoomId = data._id;
    } catch (error) {
      console.log(error);
    }
  }

  public async getRooms() {
    await new ChatListController(
      MarketplaceAdapter.$account,
      MarketplaceAdapter.$store
    ).getRooms();
  }
}
