<template>
  <div class="chat-image-message" @click="handleShowImage">
    <img :src="message.content" alt="" @error="handleErrorImage" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ErrorImage from "@/assets/images/marketplace/error-image.svg";

@Component
export default class ImageMessage extends Vue {
  @Prop({ required: true }) private readonly message!: any;

  get device() {
    return this.$store.state.global.device;
  }

  public handleShowImage() {
    if (this.device === "desktop") {
      this.$imageModal.show([this.message.content]);
    }
  }

  public handleErrorImage(e: any) {
    e.target.src = ErrorImage;
  }
}
</script>
