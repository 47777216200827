import UserList from "@/scopes/operator/features/marketplace/components/list/index.vue";
import PAGE_NAME from "@/constants/pages-name";

const ROUTER = {
  [PAGE_NAME.CHAT_LIST]: {
    name: PAGE_NAME.CHAT_LIST,
    component: UserList,
  },
  [PAGE_NAME.CHAT_BUBBLE_OPERATOR]: {
    name: PAGE_NAME.CHAT_BUBBLE_OPERATOR,
    component: UserList,
  },
};

export default ROUTER;
