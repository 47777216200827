<template>
  <div class="operator-chat-list">
    <left-of-list :data-source="dataSource"></left-of-list>
    <div v-if="conversationDetail" class="chat-content">
      <center-of-list></center-of-list>
      <right-of-list></right-of-list>
    </div>
    <div v-else class="chat-empty">
      <EmptyState />
      <div class="chat-empty__block">
        <div class="chat-empty__block__title">
          Chưa chọn cuộc trò chuyện nào
        </div>
        <div class="chat-empty__block__sub-title">
          Chọn một cuộc trò chuyện ở bên trái để bắt đầu
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ChatListMixin from "@/mixins/operator/ChatList.mixin";
import LeftOfList from "./part/LeftOfList.vue";
import CenterOfList from "./part/CenterOfList.vue";
import RightOfList from "./part/RightOfList.vue";
import EmptyState from "@/assets/images/operator/emptyState.vue";

@Component({
  components: { LeftOfList, CenterOfList, RightOfList, EmptyState },
})
export default class ChatList extends Mixins(ChatListMixin) {}
</script>
