<template>
  <div class="image-modal" id="image-modal">
    <div class="image-modal__action">
      <div class="image-modal__close">
        <close-icon @click.native="$imageModal.hide()" />
      </div>
    </div>

    <div class="image-modal__wrapper" v-if="images.length">
      <div class="image-modal__wrapper-body">
        <img :src="images[0]" alt="" @error="handleErrorImage" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CloseIcon from "@/assets/images/marketplace/modal/close.vue";
import ErrorImage from "@/assets/images/marketplace/error-image.svg";

@Component({
  components: {
    CloseIcon,
  },
})
export default class ImageModal extends Vue {
  public images: string[] = [];

  public show(images: string[]) {
    this.images = images;
  }

  public hide() {
    this.images = [];
  }

  public handleErrorImage(e: any) {
    e.target.src = ErrorImage;
  }
}
</script>

<style lang="scss" scoped>
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#020d1bb2, 0.7);
  z-index: 10000;
  display: flex;
  flex-direction: column;

  &__action {
    height: 64px;
    background-color: rgba(#020d1bb2, 0.4);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 32px;
  }

  &__close {
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-height: calc(100% - 64px);

    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      img {
        max-width: 80%;
        min-height: 55vh;
        max-height: calc(90vh - 64px);
        width: auto;
        object-fit: contain;
        object-position: center;
        border-radius: 20px;
      }
    }
  }
}
</style>
