<template>
  <div class="chat-attachment-order">
    <p class="chat-attachment-order__massage">Bạn đang trao đổi về đơn hàng:</p>
    <div class="chat-attachment-order__data">
      <div class="chat-attachment-order__image">
        <img :src="data.image" alt="" @error="handleErrorImg" />
      </div>

      <div class="chat-attachment-order__info">
        <div class="chat-attachment-order__name">
          Mã đơn: {{ data.order_code }}
        </div>
        <div class="chat-attachment-order__price">
          Tổng đơn hàng: {{ data.price | currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOrderAttachment } from "@/interfaces/marketplace/message.interface";
import PreviewImage from "@/assets/images/marketplace/preview.svg";

@Component
export default class OrderAttachment extends Vue {
  @Prop({ required: true }) private readonly data!: IOrderAttachment;

  public handleErrorImg(event: any) {
    event.target.src = PreviewImage;
  }
}
</script>
