<template>
  <div class="chat-page">
    <slot name="header-cta"></slot>

    <div class="chat-page__header">
      <div class="chat-page__go-back" @click="goBack">
        <back />
      </div>

      <slot name="header"></slot>
    </div>

    <div class="chat-page__header-bottom">
      <slot name="header-bottom"></slot>
    </div>

    <div class="chat-page__body" :id="id">
      <slot name="body"></slot>
    </div>

    <div class="chat-page__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import Back from "@/assets/images/back.vue";
import NavigatorMixin from "@/mixins/Navigator.mixin";

@Component({
  components: { Back },
})
export default class Page extends Mixins(NavigatorMixin) {
  @Prop({ required: false, default: false })
  private readonly customBack!: boolean;

  @Prop({ required: false, default: false })
  private readonly id!: string;

  public goBack() {
    this.customBack ? this.$emit("back") : this.goBackPage();
  }
}
</script>
