import state from "@/store/shared/global/state";
import mutations from "@/store/shared/global/mutations";
import actions from "@/store/shared/global/actions";

const global = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};

export default global;
