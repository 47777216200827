<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27451_110503)">
      <path
        d="M20.2501 4.5H3.75008C3.35225 4.5 2.97072 4.65804 2.68942 4.93934C2.40811 5.22064 2.25008 5.60218 2.25008 6V20.8875C2.2471 21.1744 2.32803 21.4559 2.48293 21.6974C2.63784 21.939 2.85995 22.1299 3.12195 22.2469C3.31649 22.3459 3.53178 22.3973 3.75008 22.3969C4.1039 22.3957 4.44592 22.2695 4.7157 22.0406L7.69695 19.5375L20.2501 19.5C20.6479 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.7501 18.3978 21.7501 18V6C21.7501 5.60218 21.592 5.22064 21.3107 4.93934C21.0294 4.65804 20.6479 4.5 20.2501 4.5V4.5ZM7.50008 13.125C7.27758 13.125 7.06007 13.059 6.87506 12.9354C6.69006 12.8118 6.54586 12.6361 6.46072 12.4305C6.37557 12.225 6.35329 11.9988 6.3967 11.7805C6.4401 11.5623 6.54725 11.3618 6.70458 11.2045C6.86192 11.0472 7.06237 10.94 7.2806 10.8966C7.49883 10.8532 7.72503 10.8755 7.9306 10.9606C8.13617 11.0458 8.31187 11.19 8.43548 11.375C8.5591 11.56 8.62508 11.7775 8.62508 12C8.62508 12.2984 8.50655 12.5845 8.29557 12.7955C8.0846 13.0065 7.79845 13.125 7.50008 13.125ZM12.0001 13.125C11.7776 13.125 11.5601 13.059 11.3751 12.9354C11.1901 12.8118 11.0459 12.6361 10.9607 12.4305C10.8756 12.225 10.8533 11.9988 10.8967 11.7805C10.9401 11.5623 11.0473 11.3618 11.2046 11.2045C11.3619 11.0472 11.5624 10.94 11.7806 10.8966C11.9988 10.8532 12.225 10.8755 12.4306 10.9606C12.6362 11.0458 12.8119 11.19 12.9355 11.375C13.0591 11.56 13.1251 11.7775 13.1251 12C13.1251 12.2984 13.0066 12.5845 12.7956 12.7955C12.5846 13.0065 12.2984 13.125 12.0001 13.125ZM16.5001 13.125C16.2776 13.125 16.0601 13.059 15.8751 12.9354C15.6901 12.8118 15.5459 12.6361 15.4607 12.4305C15.3756 12.225 15.3533 11.9988 15.3967 11.7805C15.4401 11.5623 15.5473 11.3618 15.7046 11.2045C15.8619 11.0472 16.0624 10.94 16.2806 10.8966C16.4988 10.8532 16.725 10.8755 16.9306 10.9606C17.1362 11.0458 17.3119 11.19 17.4355 11.375C17.5591 11.56 17.6251 11.7775 17.6251 12C17.6251 12.2984 17.5066 12.5845 17.2956 12.7955C17.0846 13.0065 16.7984 13.125 16.5001 13.125Z"
        fill="#9CA5B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_27451_110503">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconChatDot extends Vue {}
</script>
