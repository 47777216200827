import { randomString } from "@/helpers/string.helper";
import { getAppRoot } from "@/helpers/app.helper";
import MESSAGE_TYPE from "@/constants/message-type";
import { setStateRoom } from "@/helpers/localstorage.helper";
import { getPathImageMessage } from "@/helpers/message.helper";
import SCOPE from "@/scope";

export default {
  PUSH_PAGE({ commit }: any, payload: any) {
    commit("PUSH_PAGE", payload);
  },

  POP_PAGE({ commit }: any) {
    commit("POP_PAGE");
  },
  CLEAR_STACK({ commit }: any) {
    commit("CLEAR_STACK");
  },
  SET_DETAILS({ commit }: any, payload: any) {
    commit("UPDATE_DETAIL", payload);
  },

  RESET_DETAIL({ commit }: any, payload: any) {
    commit("RESET_DETAIL", payload);
  },

  async SEND_TEXT_MESSAGE({ commit, dispatch, rootState }: any, payload: any) {
    const $appRoot = getAppRoot();
    const _id = randomString(32);
    const now = new Date();
    const scope = rootState.global.scope;

    try {
      await $appRoot.$http.sendMessageText({
        _id: _id,
        rid: payload.rid,
        msg: payload.message,
      });

      if (scope === SCOPE.MARKETPLACE) {
        window.dispatchEvent(
          new CustomEvent("dispatch", {
            detail: {
              name: "sendMessage",
              body: {
                room_id: payload.rid,
                widget_id: payload.type,
                message_status: "success",
              },
            },
          })
        );
      }

      setStateRoom({
        roomId: payload.rid,
        state: "",
        message: "",
      });
    } catch (error) {
      console.log(error);

      if (scope === SCOPE.MARKETPLACE) {
        window.dispatchEvent(
          new CustomEvent("dispatch", {
            detail: {
              name: "sendMessage",
              body: {
                room_id: payload.rid,
                widget_id: payload.type,
                message_status: "fail",
              },
            },
          })
        );
      }

      setStateRoom({
        roomId: payload.rid,
        state: "sendError",
        message: "",
      });
    }
  },

  UPDATE_CHAT_ITEM({ commit, state }: any, payload: any) {
    if (state.scope === SCOPE.MARKETPLACE) {
      commit("marketplace/conversation/UPDATE_CONVERSATION_ITEM", payload, {
        root: true,
      });
    } else {
      commit("SET_CHAT_ITEM", payload);
    }
  },

  UPDATE_CHAT_DETAIL({ commit }: any, payload: any) {
    commit("SET_CHAT_DETAIL", payload);
  },

  UPDATE_CHAT_TYPING({ commit }: any, payload: any) {
    commit("SET_CHAT_TYPING", payload);
  },

  UPDATE_UNREAD_COUNT({ commit, state }: any, payload: any) {
    if (state.scope === SCOPE.MARKETPLACE) {
      commit("marketplace/conversation/SET_UNREAD_COUNT", payload, {
        root: true,
      });
    } else {
      commit("SET_UNREAD_COUNT", payload);
    }
  },

  UPDATE_CONVERSATION_DETAIL({ commit }: any, payload: any) {
    commit("SET_CONVERSATION_DETAIL", payload);
  },

  UPDATE_CONVERSATION_DETAIL_CUSTOM_FIELD({ commit }: any, payload: any) {
    commit("SET_CONVERSATION_DETAIL_CUSTOM_FIELD", payload);
  },

  UPDATE_CONVERSATION_DETAIL_USER_CURATOR({ commit }: any, payload: any) {
    commit("SET_CONVERSATION_DETAIL_USER_CURATOR", payload);
  },

  async SEND_FILE_MESSAGE({ commit, rootState }: any, payload: any) {
    const $appRoot = getAppRoot();
    const _id = randomString(32);
    const now = new Date();
    const scope = rootState.global.scope;

    try {
      await $appRoot.$http.sendMessageFile({
        _id: _id,
        file: payload.file,
        rid: payload.rid,
      });

      if (scope === SCOPE.MARKETPLACE) {
        window.dispatchEvent(
          new CustomEvent("dispatch", {
            detail: {
              name: "sendMessage",
              body: {
                room_id: payload.rid,
                widget_id: MESSAGE_TYPE.IMAGE,
                message_status: "success",
              },
            },
          })
        );
      }

      setStateRoom({
        roomId: payload.rid,
        state: "",
        message: "",
      });
    } catch (error) {
      console.log(error);

      if (scope === SCOPE.MARKETPLACE) {
        window.dispatchEvent(
          new CustomEvent("dispatch", {
            detail: {
              name: "sendMessage",
              body: {
                room_id: payload.rid,
                widget_id: MESSAGE_TYPE.IMAGE,
                message_status: "fail",
              },
            },
          })
        );
      }

      setStateRoom({
        roomId: payload.rid,
        state: "sendError",
        message: "",
      });
    }
  },
};
