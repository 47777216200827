const PERMISSION = {
  CHAT_LIST: "chat-read",
  CHAT_DETAIL: "chat-detail",
  CHAT_SEND: "chat-send",
  CHAT_CREATE_SAMPLE_MESSAGE: "chat-create-sample-message",
  CHAT_UPDATE_SAMPLE_MESSAGE: "chat-update-sample-message",
  CHAT_DELETE_SAMPLE_MESSAGE: "chat-delete-sample-message",
  CHAT_ASSIGN_USER: "chat-assign-user",
};

export default PERMISSION;
