<template>
  <div
    class="chat-item"
    :class="{ 'chat-item--unread': data.unread }"
    @click="gotoDetailPage"
  >
    <div class="chat-item__avatar">
      <img :src="data.avatar" />
    </div>

    <div class="chat-item__room">
      <div class="chat-item__user">
        <div class="chat-item__name">{{ data.name }}</div>
        <div class="chat-item__time" v-if="data.lastMessage">
          {{ data.lastMessage.ts | relative }}
        </div>
      </div>

      <div class="chat-item__message" v-if="data.lastMessage">
        <div class="chat-item__message-content">
          <span v-if="!data.receive">Bạn: </span>{{ data.lastMessage.msg }}
        </div>
        <div class="chat-item__message-count" v-if="data.unread">
          {{ data.unread > 99 ? "99+" : data.unread }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import NavigatorMixin from "@/mixins/Navigator.mixin";
import PAGE_NAME from "@/constants/pages-name";

@Component
export default class ChatItem extends Mixins(NavigatorMixin) {
  @Prop({ required: true }) private readonly data!: any;

  public gotoDetailPage() {
    this.$store.dispatch(
      "marketplace/navigator/SET_CURRENT_ROOM_ID",
      this.data.id
    );
    this.$store.dispatch(
      "marketplace/navigator/SET_CURRENT_ROOM_TYPE",
      this.data.type
    );

    this.gotoPage({
      name: PAGE_NAME.CHAT_DETAIL,
      params: { id: this.data.id },
      room: this.data,
    });

    if (this.data.supplier_refer_id) {
      window.dispatchEvent(
        new CustomEvent("dispatch", {
          detail: {
            name: "joinDirectMessage",
            body: { id: this.data.supplier_refer_id },
          },
        })
      );
    }
  }
}
</script>
