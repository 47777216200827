import { Component, Prop, Vue } from "vue-property-decorator";
import MESSAGE_TYPE from "@/constants/message-type";
import TextMessage from "@/scopes/marketplace/components/detail/types/TextMessage.vue";
import NotSupportMessage from "@/scopes/marketplace/components/detail/types/NotSupportMessage.vue";
import ProductMessage from "@/scopes/marketplace/components/detail/types/ProductMessage.vue";
import OrderMessage from "@/scopes/marketplace/components/detail/types/OrderMessage.vue";
import ImageMessage from "@/scopes/marketplace/components/detail/types/ImageMessage.vue";

@Component
export default class ChatDetailItemMixin extends Vue {
  @Prop({ required: true }) private readonly message: any;

  get MessageComponent() {
    switch (this.message.type) {
      case MESSAGE_TYPE.PLAIN_TEXT:
        return TextMessage;

      case MESSAGE_TYPE.PRODUCT:
        return ProductMessage;

      case MESSAGE_TYPE.ORDER:
        return OrderMessage;

      case MESSAGE_TYPE.IMAGE:
        return ImageMessage;

      default:
        return NotSupportMessage;
    }
  }
}
