<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27379_248162)">
      <path
        d="M19.1877 7.60938C19.1095 7.36327 18.9587 7.14654 18.7552 6.98761C18.5516 6.82868 18.3048 6.73496 18.0471 6.71875L13.4065 6.39844L11.6877 2.0625C11.5939 1.82375 11.4305 1.61867 11.2188 1.47382C11.0071 1.32896 10.7567 1.25099 10.5002 1.25C10.2437 1.25099 9.99335 1.32896 9.78164 1.47382C9.56992 1.61867 9.40656 1.82375 9.31271 2.0625L7.56271 6.42188L2.95334 6.71875C2.69592 6.73601 2.4496 6.83012 2.24625 6.9889C2.0429 7.14768 1.89188 7.36383 1.81271 7.60938C1.73141 7.85869 1.72666 8.12664 1.79907 8.37868C1.87148 8.63072 2.01773 8.85529 2.21896 9.02344L5.76584 12.0234L4.71115 16.1719C4.63818 16.4525 4.65131 16.7486 4.74884 17.0217C4.84637 17.2948 5.02378 17.5322 5.25802 17.7031C5.48539 17.8663 5.75628 17.9579 6.03602 17.9663C6.31577 17.9746 6.59164 17.8993 6.82834 17.75L10.4924 15.4297H10.508L14.4533 17.9219C14.6557 18.0534 14.8917 18.1239 15.133 18.125C15.3301 18.1235 15.5242 18.0767 15.7004 17.9884C15.8766 17.9002 16.0302 17.7727 16.1494 17.6157C16.2686 17.4588 16.3502 17.2766 16.388 17.0832C16.4258 16.8898 16.4188 16.6903 16.3674 16.5L15.2502 11.9609L18.7815 9.02344C18.9827 8.85529 19.1289 8.63072 19.2013 8.37868C19.2738 8.12664 19.269 7.85869 19.1877 7.60938Z"
        fill="#FA8C16"
      />
    </g>
    <defs>
      <clipPath id="clip0_27379_248162">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconStarActive extends Vue {}
</script>
