<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0.875C6.89303 0.875 5.32214 1.35152 3.986 2.24431C2.64985 3.1371 1.60844 4.40605 0.993482 5.8907C0.37852 7.37535 0.217618 9.00901 0.531123 10.5851C0.844628 12.1612 1.61846 13.6089 2.75476 14.7452C3.89106 15.8815 5.3388 16.6554 6.9149 16.9689C8.49099 17.2824 10.1247 17.1215 11.6093 16.5065C13.094 15.8916 14.3629 14.8502 15.2557 13.514C16.1485 12.1779 16.625 10.607 16.625 9C16.6209 6.84638 15.7635 4.78216 14.2407 3.25932C12.7178 1.73648 10.6536 0.87913 8.5 0.875ZM12.3672 7.57812L7.78907 11.9531C7.67071 12.0645 7.51406 12.126 7.35157 12.125C7.27214 12.1261 7.19328 12.1115 7.11953 12.082C7.04578 12.0525 6.97861 12.0087 6.92188 11.9531L4.63282 9.76562C4.56933 9.71023 4.51769 9.64257 4.48102 9.56672C4.44434 9.49086 4.42338 9.40837 4.4194 9.32421C4.41542 9.24004 4.42849 9.15594 4.45784 9.07696C4.48719 8.99798 4.53221 8.92575 4.59019 8.86461C4.64816 8.80347 4.7179 8.75469 4.79522 8.72119C4.87253 8.68769 4.95582 8.67017 5.04007 8.66968C5.12433 8.66919 5.20781 8.68574 5.28551 8.71834C5.36321 8.75094 5.43351 8.79891 5.49219 8.85938L7.35157 10.6328L11.5078 6.67188C11.6293 6.56585 11.7871 6.51091 11.9482 6.51853C12.1093 6.52615 12.2612 6.59575 12.3722 6.71277C12.4832 6.8298 12.5446 6.98519 12.5437 7.14646C12.5428 7.30773 12.4795 7.4624 12.3672 7.57812Z"
      fill="#02B875"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconCheckCircleActive extends Vue {}
</script>
