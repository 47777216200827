<template>
  <div class="operator-chat-list-left">
    <div class="operator-chat-list-left-header">
      <button class="back-to-ops" @click.prevent="backToOps">
        <icon-exit />
      </button>
      <div class="header-right">
        <b>Chat</b>
        <div class="dropdown-toggle">
          <div
            ref="tgtypeshow"
            class="toggle-title"
            @click.prevent="toggleElement(aliasTypeFilter)"
          >
            {{ typeChoose?.title }} <icon-angle-down />
          </div>
          <div class="toggle-active" ref="tgtypeactive" v-if="showTypeFilter">
            <ul class="pretty-checkbox-mobile p-0 m-0">
              <li
                v-for="(filter, index) in filterTypeArray"
                :key="index"
                class="has-pretty-child"
                :class="{ active: typeChoose?.value === filter.value }"
                @click="chooseTypeFilter(filter)"
              >
                {{ filter.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="operator-chat-list-left-search-order mr">
      <div class="search-container">
        <input
          class="input-custom"
          type="text"
          v-model="keySearch"
          @keyup.enter="searchConversation()"
          placeholder="Tìm kiếm"
        />
        <IconSearch class="search-icon" />
      </div>
      <div class="dropdown-toggle">
        <div
          ref="tgstatusshow"
          class="toggle-button"
          @click.prevent="toggleElement(aliasStatusFilter)"
        >
          <icon-funnel />
        </div>
        <div
          class="toggle-active width"
          ref="tgstatusactive"
          v-if="showStatusFilter"
        >
          <ul class="pretty-checkbox-mobile p-0 m-0">
            <li
              v-for="(filter, index) in filterStatusArray"
              :key="index"
              class="has-pretty-child"
              :class="{ active: statusChoose?.value === filter.value }"
              @click="chooseStatusFilter(filter)"
            >
              {{ filter.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="operator-chat-list-left-filter">
      <div
        v-if="userChoose"
        class="filter-item active"
        @click="chooseUserFilter(null)"
      >
        {{ userChoose?.title }}
      </div>
      <div
        v-for="(filter, index) in statusDefaultShow"
        :key="index"
        class="filter-item"
        :class="{
          active:
            statusChoose?.value === filter.value || filter.value === 'assigned',
        }"
        @click="chooseStatusFilter(filter)"
      >
        {{ filter.title }}
      </div>
    </div>
    <span class="total" v-if="!showListUser">Tất cả: {{ total }}</span>
    <div v-if="!showListUser">
      <div
        v-show="dataSource.size"
        class="operator-chat-list-left-conversation"
        id="scroll-container"
      >
        <chat-item
          v-for="key in dataSource.keys()"
          :key="key"
          :data="dataSource.get(key)"
        />
        <div v-if="loading" class="operator-chat-list-left-loading-state">
          <div class="loading-state-spin"></div>
        </div>
      </div>
      <div
        v-if="!dataSource.size"
        class="operator-chat-list-left-conversation text-center pt-3 pb-3 size-null"
      >
        Chưa có cuộc trò chuyện nào.
      </div>
    </div>
    <div v-else>
      <list-user
        @isShow="hiddenListUser($event)"
        @choose="chooseUserFilter($event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ChatItem from "@/scopes/operator/components/list/ChatItem.vue";
import IconExit from "@/assets/images/operator/iconExit.vue";
import IconAngleDown from "@/assets/images/operator/iconAngleDown.vue";
import IconSearch from "@/assets/images/operator/iconSearch.vue";
import IconFunnel from "@/assets/images/operator/iconFunnel.vue";
import ChatListController from "@/controllers/ChatList.controller";
import ListUser from "@/scopes/operator/components/filter/ListUser.vue";
import ChatDetailController from "@/controllers/ChatDetail.controller";

const statusDefaultShowInit = [
  { value: "unread", title: "Chưa đọc" },
  { value: "important", title: "Quan trọng" },
];
@Component({
  components: {
    ListUser,
    IconFunnel,
    IconSearch,
    IconAngleDown,
    IconExit,
    ChatItem,
  },
})
export default class ChatList extends Vue {
  @Prop({ required: true }) private readonly dataSource!: Map<string, any>;
  showTypeFilter = false;
  showListUser = false;
  showStatusFilter = false;
  aliasTypeFilter = "type";
  aliasStatusFilter = "status";
  scrollContainer: HTMLElement | null = null;
  loading = false;
  timer: any = null;
  keySearch = "";
  filterShowNow = "";
  typeChoose: any = {};
  statusChoose: any = { value: "", title: "Tất cả" };
  userChoose: any = null;
  filterTypeArray = [
    { value: 1, title: "Chăm sóc khách hàng" },
    { value: 2, title: "Hỗ trợ kinh doanh" },
  ];
  filterStatusArray = [
    { value: "", title: "Tất cả" },
    { value: "important", title: "Quan trọng" },
    { value: "assigned", title: "Đã chỉ định" },
    { value: "unread", title: "Chưa đọc" },
    { value: "notReply", title: "Chưa phản hồi" },
    { value: "success", title: "Hoàn thành" },
    { value: "spam", title: "Spam" },
  ];
  statusDefaultShow = JSON.parse(JSON.stringify(statusDefaultShowInit));
  customQuery?: any;
  isQueryForAllCurator?: any;
  keyFilterToQuery: any = {
    "customFields.status": "status",
    "customFields.user_create_name": "key_search",
    _id: "status",
    "lastMessage.u.username": "status",
    "customFields.userCurator.id": "assignee_id",
  };

  backToOps() {
    window.CHAT_SDK.closeApp();
  }
  get total() {
    return this.$store.state.global.total;
  }

  get offset() {
    return this.$store.state.global.offset;
  }
  get isGotAll() {
    return this.$store.state.global.isGotAll;
  }
  mounted() {
    this.typeChoose = this.filterTypeArray[0];
    this.statusDefaultShow.unshift({ value: "", title: "Tất cả" });
    document.addEventListener("click", this.handleClickOutside);
    this.scrollContainer = document.getElementById("scroll-container");
    if (this.scrollContainer) {
      this.scrollContainer.addEventListener("scroll", this.handleScroll);
    }
    this.mapFilterFromUrl();
  }
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  }
  @Watch("dataSource")
  public async onDataSourceChange() {
    if (!this.scrollContainer) {
      this.scrollContainer = document.getElementById("scroll-container");
    }
  }
  async handleScroll() {
    if (this.scrollContainer) {
      const threshold = 150;
      const { scrollHeight, scrollTop, offsetHeight } = this.scrollContainer;
      if (scrollTop + offsetHeight + threshold < scrollHeight) {
        return;
      }
      if (this.isGotAll && !this.loading) {
        return;
      }
      this.loading = true;
      this.clearTimer();
      this.timer = setTimeout(() => {
        this.loadMoreData();
      }, 300);
    }
  }
  updated() {
    if (!this.scrollContainer) {
      this.scrollContainer = document.getElementById("scroll-container");
    }
  }
  async loadMoreData(isMapUrl = true) {
    this.setFilterKeySearch();
    await new ChatListController().getChatChannelList(
      this.offset ? this.offset : 0,
      this.customQuery
    );
    this.loading = false;
    if (isMapUrl) this.mapUrlWithFilter();
  }
  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
  toggleElement(button: string) {
    this.filterShowNow = button;
    if (button === this.aliasTypeFilter) {
      this.showTypeFilter = !this.showTypeFilter;
      this.showStatusFilter = false;
    } else if (button === this.aliasStatusFilter) {
      this.showStatusFilter = !this.showStatusFilter;
      this.showTypeFilter = false;
    }
  }
  chooseTypeFilter(type: any) {
    this.typeChoose = type;
    console.log("Huyth can do anything here.", type);
  }
  hiddenListUser(show: boolean) {
    this.showListUser = show;
    if (!show) {
      this.statusChoose = { value: "", title: "Tất cả" };
    }
  }
  chooseUserFilter(data: any, isLoadMore = true) {
    let user = null;
    this.statusDefaultShow = JSON.parse(JSON.stringify(statusDefaultShowInit));
    if (data) {
      user = {
        value: "assigned",
        title: data.department_name?.trim()
          ? data.name + " - " + data.department_name
          : data.name,
      };
      this.statusChoose = {};
    } else {
      this.statusChoose = { value: "", title: "Tất cả" };
      this.statusDefaultShow.unshift({ value: "", title: "Tất cả" });
    }
    this.showListUser = false;
    this.userChoose = user;
    if (data && data.id) {
      this.customQuery = {};
      this.customQuery["customFields.userCurator.id"] = data.id;
    } else {
      this.customQuery = null;
      this.isQueryForAllCurator = 0;
    }
    this.$store.commit("global/SET_OFFSET_CHAT_LIST", 0);
    if (isLoadMore) this.loadMoreData();
  }
  async chooseStatusFilter(status: any, isLoadMore = true) {
    this.showListUser = false;
    let defaultValue = this.statusDefaultShow.map(
      (item: { value: any }) => item.value
    );
    if (!defaultValue.includes(status.value) && status.value !== "assigned") {
      this.statusDefaultShow = JSON.parse(
        JSON.stringify(statusDefaultShowInit)
      );
      this.statusDefaultShow.unshift(status);
    }
    if (!Object.keys(this.statusChoose).length) {
      this.userChoose = null;
    }
    if (this.statusChoose?.value === status.value) {
      this.statusChoose = { value: "", title: "Tất cả" };
      this.statusDefaultShow = JSON.parse(
        JSON.stringify(statusDefaultShowInit)
      );
      this.statusDefaultShow.unshift({ value: "", title: "Tất cả" });
    } else {
      this.statusChoose = status;
    }
    let customQuery = { ...this.customQuery };
    if (this.statusChoose.value) {
      if (this.statusChoose.value === "assigned") {
        this.showListUser = true;
        return;
      }
      this.customQuery = {};
      if (this.statusChoose.value === "unread") {
        let subscription = await this.$http.getIdUnread();
        this.customQuery["_id"] = { $in: subscription };
      } else if (this.statusChoose.value === "notReply") {
        this.customQuery["lastMessage.u.username"] = {
          $regex: "^marketplace",
        };
      } else {
        this.customQuery["customFields.status"] = this.statusChoose.value;
      }
    } else {
      this.customQuery = {};
      if (customQuery["customFields.user_create_name"]) {
        this.customQuery["customFields.user_create_name"] =
          customQuery["customFields.user_create_name"];
        delete this.customQuery["customFields.status"];
      }
      this.isQueryForAllCurator = 0;
    }
    this.$store.commit("global/SET_OFFSET_CHAT_LIST", 0);
    if (isLoadMore) this.loadMoreData();
  }

  searchConversation() {
    let customQuery = { ...this.customQuery };
    if (this.keySearch) {
      customQuery["customFields.user_create_name"] = {
        $regex: this.keySearch,
        $options: "i",
      };
    } else {
      delete customQuery["customFields.user_create_name"];
    }
    this.customQuery = { ...customQuery };
    this.$store.commit("global/SET_OFFSET_CHAT_LIST", 0);
    this.loadMoreData();
  }

  handleClickOutside(event: { target: any }) {
    if (!this.scrollContainer) {
      this.scrollContainer = document.getElementById("scroll-container");
    }
    if (this.filterShowNow === this.aliasTypeFilter) {
      this.clickOutsideType(event);
    } else if (this.filterShowNow === this.aliasStatusFilter) {
      this.clickOutsideStatus(event);
    }
  }

  clickOutsideStatus(event: any) {
    const { tgstatusactive, tgstatusshow } = this.$refs;
    if (!tgstatusshow || (tgstatusshow as HTMLElement).contains(event.target)) {
      return;
    }
    if (
      tgstatusactive &&
      !(tgstatusactive as HTMLElement).contains(event.target)
    ) {
      this.showStatusFilter = false;
    }
  }
  clickOutsideType(event: any) {
    const { tgtypeactive, tgtypeshow } = this.$refs;
    if (!tgtypeshow || (tgtypeshow as HTMLElement).contains(event.target)) {
      return;
    }
    if (tgtypeactive && !(tgtypeactive as HTMLElement).contains(event.target)) {
      this.showTypeFilter = false;
    }
  }

  mapUrlWithFilter() {
    if (!this.customQuery) {
      window.history.pushState({}, "", "chat-list");
      return;
    }
    const searchParams = new URLSearchParams();
    Object.keys(this.customQuery).forEach((key) => {
      if (this.customQuery[key] !== "" && this.keyFilterToQuery[key]) {
        if (key == "customFields.user_create_name") {
          searchParams.append(
            this.keyFilterToQuery[key],
            this.customQuery[key]["$regex"]
          );
        } else if (key == "_id") {
          searchParams.append(this.keyFilterToQuery[key], "unread");
        } else if (key == "lastMessage.u.username") {
          searchParams.append(this.keyFilterToQuery[key], "notReply");
        } else {
          searchParams.append(
            this.keyFilterToQuery[key],
            this.customQuery[key]
          );
        }
      }
    });
    let url = window.location.toString();
    const query =
      (searchParams as any) && (searchParams as any).size > 0
        ? "?" + searchParams
        : "";
    url = "chat-list" + query;
    window.history.pushState({}, "", url);
  }

  async mapFilterFromUrl() {
    const queryParams = new URLSearchParams(window.location.search);
    for (const [key, value] of queryParams.entries()) {
      if (key == "status") {
        const item = this.filterStatusArray.find((v) => v.value == value);
        if (item) this.chooseStatusFilter(item, false);
      }
      if (key == "key_search") {
        this.keySearch = value;
      }
      if (key == "assignee_id") {
        const listUser = await this.listAssignee({ is_get_all: true });
        const user = listUser.data.find((v: any) => v.id == value);
        if (user) this.chooseUserFilter(user, false);
      }
    }
    setTimeout(() => {
      this.loadMoreData(false);
    }, 100);
  }

  getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  setFilterKeySearch() {
    if (!this.customQuery) this.customQuery = {};
    if (this.keySearch && this.keySearch.trim()) {
      this.customQuery["customFields.user_create_name"] = {
        $regex: this.keySearch,
        $options: "i",
      };
    }
  }
  get conversationDetail() {
    return this.$store.state.global.conversationDetail;
  }
  public async listAssignee(params: any) {
    const data = await new ChatDetailController(
      this.conversationDetail?.roomId
    ).listAssignee(params);
    return data.data;
  }
}
</script>
