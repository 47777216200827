<template>
  <page
    class="kvc-page-chat-detail"
    v-if="room"
    :id="roomId"
    :class="{ 'chat-page--empty': !messages.size }"
  >
    <template #header>
      <div class="chat-page__header-title" @click="openSupplierDetail">
        <img :src="room.avatar" alt="" />
        <span>{{ room.name }}</span>
      </div>
    </template>

    <template #header-bottom>
      <warning-section v-if="supplierInfo && supplierInfo.warning_title">
        <template #title>{{ supplierInfo.warning_title }}</template>
        <template #description>{{ supplierInfo.warning_content }}</template>
      </warning-section>
    </template>

    <template #body>
      <template v-if="messages.size">
        <chat-detail-by-date
          v-for="date in messages.keys()"
          :key="date"
          :date="date"
          :data-source="messages.get(date)"
        />
      </template>

      <template v-else-if="!room.isCustomerService">
        <empty-detail />
      </template>

      <template v-if="room.isCustomerService">
        <empty-customer-service />
      </template>
    </template>

    <template #footer>
      <div class="chat-attachment" v-if="attachment">
        <product-attachment
          v-if="attachment.type === 'product'"
          :data="attachment.payload"
        />
        <order-attachment
          v-if="attachment.type === 'order'"
          :data="attachment.payload"
        />
      </div>

      <div v-else id="chat-detail-lure"></div>

      <div class="kvc-page-chat-detail__footer">
        <label class="chat-detail__image" for="image-input">
          <image-icon />
          <input
            id="image-input"
            type="file"
            accept="image/*"
            @change="handleUploadImage"
          />
        </label>

        <textarea
          autofocus
          class="chat-detail__input"
          placeholder="Nhập tin nhắn"
          @focus="toggleFocus"
          @focusout="toggleFocus"
          v-model="message"
          @input="onChangeMessage"
          ref="input"
        />

        <div
          class="chat-detail__send"
          v-if="isFocus || message"
          @click="handleSendMessage"
        >
          <send :is-active="!!message?.trim()" />
        </div>
      </div>
    </template>
  </page>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import Page from "@/scopes/marketplace/device/mobile/components/layouts/Page.vue";
import ChatDetailItem from "@/scopes/marketplace/components/detail/ChatDetailItem.vue";
import Send from "@/assets/images/send.vue";
import ChatDetailByDate from "@/scopes/marketplace/components/detail/ChatDetailByDate.vue";
import EmptyDetail from "@/scopes/marketplace/components/detail/EmptyDetail.vue";
import ProductAttachment from "@/scopes/marketplace/components/detail/attachments/ProductAttachment.vue";
import MarketplaceChatDetailMixin from "@/mixins/marketplace/MarketplaceChatDetail.mixin";
import OrderAttachment from "@/scopes/marketplace/components/detail/attachments/OrderAttachment.vue";
import EmptyCustomerService from "@/scopes/marketplace/components/detail/EmptyCustomerService.vue";
import ImageIcon from "@/assets/images/marketplace/image.vue";
import WarningSection from "@/scopes/marketplace/components/WarningSection.vue";
@Component({
  components: {
    WarningSection,
    ImageIcon,
    EmptyCustomerService,
    ProductAttachment,
    EmptyDetail,
    ChatDetailByDate,
    ChatDetailItem,
    Page,
    Send,
    OrderAttachment,
  },
})
export default class ChatDetail extends Mixins(MarketplaceChatDetailMixin) {
  wrapper!: HTMLElement;

  @Watch("messages", { deep: true, immediate: true })
  onMessagesChange(val: Map<any, any>) {
    if (val && !(this.activePage.query && this.activePage.query.direct)) {
      this.$nextTick(() => {
        this.wrapper = document.getElementById(this.roomId) as HTMLElement;
        this.wrapper.addEventListener("scroll", this.handleScroll);
      });
    }
  }

  @Watch("user", { deep: true, immediate: true })
  onUserChange(val: any) {
    if (val && this.activePage.query && this.activePage.query.direct) {
      this.$nextTick(() => {
        this.wrapper = document.getElementById(this.roomId) as HTMLElement;
        this.wrapper.addEventListener("scroll", this.handleScroll);
      });
    }
  }

  @Watch("room", { deep: true, immediate: true })
  onChangeRoomId(val: any) {
    console.log("room", val);

    if (val && val.supplier_refer_id) {
      window.dispatchEvent(
        new CustomEvent("dispatch", {
          detail: {
            name: "openChatDetail",
            body: {
              supplier_refer_id: val.supplier_refer_id,
            },
          },
        })
      );
    }
  }

  public async created(): Promise<void> {
    this.getAttachment();
  }
  mounted() {
    if (document.getElementById("chat-detail-lure")) {
      window.dispatchEvent(new Event("initChatDetailLure"));
    }
  }

  public beforeDestroy() {
    this.wrapper.removeEventListener("scroll", this.handleScroll);
  }

  public getAttachment(): void {
    const { attachment } = this.activePage.params;

    if (attachment) {
      this.attachment = JSON.parse(attachment);
    }
  }

  public async handleScroll() {
    const { scrollTop, clientHeight } = this.wrapper;

    if (scrollTop <= clientHeight && !this.isLoadingMore) {
      await this.loadMore();
    }
  }
}
</script>
