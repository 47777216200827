export default {
  SET_CURRENT_ROOM({ commit }: any, payload: any) {
    commit("UPDATE_CURRENT_ROOM_ID", payload.id);
    commit("UPDATE_CURRENT_ROOM_TYPE", payload.type);
    commit("UPDATE_CURRENT_ROOM_AREA", payload.area || null);
  },

  SET_CURRENT_ROOM_ID({ commit }: any, payload: string) {
    commit("UPDATE_CURRENT_ROOM_ID", payload);
  },

  SET_CURRENT_ROOM_TYPE({ commit }: any, payload: string) {
    commit("UPDATE_CURRENT_ROOM_TYPE", payload);
  },

  SET_IS_SHOW_CHAT({ commit }: any, payload: boolean) {
    commit("UPDATE_IS_SHOW_CHAT", payload);
  },
};
