export default {
  UPDATE_CURRENT_ROOM_ID(state: any, payload: string) {
    state.currentRoomId = payload;
  },

  UPDATE_CURRENT_ROOM_TYPE(state: any, payload: string) {
    state.currentRoomType = payload;
  },

  UPDATE_CURRENT_ROOM_AREA(state: any, payload: string | null) {
    state.currentRoomArea = payload;
  },

  UPDATE_IS_SHOW_CHAT(state: any, payload: boolean) {
    state.isShowChat = payload;
  },
};
