<template>
  <div id="marketplace-chat">
    <component :is="ChatComponent" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ROUTER from "@/scopes/marketplace/device/mobile/constants/router";
import Chat from "@/scopes/marketplace/device/desktop/Chat.vue";
import GlobalMixin from "@/mixins/Global.mixin";
import PAGE_NAME from "@/constants/pages-name";

@Component
export default class App extends Mixins(GlobalMixin) {
  get device() {
    return this.$store.state.global.device;
  }

  get ChatComponent() {
    if (this.device === "desktop") {
      return Chat;
    } else {
      if (this.activePage) {
        return ROUTER[this.activePage.name].component;
      }

      return ROUTER[PAGE_NAME.CHAT_LIST].component;
    }
  }
}
</script>
