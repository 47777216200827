<template>
  <div class="chat-detail__block">
    <div class="chat-detail__block-date">{{ date }}</div>

    <chat-detail-item
      v-for="message in dataSource"
      :key="message._id"
      :message="message"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ChatDetailItem from "@/scopes/marketplace/components/detail/ChatDetailItem.vue";

@Component({
  components: { ChatDetailItem },
})
export default class ChatDetailByDate extends Vue {
  @Prop({ required: true }) private readonly date!: string;
  @Prop({ required: true }) private readonly dataSource!: any[];

  created() {
    console.log(this.dataSource);
  }
}
</script>
