import PAGE_NAME from "@/constants/pages-name";

export interface GlobalState {
  scope?: string;
  device?: string;
  users: Map<string, any>;
  total: number;
  offset: number;
  count: number | null;
  isGotAll: boolean;
  pageStack: any[];
  details: any;
  typeComponent?: string;
  conversationDetail: any;
}

export default (): GlobalState => ({
  scope: undefined,
  users: new Map(),
  total: 0,
  offset: 0,
  count: null,
  isGotAll: false,
  pageStack: [{ name: PAGE_NAME.CHAT_LIST }],
  details: new Map(),
  typeComponent: PAGE_NAME.CHAT_LIST,
  conversationDetail: null,
});
