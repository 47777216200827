<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 6.875V5.3125C7.5 4.8981 7.66462 4.50067 7.95765 4.20765C8.25067 3.91462 8.6481 3.75 9.0625 3.75H16.5625C16.9769 3.75 17.3743 3.91462 17.6674 4.20765C17.9604 4.50067 18.125 4.8981 18.125 5.3125V14.6875C18.125 15.1019 17.9604 15.4993 17.6674 15.7924C17.3743 16.0854 16.9769 16.25 16.5625 16.25H9.0625C8.6481 16.25 8.25067 16.0854 7.95765 15.7924C7.66462 15.4993 7.5 15.1019 7.5 14.6875V13.125M5 6.875L1.875 10L5 13.125M12.5391 10H1.875"
      stroke="#FAFBFC"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IconExit extends Vue {}
</script>
