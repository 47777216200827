import state from "@/store/shared/account/state";
import mutations from "@/store/shared/account/mutations";

const account = {
  namespaced: true,
  state: state,
  mutations: mutations,
};

export default account;
