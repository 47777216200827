<template>
  <div
    class="chat-detail-item"
    :class="{ 'chat-detail-item--receive': message.receive }"
  >
    <component :is="MessageComponent" :message="message" />
  </div>
</template>

<script>
import { Component, Mixins } from "vue-property-decorator";
import ChatDetailItemMixin from "@/mixins/ChatDetailItem.mixin";

@Component
export default class ChatDetailItem extends Mixins(ChatDetailItemMixin) {}
</script>
