<template>
  <div class="operator-chat-list-center">
    <div class="operator-chat-list-center-header">
      <div class="user-info">
        <div class="user-info__avatar">
          <div class="first-character"></div>
          <span class="text">{{ firstCharacter.toUpperCase() }}</span>
        </div>
        <div class="user-info__group">
          <div class="name">{{ userByConversationDetail?.name }}</div>
          <div>
            <FilterAssignUser
              :assignee="userByConversationDetail?.assignee"
              :conversationDetail="conversationDetail"
              :roomId="roomId"
            />
          </div>
        </div>
      </div>
      <div class="action-group">
        <div class="action-group__item">
          <a
            @click.prevent="addCustomFieldForConversation('spam')"
            class="tooltip-custom"
          >
            <IconWarningActive
              v-if="conversationDetail?.statusRoom == 'spam'"
            />
            <IconWarning v-else />
            <span
              class="tooltip-custom-content"
              :style="
                conversationDetail?.statusRoom == 'spam'
                  ? 'margin-left: -79px'
                  : 'margin-left: -71px'
              "
              >{{
                conversationDetail?.statusRoom == "spam"
                  ? "Bỏ đánh dấu là Spam"
                  : "Đánh dấu là Spam"
              }}</span
            >
          </a>
        </div>
        <div class="action-group__item">
          <a
            @click.prevent="addCustomFieldForConversation('important')"
            class="tooltip-custom"
          >
            <IconStarActive
              v-if="conversationDetail?.statusRoom == 'important'"
            />
            <IconStar v-else />
            <span
              class="tooltip-custom-content"
              :style="
                conversationDetail?.statusRoom == 'important'
                  ? 'margin-left: -92px'
                  : 'margin-left: -84px'
              "
              >{{
                conversationDetail?.statusRoom == "important"
                  ? "Bỏ đánh dấu là quan trọng"
                  : "Đánh dấu là quan trọng"
              }}</span
            >
          </a>
        </div>
        <div class="action-group__item">
          <a
            @click.prevent="addCustomFieldForConversation('success')"
            class="tooltip-custom"
          >
            <IconCheckCircleActive
              v-if="conversationDetail?.statusRoom == 'success'"
            />
            <IconCheckCircle v-else />
            <span
              class="tooltip-custom-content"
              :style="
                conversationDetail?.statusRoom == 'success'
                  ? 'margin-left: -90px'
                  : 'margin-left: -86px'
              "
            >
              {{
                conversationDetail?.statusRoom == "success"
                  ? "Bỏ đánh dấu là hoàn thành"
                  : "Đánh dấu là hoàn thành"
              }}
            </span>
          </a>
        </div>
      </div>
    </div>
    <div ref="chatBox" class="operator-chat-list-center-body">
      <chat-detail-by-date
        v-for="date in messages.keys()"
        :key="date"
        :date="date"
        :data-source="messages.get(date)"
      />
      <div
        class="init-chat"
        v-if="userByConversationDetail && scrollToFirstMessage"
      >
        <div class="init-chat__avatar">
          <span>{{ firstCharacter.toUpperCase() }}</span>
        </div>
        <div class="init-chat__name">{{ userByConversationDetail.name }}</div>
        <div class="init-chat__greeting">
          Bắt đầu cuộc trò chuyện với {{ userByConversationDetail.name }}
        </div>
      </div>
    </div>
    <div
      v-if="userByConversationDetail"
      class="operator-chat-list-center-footer"
      ref="footer"
    >
      <div
        ref="preview_image"
        v-if="base64ImageUpload"
        class="operator-chat-list-center-footer__preview-image"
      >
        <img :src="base64ImageUpload" alt="Preview" />
        <div class="close-image" @click.prevent="closeAttachImage">
          <IconClose />
        </div>
      </div>
      <textarea
        v-else
        ref="message_input"
        class="operator-chat-list-center-footer__input"
        placeholder="Nhập nội dung tin nhắn ..."
        @focus="toggleFocus"
        @focusout="toggleFocusOut"
        v-model="message"
        @input="onChangeMessage"
        @keyup.enter="handleEnterKey($event)"
        @keydown.enter.prevent
      />
      <div class="operator-chat-list-center-footer__send">
        <div v-if="showAttachFile">
          <input
            type="file"
            id="file"
            ref="fileUpload"
            style="display: none"
            accept=".png, .jpg, .jpeg"
            @change.prevent="onChangeFileUpload"
          />
        </div>
        <div @click.prevent="handleClickOutside" ref="filter_sample_message">
          <FilterSampleMessage
            @dataMessage="triggerChooseSampleMessage"
            v-if="showBlockSampleMessage"
          />
          <div
            @click.prevent="showBlockSampleMessage = !showBlockSampleMessage"
          >
            <IconChatDot />
          </div>
        </div>
        <div @click.stop="attachFile">
          <IconAttachFile />
        </div>
        <div @click.stop="sendTextMessage">
          <send :is-active="!!message?.trim() || !!base64ImageUpload?.trim()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import ChatDetailMixin from "@/mixins/operator/ChatDetail.mixin";
import ChatDetailByDate from "@/scopes/operator/components/detail/ChatDetailByDate.vue";
import Send from "@/assets/images/operator/iconSend.vue";
import IconChatDot from "@/assets/images/operator/iconChatDot.vue";
import IconCheckCircle from "@/assets/images/operator/iconCheckCircle.vue";
import IconStar from "@/assets/images/operator/iconStar.vue";
import IconWarning from "@/assets/images/operator/iconWarning.vue";
import IconAttachFile from "@/assets/images/operator/iconAttachFile.vue";
import IconWarningActive from "@/assets/images/operator/iconWarningActive.vue";
import IconStarActive from "@/assets/images/operator/iconStarActive.vue";
import IconCheckCircleActive from "@/assets/images/operator/iconCheckCircleActive.vue";
import FilterAssignUser from "@/scopes/operator/components/filter/FilterAssignUser.vue";
import FilterSampleMessage from "@/scopes/operator/components/filter/FilterSampleMessage.vue";
import IconClose from "@/assets/images/operator/iconClose.vue";
import { setStateRoom } from "@/helpers/localstorage.helper";

@Component({
  components: {
    FilterSampleMessage,
    ChatDetailByDate,
    Send,
    IconChatDot,
    IconCheckCircle,
    IconStar,
    IconWarning,
    IconAttachFile,
    IconWarningActive,
    IconStarActive,
    IconCheckCircleActive,
    FilterAssignUser,
    IconClose,
  },
})
export default class ChatList extends Mixins(ChatDetailMixin) {
  triggerChooseSampleMessage(message: any) {
    this.message = message.content ?? null;
    this.showBlockSampleMessage = false;
    (this.$refs.message_input as HTMLElement).focus();
    this.setHeightChatBox(null);
  }
  mounted() {
    setTimeout(() => {
      if (this.$refs.chatBox) {
        (this.$refs.chatBox as HTMLElement).scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 100);
    document.addEventListener("click", this.handleClickOutside);
    this.wrapper = document.querySelector(
      ".operator-chat-list-center-body"
    ) as HTMLElement;

    this.wrapper.addEventListener("scroll", this.handleScrollChat);
    if (this?.$refs?.message_input) {
      (this.$refs.message_input as HTMLElement).focus();
    }
  }
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    this.wrapper.removeEventListener("scroll", this.handleScrollChat);
  }
  handleClickOutside(event: { target: any }) {
    if (
      this.$refs.footer &&
      !(this.$refs.footer as any).contains(event.target)
    ) {
      setStateRoom({
        roomId: this.roomId,
        state: this.base64ImageUpload || this.message ? "draft" : "",
        message: this.message,
      });
    }
  }
  handleEnterKey(event: KeyboardEvent) {
    if (event.shiftKey) {
      this.message += "\n";
      this.$nextTick(() => {
        const textarea = this.$refs.message_input as HTMLElement;
        textarea.scrollTop = textarea.scrollHeight;
      });
    } else {
      this.sendTextMessage();
    }
  }
}
</script>
