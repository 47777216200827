<template>
  <svg
    width="191"
    height="156"
    viewBox="0 0 191 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M94.5521 149.762C135.825 149.762 169.286 116.302 169.286 74.8812C169.286 33.4607 135.825 0 94.5521 0C53.279 0 19.8184 33.4607 19.8184 74.8812C19.8184 116.302 53.279 149.762 94.5521 149.762Z"
      fill="#EAEEF9"
    />
    <path
      d="M177.835 44.0737C181.173 44.0737 183.879 41.3679 183.879 38.0301C183.879 34.6924 181.173 31.9866 177.835 31.9866C174.497 31.9866 171.792 34.6924 171.792 38.0301C171.792 41.3679 174.497 44.0737 177.835 44.0737Z"
      fill="#EAEEF9"
    />
    <path
      d="M186.681 20.342C188.96 20.342 190.808 18.4942 190.808 16.2147C190.808 13.9353 188.96 12.0874 186.681 12.0874C184.401 12.0874 182.553 13.9353 182.553 16.2147C182.553 18.4942 184.401 20.342 186.681 20.342Z"
      fill="#EAEEF9"
    />
    <path
      d="M14.66 48.3486C16.9395 48.3486 18.7873 46.5008 18.7873 44.2213C18.7873 41.9419 16.9395 40.094 14.66 40.094C12.3806 40.094 10.5327 41.9419 10.5327 44.2213C10.5327 46.5008 12.3806 48.3486 14.66 48.3486Z"
      fill="#EAEEF9"
    />
    <path
      d="M22.3242 138.118C26.5574 138.118 29.9892 134.686 29.9892 130.453C29.9892 126.22 26.5574 122.788 22.3242 122.788C18.0909 122.788 14.6592 126.22 14.6592 130.453C14.6592 134.686 18.0909 138.118 22.3242 138.118Z"
      fill="#EAEEF9"
    />
    <g filter="url(#filter0_d_28025_253694)">
      <path
        d="M163.832 44.9584H36.0327C33.2321 44.9584 31.021 42.7473 31.021 39.9467V11.2029C31.021 8.40222 33.2321 6.19116 36.0327 6.19116H163.685C166.485 6.19116 168.696 8.40222 168.696 11.2029V39.9467C168.844 42.7473 166.485 44.9584 163.832 44.9584Z"
        fill="white"
      />
    </g>
    <path
      d="M61.9748 25.5013C61.9748 28.7442 60.5008 31.6923 58.2897 33.756C56.3735 35.5248 53.7202 36.5566 50.9195 36.5566C48.1189 36.5566 45.4656 35.5248 43.5494 33.756C41.1909 31.6923 39.8643 28.7442 39.8643 25.5013C39.8643 19.4578 44.876 14.446 50.9195 14.446C56.9631 14.446 61.9748 19.4578 61.9748 25.5013Z"
      fill="#DCE1EE"
    />
    <path
      d="M58.2882 33.7558C56.372 35.5246 53.7187 36.5564 50.918 36.5564C48.1174 36.5564 45.4641 35.5246 43.5479 33.7558C44.4323 33.461 45.4641 33.0188 46.7907 32.4291C47.0855 32.2817 47.3804 31.9869 47.5278 31.6921C47.6752 31.5447 47.6752 31.2499 47.6752 31.1025V28.3018L47.5278 28.1544C47.2329 27.8596 47.0855 27.4174 47.0855 26.9752L46.7907 26.8278C46.0537 26.9752 46.2011 26.2382 45.9063 24.7641C45.9063 24.1745 45.9063 24.0271 46.2011 23.8797L46.4959 23.5849C45.1693 20.4894 45.7589 18.5732 47.2329 17.8362C46.7907 16.8043 46.7907 16.5095 46.7907 16.5095C46.7907 16.5095 49.7388 16.9517 50.6232 16.8043C51.9499 16.5095 53.8661 16.8043 54.6031 18.5732C55.7824 19.0154 56.2246 19.7524 56.372 20.6368C56.5194 21.9635 55.7824 23.4375 55.635 23.8797C55.7824 24.0271 55.9298 24.1745 55.7824 24.6167C55.635 25.9434 55.635 26.8278 54.8979 26.6804L54.3083 28.007C54.3083 28.1544 54.3083 28.1544 54.3083 28.3018C54.3083 28.4492 54.3083 28.8914 54.3083 31.2499C54.3083 31.5447 54.4557 31.8395 54.4557 31.9869C54.6031 32.2817 54.8979 32.4291 55.0454 32.5765C56.372 33.0188 57.4038 33.461 58.2882 33.7558Z"
      fill="white"
    />
    <path
      d="M55.6734 24.0269C55.6734 23.7201 55.52 23.2598 55.3665 22.9529V22.7995C54.7529 21.5722 53.5255 21.1119 52.2981 21.1119C49.2297 20.9585 48.9229 21.5722 47.8489 20.6516C48.1558 21.1119 48.1558 21.879 47.6955 22.7995C47.3887 23.4132 46.775 23.7201 46.1613 23.8735C44.7805 20.6516 45.3942 18.6572 46.9284 17.8901C46.4681 16.8161 46.4681 16.5093 46.4681 16.5093C46.4681 16.5093 49.5366 16.9695 50.4571 16.8161C51.8379 16.5093 53.8323 16.8161 54.5994 18.6572C55.8268 19.1174 56.2871 19.8845 56.4405 20.8051C56.5939 21.879 55.8268 23.4132 55.6734 24.0269Z"
      fill="#A2ABBC"
    />
    <path
      d="M54.4558 31.9868V32.5764H47.5278V31.8394C47.6752 31.692 47.6752 31.3972 47.6752 31.2498V28.4491L47.5278 28.3017V28.1543C47.6752 28.4491 47.8226 28.5965 48.1174 28.8913L50.3285 30.3654C50.7707 30.8076 51.5077 30.8076 52.0974 30.3654L54.161 28.5965L54.3084 28.4491C54.3084 28.5965 54.3084 29.0387 54.3084 31.3972C54.161 31.5446 54.3084 31.692 54.4558 31.9868Z"
      fill="url(#paint0_linear_28025_253694)"
    />
    <path
      d="M98.2371 22.2579H69.9356C69.1986 22.2579 68.7563 21.6683 68.7563 21.0787V17.3936C68.7563 16.6566 69.346 16.2144 69.9356 16.2144H98.2371C98.9741 16.2144 99.4164 16.804 99.4164 17.3936V21.0787C99.4164 21.8157 98.9741 22.2579 98.2371 22.2579Z"
      fill="#CED6E2"
    />
    <path
      d="M131.845 34.7872H69.9356C69.1986 34.7872 68.7563 34.1976 68.7563 33.608V29.9229C68.7563 29.1859 69.346 28.7437 69.9356 28.7437H131.845C132.582 28.7437 133.024 29.3333 133.024 29.9229V33.608C133.024 34.345 132.435 34.7872 131.845 34.7872Z"
      fill="#CED6E2"
    />
    <g filter="url(#filter1_d_28025_253694)">
      <path
        d="M163.832 141.655H36.0327C33.2321 141.655 31.021 139.444 31.021 136.643V107.9C31.021 105.099 33.2321 102.888 36.0327 102.888H163.685C166.485 102.888 168.696 105.099 168.696 107.9V136.643C168.844 139.444 166.485 141.655 163.832 141.655Z"
        fill="white"
      />
    </g>
    <path
      d="M61.9748 122.198C61.9748 125.441 60.5008 128.389 58.2897 130.453C56.3735 132.222 53.7202 133.253 50.9195 133.253C48.1189 133.253 45.4656 132.222 43.5494 130.453C41.1909 128.389 39.8643 125.441 39.8643 122.198C39.8643 116.155 44.876 111.143 50.9195 111.143C56.9631 111.143 61.9748 116.155 61.9748 122.198Z"
      fill="#DCE1EE"
    />
    <path
      d="M58.2882 130.453C56.372 132.222 53.7187 133.253 50.918 133.253C48.1174 133.253 45.4641 132.222 43.5479 130.453C44.4323 130.158 45.4641 129.716 46.7907 129.126C47.0855 128.979 47.3804 128.684 47.5278 128.389C47.6752 128.242 47.6752 127.947 47.6752 127.8V124.999L47.5278 124.851C47.2329 124.557 47.0855 124.114 47.0855 123.672L46.7907 123.525C46.0537 123.672 46.2011 122.935 45.9063 121.461C45.9063 120.872 45.9063 120.724 46.2011 120.577L46.4959 120.282C45.1693 117.186 45.7589 115.27 47.2329 114.533C46.7907 113.501 46.7907 113.207 46.7907 113.207C46.7907 113.207 49.7388 113.649 50.6232 113.501C51.9499 113.207 53.8661 113.501 54.6031 115.27C55.7824 115.712 56.2246 116.449 56.372 117.334C56.5194 118.66 55.7824 120.135 55.635 120.577C55.7824 120.724 55.9298 120.872 55.7824 121.314C55.635 122.64 55.635 123.525 54.8979 123.377L54.3083 124.557C54.3083 124.704 54.3083 124.704 54.3083 124.851C54.3083 124.999 54.3083 125.441 54.3083 127.8C54.3083 128.094 54.4557 128.389 54.4557 128.537C54.6031 128.831 54.8979 128.979 55.0454 129.126C56.372 129.863 57.4038 130.158 58.2882 130.453Z"
      fill="white"
    />
    <path
      d="M55.6734 120.724C55.6734 120.417 55.52 119.957 55.3665 119.65V119.497C54.7529 118.269 53.5255 117.809 52.2981 117.809C49.2297 117.656 48.9229 118.269 47.8489 117.349C48.1558 117.809 48.1558 118.576 47.6955 119.497C47.3887 120.11 46.775 120.417 46.1613 120.571C44.7805 117.349 45.3942 115.354 46.9284 114.587C46.4681 113.513 46.4681 113.207 46.4681 113.207C46.4681 113.207 49.5366 113.667 50.4571 113.513C51.8379 113.207 53.8323 113.513 54.5994 115.354C55.8268 115.815 56.2871 116.582 56.4405 117.502C56.5939 118.73 55.8268 120.11 55.6734 120.724Z"
      fill="#A2ABBC"
    />
    <path
      d="M54.4558 128.684V129.274H47.5278V128.537C47.6752 128.389 47.6752 128.094 47.6752 127.947V125.146L47.5278 124.999V124.852C47.6752 125.146 47.8226 125.294 48.1174 125.589L50.3285 127.063C50.7707 127.505 51.5077 127.505 52.0974 127.063L54.161 125.294L54.3084 125.146C54.3084 125.294 54.3084 125.736 54.3084 128.094C54.161 128.242 54.3084 128.537 54.4558 128.684Z"
      fill="url(#paint1_linear_28025_253694)"
    />
    <path
      d="M98.2371 119.102H69.9356C69.1986 119.102 68.7563 118.512 68.7563 117.923V114.238C68.7563 113.501 69.346 113.058 69.9356 113.058H98.2371C98.9741 113.058 99.4164 113.648 99.4164 114.238V117.923C99.4164 118.512 98.9741 119.102 98.2371 119.102Z"
      fill="#CED6E2"
    />
    <path
      d="M131.845 131.631H69.9356C69.1986 131.631 68.7563 131.042 68.7563 130.452V126.767C68.7563 126.03 69.346 125.588 69.9356 125.588H131.845C132.582 125.588 133.024 126.177 133.024 126.767V130.452C133.024 131.042 132.435 131.631 131.845 131.631Z"
      fill="#CED6E2"
    />
    <g filter="url(#filter2_d_28025_253694)">
      <path
        d="M142.164 93.3068H14.5117C11.7111 93.3068 9.5 91.0957 9.5 88.295V59.5513C9.5 56.7506 11.7111 54.5396 14.5117 54.5396H142.164C144.964 54.5396 147.175 56.7506 147.175 59.5513V88.295C147.323 91.0957 144.964 93.3068 142.164 93.3068Z"
        fill="white"
      />
    </g>
    <path
      d="M76.7151 70.6068H48.4136C47.6766 70.6068 47.2344 70.0172 47.2344 69.4276V65.7425C47.2344 65.0054 47.824 64.5632 48.4136 64.5632H76.7151C77.4522 64.5632 77.8944 65.1528 77.8944 65.7425V69.4276C77.8944 70.1646 77.4522 70.6068 76.7151 70.6068Z"
      fill="#CED6E2"
    />
    <path
      d="M110.323 83.2835H48.4136C47.6766 83.2835 47.2344 82.6939 47.2344 82.1043V78.4192C47.2344 77.6822 47.824 77.24 48.4136 77.24H110.176C110.913 77.24 111.355 77.8296 111.355 78.4192V82.1043C111.502 82.6939 110.913 83.2835 110.323 83.2835Z"
      fill="#CED6E2"
    />
    <path
      d="M40.6027 73.8495C40.6027 76.945 39.2761 79.7456 37.2124 81.8093C37.065 81.9567 37.065 81.9567 36.9176 82.1041C35.0014 83.8729 32.3481 84.9048 29.5474 84.9048C26.7467 84.9048 24.2409 83.8729 22.3246 82.2515C22.3246 82.2515 22.3246 82.2515 22.1772 82.2515C19.8188 80.1878 18.3447 77.2398 18.3447 73.8495C18.3447 67.8059 23.3565 62.7942 29.4 62.7942C35.591 62.7942 40.6027 67.8059 40.6027 73.8495Z"
      fill="#DCE1EE"
    />
    <path
      d="M37.169 82.0342C36.7205 80.9878 35.9731 80.0909 34.7773 79.493C34.1793 79.194 33.7309 79.0445 32.9835 79.0445H32.535C31.4887 79.0445 31.3392 78.8951 31.3392 78.8951V77.1013C32.535 76.2044 33.4319 75.0085 33.7309 73.6632C34.4783 73.5137 34.9267 72.9158 34.7773 72.1684C34.7773 71.8694 34.4783 71.5704 34.4783 71.2715C34.4783 71.122 34.4783 70.9725 34.4783 70.823C34.4783 70.6736 34.4783 70.6736 34.4783 70.5241C34.4783 70.3746 34.4783 70.3746 34.4783 70.2251C34.4783 69.6272 34.3288 69.1787 33.8804 68.5808C32.9835 66.9365 31.1897 65.8901 29.2464 65.8901C28.798 65.8901 28.499 65.8901 28.0506 66.0396C27.3032 66.1891 26.7052 66.4881 26.1073 66.9365C25.9578 67.086 25.9578 67.086 25.8083 67.2355C25.8083 67.2355 25.8083 67.2355 25.6589 67.385C25.0609 67.9829 24.6125 68.7303 24.3135 69.4777C24.0146 70.2251 24.0146 71.122 24.0146 72.0189C24.0146 72.0189 24.0146 72.0189 24.0146 72.1684C24.0146 72.3179 24.0146 72.3179 24.0146 72.4673C24.0146 72.4673 24.0146 72.6168 23.8651 72.6168C24.164 72.3179 24.0146 72.7663 24.164 73.0653C24.463 73.8127 24.9114 73.6632 25.5094 74.1116C25.5094 74.1116 25.5094 74.1116 25.3599 74.1116L24.762 74.2611C21.4734 75.3075 21.1744 78.7456 24.463 79.493C24.164 79.6425 24.0146 79.792 23.8651 79.792C22.9682 80.5394 22.3703 81.4363 22.0713 82.3332C24.0146 83.9775 26.5557 85.0238 29.3959 85.0238C32.2361 85.0238 34.9267 83.9775 36.87 82.1837C37.0195 82.3332 37.169 82.1837 37.169 82.0342C37.3184 82.0342 37.3184 82.0342 37.169 82.0342ZM27.9011 78.4466C27.7516 78.2971 27.6021 78.1477 27.6021 78.1477C27.4526 77.9982 27.3032 77.9982 27.1537 77.8487C27.1537 77.8487 27.0042 77.8487 27.0042 77.6992C26.5557 77.4002 26.1073 77.1013 25.9578 76.6528C25.9578 76.5034 26.1073 76.2044 26.4063 75.9054C26.5557 76.0549 26.7052 76.2044 26.8547 76.5034C27.0042 76.6528 27.1537 76.8023 27.3032 76.9518C27.4526 76.9518 27.6021 77.1013 27.6021 77.2508C27.6021 77.2508 27.6021 77.2508 27.7516 77.2508C27.7516 77.2508 27.7516 77.2508 27.9011 77.2508V78.4466Z"
      fill="white"
    />
    <path
      d="M32.7164 78.9595C32.569 79.5491 32.2742 79.9913 32.2742 79.9913L26.6729 79.1069L26.9677 77.6328C26.9677 77.6328 27.1151 77.6328 27.1151 77.7802C27.2625 77.9276 27.4099 77.9276 27.5573 78.075C27.7047 78.075 27.7047 78.2224 27.8521 78.3698V77.338C27.8521 77.338 27.8521 77.338 27.7047 77.338C28.5891 77.7802 29.7683 78.075 31.3898 77.0432V78.8121C31.5372 78.8121 31.6846 78.9595 32.7164 78.9595Z"
      fill="url(#paint2_linear_28025_253694)"
    />
    <path
      d="M31.5513 70.7371C32.6029 70.7371 33.6545 70.5869 34.5559 70.2864C34.5559 70.2864 34.5559 70.1362 34.7061 69.986C34.5559 69.5353 34.4056 68.9344 34.1052 68.3335C33.2038 66.681 31.4011 65.6294 29.4482 65.6294C28.9975 65.6294 28.5652 65.6615 27.9624 65.8118C27.1602 66.0569 26.7878 66.2724 26.5978 66.3876C26.5978 66.3876 26.1587 66.6828 25.8427 66.9814C25.5268 67.28 24.7911 68.3335 24.4907 69.0846C24.1902 69.986 23.9478 70.7521 24.098 71.6535C24.2483 71.6535 24.4907 71.4882 24.6409 71.4882C25.8427 70.8873 26.7441 70.1362 27.1948 68.9344C28.3966 69.986 29.8988 70.5869 31.5513 70.7371Z"
      fill="#A2ABBC"
    />
    <path
      d="M26.4512 76.0605C26.5986 76.208 26.746 76.3554 26.8934 76.5028C26.746 76.3554 26.5986 76.208 26.4512 76.0605Z"
      fill="#3973F4"
    />
    <path
      d="M28.5156 81.5145C28.0734 82.1041 27.3364 82.1041 26.5993 82.1041C27.3364 81.3671 26.8941 79.156 24.5357 79.4508C21.1454 78.8612 21.5876 75.3235 24.8305 74.2917L25.4201 74.1443C25.4201 74.1443 25.4201 74.1443 25.5675 74.2917C25.8623 75.0287 26.1571 75.4709 26.5993 76.0605C25.2727 77.2398 27.189 77.5346 28.0734 78.419C28.663 78.7138 29.2526 80.6301 28.5156 81.5145Z"
      fill="#A2ABBC"
    />
    <defs>
      <filter
        id="filter0_d_28025_253694"
        x="22.021"
        y="2.19116"
        width="155.682"
        height="56.7673"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_28025_253694"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_28025_253694"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_28025_253694"
        x="22.021"
        y="98.8879"
        width="155.682"
        height="56.7673"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_28025_253694"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_28025_253694"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_28025_253694"
        x="0.5"
        y="50.5396"
        width="155.682"
        height="56.7673"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_28025_253694"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_28025_253694"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_28025_253694"
        x1="50.9939"
        y1="32.6033"
        x2="50.9939"
        y2="30.1672"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#E2E5EC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_28025_253694"
        x1="50.9939"
        y1="129.301"
        x2="50.9939"
        y2="126.864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#E2E5EC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_28025_253694"
        x1="29.6965"
        y1="80.0092"
        x2="29.6965"
        y2="78.3852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#E2E5EC" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EmptyState extends Vue {}
</script>
