<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27716_51616)">
      <path
        d="M2.63119 3H13.3687C13.4657 3.00003 13.5606 3.02827 13.6418 3.08127C13.723 3.13427 13.7871 3.20975 13.8261 3.29852C13.8652 3.38728 13.8776 3.48549 13.8619 3.58119C13.8461 3.67689 13.8029 3.76594 13.7374 3.8375L9.63119 8.35625C9.54573 8.44773 9.49873 8.56857 9.49994 8.69375V12.2312C9.5007 12.3143 9.48048 12.3963 9.44115 12.4695C9.40182 12.5427 9.34465 12.6048 9.27494 12.65L7.27494 13.9812C7.19992 14.0307 7.11297 14.059 7.02323 14.0631C6.9335 14.0673 6.84429 14.0473 6.76499 14.0051C6.68569 13.9629 6.61922 13.9001 6.57258 13.8233C6.52594 13.7465 6.50085 13.6586 6.49994 13.5688V8.69375C6.50114 8.56857 6.45415 8.44773 6.36869 8.35625L2.26244 3.8375C2.19697 3.76594 2.15373 3.67689 2.13798 3.58119C2.12224 3.48549 2.13466 3.38728 2.17374 3.29852C2.21282 3.20975 2.27688 3.13427 2.3581 3.08127C2.43932 3.02827 2.5342 3.00003 2.63119 3V3Z"
        stroke="#53637A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_27716_51616">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IconFunnel extends Vue {}
</script>
