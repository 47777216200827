import { cloneDeep } from "lodash";
import { pushToTop } from "@/helpers/map.helper";

export default {
  SET_CUSTOMER_SERVICE_CONVERSATION: (state: any, payload: any) => {
    const pinnedClone = cloneDeep(state.pinned);
    pinnedClone.set(payload.id, payload);

    state.pinned = pushToTop(pinnedClone, payload.id);
  },

  SET_CHAT_ITEM(state: any, payload: any): void {
    const { rid, list } = payload;
    const pinnedClone = cloneDeep(state.pinned);

    if (!pinnedClone.has(payload.rid)) {
      pinnedClone.set(rid, { ...list });
    } else {
      const current = pinnedClone.get(rid);
      pinnedClone.set(rid, { ...current, ...list });
    }

    state.pinned = new Map(
      [...pinnedClone.entries()].sort((a, b) => {
        const aOrder = a[1].order;
        const aDate = new Date(a[1].lastMessage?.ts);
        const bOrder = b[1].order;
        const bDate = new Date(b[1].lastMessage?.ts);

        return bOrder - aOrder || bDate.getTime() - aDate.getTime();
      })
    );
  },

  SET_CONVERSATION_LIST(state: any, payload: any): void {
    state.data = payload;
  },

  SET_UNREAD_COUNT(state: any, payload: any): void {
    const { rid, data } = payload;
    const conversationClone = cloneDeep(state.data);

    if (conversationClone.has(rid)) {
      const current = conversationClone.get(rid);
      conversationClone.set(rid, { ...current, unread: data });
    }

    state.data = conversationClone;
  },

  UPDATE_CONVERSATION_ITEM(state: any, payload: any): void {
    const { rid, detail, list } = payload;
    const conversationClone = cloneDeep(state.data);

    if (conversationClone.has(payload.rid)) {
      const current = conversationClone.get(rid);
      conversationClone.set(rid, {
        ...current,
        receive: detail.receive,
        lastMessage: {
          type: detail.type,
          msg: detail.msg,
          ts: detail.ts,
        },
      });
    } else {
      console.log("conversationClone", conversationClone);
      console.log("UPDATE_CONVERSATION_ITEM", payload);

      conversationClone.set(rid, {
        id: list.id,
        avatar: list.user.avatar,
        name: list.user.name,
        receive: detail.receive,
        unread: list.unread,
        lastMessage: {
          type: detail.type,
          msg: detail.msg,
          ts: detail.ts,
        },
      });
    }

    state.data = pushToTop(conversationClone, rid);
  },
};
