import { SocketAPI } from "@/services/SocketAPI";

declare global {
  interface Window {
    CHAT_SDK: any;
  }
}

import { InitArgs } from "@/@types/sdk.type";
import SCOPE from "@/scope";
import Vue from "vue";
import STORE from "./store";
import MarketplaceApp from "./scopes/marketplace/App.vue";
import OperatorApp from "./scopes/operator/App.vue";
import { HttpAPI } from "@/services/HttpAPI";
import { relativeTime } from "@/filters/relative-time.filter";

if (+process.env.VUE_APP_DEV_MODE) {
  import(`@/assets/scss/dev-mode.scss`);
}

import "@/assets/scss/marketplace-chat.scss";
import { currency } from "@/filters/number.filter";
import { MarketAPI } from "@/services/MarketAPI";
import MarketplaceAdapter from "@/adapters/marketplace.adapter";
import ImageModalPlugin from "@/plugins/image-modal.plugin";

Vue.config.productionTip = false;

const { MARKETPLACE, OPERATOR } = SCOPE;
const APPLICATION = new Map<string, any>([
  [MARKETPLACE, MarketplaceApp],
  [OPERATOR, OperatorApp],
]);

window.CHAT_SDK = {
  $appRoot: null,
  $totalNotificationRoot: 0,
  checkPermission: null,
  $marketplaceAdapter: null,
  $customerServiceRoomId: null,
  async init(id: string, args: InitArgs) {
    const app: any = APPLICATION.get(args.scope);
    const store = STORE[args.scope as keyof typeof STORE];
    store.commit("global/SET_SCOPE", args.scope);
    store.commit("global/SET_TYPE_COMPONENT", args.typeComponent);
    store.commit("account/SET_ACCOUNT_INFO", args.account);

    if (args.scope === MARKETPLACE) {
      /** Marketplace Adapter */
      Vue.prototype.$httpMarketplace = new MarketAPI(
        true,
        args.account.accessToken
      );
      this.$marketplaceAdapter = new MarketplaceAdapter(args.account, store);
      await this.$marketplaceAdapter.getCustomerServiceRoom();

      /** Image Modal Plugin */
      Vue.prototype.$imageModal = ImageModalPlugin;

      if (args.options?.screen) {
        store.commit("global/SET_PAGE_STACK", args.options.screen);
      }

      if (args.options?.device === "desktop") {
        await this.initNotification(args);
      }
    }

    if (args.options?.device) {
      store.commit("global/SET_DEVICE", args.options.device);
    }
    if (args.checkPermissionChat) {
      this.checkPermission = args.checkPermissionChat;
    }
    Vue.prototype.$socket = await new SocketAPI().connect({
      authToken: args.account.auth_token,
      userId: args.account.user_id,
    });

    Vue.prototype.$http = await new HttpAPI().connect({
      authToken: args.account.auth_token,
      userId: args.account.user_id,
    });

    Vue.prototype.$httpCoreApi = await new HttpAPI(
      process.env.VUE_APP_CORE_API
    ).connect({
      authToken: args.account.auth_token,
      userId: args.account.user_id,
    });

    Vue.filter("relative", relativeTime);
    Vue.filter("currency", currency);

    this.$appRoot = new Vue({
      store,
      render: (h) => h(app),
    });

    if (id) {
      this.$appRoot.$mount(id);

      if (args.scope === MARKETPLACE) {
        await this.$marketplaceAdapter.getRooms();
      }
    }
  },
  async initNotification(args: InitArgs) {
    Vue.prototype.$http = await new HttpAPI().connect({
      authToken: args.account.auth_token,
      userId: args.account.user_id,
    });
    this.$totalNotificationRoot = await Vue.prototype.$http.getTotalUnread();
    this.totalUnread();

    const socket = await new SocketAPI().connect({
      authToken: args.account.auth_token,
      userId: args.account.user_id,
    });
    socket.onMessage((payload: any) => {
      const { fields } = payload;
      if (fields && fields.args && fields.args[0] === "updated") {
        const room = fields.args[1];
        if (room.lastMessage) {
          Vue.prototype.$http.getTotalUnread().then((rs: any) => {
            this.$totalNotificationRoot = rs;
            this.totalUnread();
          });
        }
      }
    });
  },
  closeApp() {
    window.dispatchEvent(
      new CustomEvent("dispatch", {
        detail: { name: "closeChat" },
        bubbles: true,
      })
    );
  },
  totalUnread() {
    window.dispatchEvent(
      new CustomEvent("dispatch", {
        detail: { name: "getTotalUnread", data: this.$totalNotificationRoot },
        bubbles: true,
      })
    );
  },
};

// if (+process.env.VUE_APP_DEV_MODE) {
//   window.CHAT_SDK.init("#operator-chat", {
//     scope: OPERATOR,
//     typeComponent: "chat-list",
//     account: {
//       // user_id: "WsKXBmS8nkKCru3q2",
//       // auth_token: "g-Q_pLI01C1SYfpVgeUU-i00Cn6JkWOx7ahTSk7VbG1",
//       user_id: "7q9ckK5qXEMg5WxDS",
//       auth_token: "Z9CMdblsVO7ebBYM2HnP7z1M5kqJ7PN1wNtDYqpSyEx",
//       display_name: "operator_1686738157",
//       user_name: "operator_1686738157",
//       nickname: "",
//       active: true,
//       avatar_url: "https://rocketchat.dev.kiotpro.vn/avatar/marketplace69423",
//     },
//     options: {
//       device: "desktop",
//       screen: {
//         name: "",
//         params: "",
//         query: "",
//       },
//     },
//   });
// }
