<template>
  <div class="operator-chat-list set-screen">
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <div class="title">Xóa ghi chú</div>
        <div class="content">
          <p>Bạn có chắc chắn muốn xóa ghi chú này?</p>
          <div class="footer-modal">
            <button class="cancel-note" @click="closeModal">Hủy</button>
            <button class="agree" @click="updateModal">Đồng ý</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isOpen(val) {
      this.showModal = val;
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit("closeModal", false);
    },
    updateModal() {
      this.showModal = false;
      this.$emit("updateModal", false);
    },
  },
};
</script>
