import { Account } from "@/@types/sdk.type";
import ChatListTransformer from "@/transformers/ChatList.transformer";
import { CHAT_LIST_PAGE_SIZE } from "@/constants/app";
import { HttpAPI } from "@/services/HttpAPI";

class ChatListController {
  private $appRoot: any;

  private account: Account;
  private defaultQuery: any;
  private defaultQueryForCurator: any;
  private $http: any;
  private $store: any;

  private readonly PAGE_SIZE = CHAT_LIST_PAGE_SIZE;

  constructor(account?: Account, store?: any) {
    this.$appRoot = window.CHAT_SDK.$appRoot;
    if (!this.$appRoot) {
      this.account = account as Account;
      this.$http = new HttpAPI().connect({
        authToken: (account as Account).auth_token,
        userId: (account as Account).user_id,
      });
      this.$store = store;
    } else {
      this.account = this.$appRoot.$store.state.account;
      this.$http = this.$appRoot.$http;
      this.$store = this.$appRoot.$store;
    }

    this.defaultQuery = {
      lastMessage: { $exists: true },
      "customFields.type": "customer_service",
      msgs: { $gt: 0 },
      "customFields.status": { $in: ["important", "normal", ""] },
    };

    this.defaultQueryForCurator = {
      "customFields.type": "customer_service",
      msgs: { $gt: 0 },
    };
  }
  async getImList(offset: number) {
    return await this.$http.listDirectRooms({
      sort: { "lastMessage.ts": -1 },
      count: this.PAGE_SIZE,
      offset,
    });
  }

  async getSubscriptions() {
    return await this.$http.listSubscriptions();
  }

  getUsernameList(ims: any[]) {
    const usersInteracted = ims.filter((im: any) => !!im.lastMessage);

    return usersInteracted.map((im: any) => {
      const username = im.usernames.filter(
        (name: string) => name !== this.account.user_name
      )[0];
      im.username = username;

      return username;
    });
  }

  async getUserInfo(usernames: string[]) {
    const { users } = await this.$http.listUsers({
      fields: JSON.stringify({ name: 1, emails: 0 }),
      query: JSON.stringify({ username: { $in: usernames } }),
    });

    return users;
  }

  getRawChatItem(ims: any[], update: any[], users: any[]) {
    const imsMapByUser = new Map();
    ims.forEach((im: any) => imsMapByUser.set(im.username, im));

    const _update: any = new Map();
    update.forEach((sub: any) => _update.set(sub.rid, sub));

    const usersMap = new Map();
    users.forEach((user: any) => usersMap.set(user.username, user));

    return Array.from(usersMap.keys()).map((username: string) => {
      const user = usersMap.get(username);
      const im = imsMapByUser.get(username);
      const update = _update.get(im._id);

      return { im, user, update };
    });
  }

  async getChatList(offset: number) {
    try {
      const [{ ims, total, count }, { update }] = await Promise.all([
        this.getImList(offset),
        this.getSubscriptions(),
      ]);
      const users = await this.getUserInfo(this.getUsernameList(ims));

      const chatList = this.getRawChatItem(ims, update, users);
      chatList.sort(
        (a: any, b: any) =>
          new Date(b.im.lm).getTime() - new Date(a.im.lm).getTime()
      );

      this.$store.commit("global/SET_CHAT_LIST", {
        users: ChatListTransformer.transform(chatList),
        offset: offset,
        total: total,
        count: count,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getChannelList(offset: number, customQuery: any) {
    let query = { ...this.defaultQuery };
    if (customQuery) {
      query = { ...query, ...customQuery };
      if (customQuery["customFields.userCurator.id"]) {
        delete query["customFields.status"];
      }
    }

    return await this.$http.listChannels({
      sort: { "lastMessage.ts": -1 },
      query: query,
      count: this.PAGE_SIZE,
      offset,
    });
  }

  getOwnerChannelList(channels: any[]) {
    const usersInteracted = channels.filter((im: any) => !!im.lastMessage);

    return usersInteracted.map((channel: any) => {
      const username = channel.u.username;
      channel.username = username;

      return username;
    });
  }

  getRawChannelItem(ims: any[], update: any[], users: any[]) {
    const imsMapByUser = new Map();
    ims.forEach((im: any) => imsMapByUser.set(im.username, im));

    const _update: any = new Map();
    update.forEach((sub: any) => _update.set(sub.rid, sub));

    const usersMap = new Map();
    users.forEach((user: any) => usersMap.set(user.username, user));
    return Array.from(ims.keys()).map((item: any) => {
      const im = ims[item];
      const user = usersMap.get(im.username);
      const update = _update.get(im._id);

      return { im, user, update };
    });
  }

  async getChatChannelList(offset: number, customQuery: any) {
    try {
      const [{ channels, total, count }, { update }] = await Promise.all([
        this.getChannelList(offset, customQuery),
        this.getSubscriptions(),
      ]);
      const users = await this.getUserInfo(this.getOwnerChannelList(channels));
      const dataChannels = channels.filter((im: any) => !!im.lastMessage);
      const chatList = this.getRawChannelItem(dataChannels, update, users);
      chatList.sort(
        (a: any, b: any) =>
          new Date(b.im.lm).getTime() - new Date(a.im.lm).getTime()
      );
      this.$store.commit("global/SET_CHAT_LIST", {
        users: ChatListTransformer.transform(chatList),
        total: total,
        offset: offset,
        count: count,
      });
      if (!offset) {
        this.$store.dispatch("global/UPDATE_CONVERSATION_DETAIL", null);
        this.$store.dispatch("global/RESET_DETAIL", new Map());
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getChatItem(rid: string) {
    try {
      const { room } = await this.$http.getRoomInfo({ roomId: rid });
      console.log(room);
      return room;
    } catch (error) {
      console.log(error);
    }
  }

  /** Mapping Rooms And Subscriptions */
  public mappingRoomsAndSubscriptions(rooms: any[], subscriptions: any[]) {
    const roomsMap = new Map();
    rooms.forEach((room: any) => roomsMap.set(room._id, room));

    const subscriptionsMap = new Map();
    subscriptions.forEach((sub: any) => subscriptionsMap.set(sub.rid, sub));

    const map = new Map();
    roomsMap.forEach((room: any, key: string) => {
      const sub = subscriptionsMap.get(key);
      map.set(key, { room, sub });
    });

    return map;
  }

  /** Get All Rooms */
  public async getRooms() {
    try {
      const [{ update: subscriptions }, rooms] = await Promise.all([
        this.getSubscriptions(),
        this.$http.getRooms(),
      ]);

      const map = this.mappingRoomsAndSubscriptions(rooms, subscriptions);

      const roomsList = ChatListTransformer.roomTransform(map);

      console.log(roomsList);

      this.$store.dispatch(
        "marketplace/conversation/SET_CONVERSATION_LIST",
        roomsList
      );
    } catch (error) {
      console.log(error);
    }
  }
}

export default ChatListController;
