<template>
  <div class="chat" id="desktop">
    <!--    <transition mode="out-in">-->
    <div class="chat-button" @click="open" v-show="!isShow">
      <div class="chat-icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_0_20)">
            <path
              d="M12.375 2.25C9.89012 2.25495 7.50843 3.24427 5.75135 5.00135C3.99427 6.75843 3.00495 9.14012 3 11.625V19.5281C3 19.9185 3.15507 20.2929 3.4311 20.5689C3.70713 20.8449 4.08151 21 4.47188 21H12.375C14.8614 21 17.246 20.0123 19.0041 18.2541C20.7623 16.496 21.75 14.1114 21.75 11.625C21.75 9.1386 20.7623 6.75403 19.0041 4.99587C17.246 3.23772 14.8614 2.25 12.375 2.25V2.25ZM15 14.25H9.375C9.17609 14.25 8.98532 14.171 8.84467 14.0303C8.70402 13.8897 8.625 13.6989 8.625 13.5C8.625 13.3011 8.70402 13.1103 8.84467 12.9697C8.98532 12.829 9.17609 12.75 9.375 12.75H15C15.1989 12.75 15.3897 12.829 15.5303 12.9697C15.671 13.1103 15.75 13.3011 15.75 13.5C15.75 13.6989 15.671 13.8897 15.5303 14.0303C15.3897 14.171 15.1989 14.25 15 14.25ZM15 11.25H9.375C9.17609 11.25 8.98532 11.171 8.84467 11.0303C8.70402 10.8897 8.625 10.6989 8.625 10.5C8.625 10.3011 8.70402 10.1103 8.84467 9.96967C8.98532 9.82902 9.17609 9.75 9.375 9.75H15C15.1989 9.75 15.3897 9.82902 15.5303 9.96967C15.671 10.1103 15.75 10.3011 15.75 10.5C15.75 10.6989 15.671 10.8897 15.5303 11.0303C15.3897 11.171 15.1989 11.25 15 11.25Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_0_20">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div class="chat-notice" v-show="totalUnreadChat > 0">
          {{ totalUnreadChat }}
        </div>
      </div>

      <span class="chat-text">Chat</span>
    </div>

    <div class="chat-wrapper" v-show="isShow">
      <chat-list @select="onSelect" />

      <chat-detail @close="close" @open="open" ref="chatDetail" />
    </div>
    <!--    </transition>-->
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ChatList from "@/scopes/marketplace/device/desktop/components/list/ChatList.vue";
import ChatDetail from "@/scopes/marketplace/device/desktop/components/detail/ChatDetail.vue";

@Component({
  components: { ChatList, ChatDetail },
})
export default class Chat extends Vue {
  isShow = false;
  totalUnreadChat = 0;

  async mounted() {
    this.totalUnreadChat = window.CHAT_SDK.$totalNotificationRoot;

    window.addEventListener("dispatch", (event: any) => {
      if (event.detail.name == "getTotalUnread") {
        this.totalUnreadChat = event.detail.data;
      }
    });
  }

  public toggle() {
    this.isShow = !this.isShow;
  }

  public open() {
    if (!this.$store.state.account.user_id) {
      window.dispatchEvent(
        new CustomEvent("dispatch", {
          detail: { name: "openAlertSupplierPreview" },
        })
      );
      return;
    }
    this.isShow = true;

    this.$store.commit("marketplace/navigator/UPDATE_IS_SHOW_CHAT", true);
    // As read room chat when open room chat
    (this.$refs.chatDetail as any).markRoomAsRead();
  }

  public close() {
    this.isShow = false;
    this.$store.commit("marketplace/navigator/UPDATE_IS_SHOW_CHAT", false);
  }

  public onSelect() {
    (this.$refs.chatDetail as any).onChangeRoom();
  }
}
</script>
