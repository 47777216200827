import { cloneDeep } from "lodash";

export interface IConversationState {
  data: Map<string, any>;
}

const initialState = {
  data: new Map(),
};

export default () => cloneDeep(initialState);
