export const setStateRoom = (payload: any) => {
  const oldData = localStorage.getItem("dataStateRoom");
  if (!oldData) {
    localStorage.setItem("dataStateRoom", JSON.stringify([payload]));
  } else {
    const tempData = JSON.parse(oldData);
    const index = tempData.findIndex(
      (item: any) => item.roomId == payload.roomId
    );
    if (index > -1) {
      tempData[index] = payload;
    } else {
      tempData.push(payload);
    }
    localStorage.setItem("dataStateRoom", JSON.stringify(tempData));
  }
  window.dispatchEvent(
    new CustomEvent("dataStateRoom-localstorage-changed", {
      detail: {
        storage: localStorage.getItem("dataStateRoom"),
      },
    })
  );
};

export const getStateRoom = (roomId: string) => {
  const oldData = localStorage.getItem("dataStateRoom");
  if (!oldData) {
    return null;
  }
  const tempData = JSON.parse(oldData);
  const data = tempData.filter((item: any) => item.roomId == roomId);
  return data.length ? data[0] : null;
};
