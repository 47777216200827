<template>
  <div class="warningSection">
    <div class="warningSection__icon" v-if="icon">
      <span class="k-icon-warning-circle" v-if="!$slots.icon"></span>
      <slot name="icon" v-else></slot>
    </div>

    <div class="warningSection__content">
      <div class="warningSection__title">
        <slot name="title"></slot>
      </div>

      <div class="warningSection__description">
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class WarningSection extends Vue {
  @Prop({ required: false, default: true })
  private readonly icon!: boolean;
}
</script>
