<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.34387 13.8984L13.2814 16.3984C13.7892 16.7187 14.4142 16.2422 14.2657 15.6562L13.1251 11.1719C13.0943 11.0476 13.0992 10.9171 13.1393 10.7954C13.1793 10.6738 13.253 10.566 13.3517 10.4844L16.8829 7.53905C17.3439 7.15624 17.1095 6.3828 16.5079 6.34374L11.8986 6.04686C11.7728 6.03955 11.6519 5.99575 11.5506 5.92083C11.4493 5.84591 11.372 5.74311 11.3282 5.62499L9.60949 1.29686C9.564 1.17179 9.48111 1.06374 9.3721 0.987391C9.26308 0.911042 9.13321 0.870087 9.00012 0.870087C8.86702 0.870087 8.73715 0.911042 8.62814 0.987391C8.51912 1.06374 8.43624 1.17179 8.39074 1.29686L6.67199 5.62499C6.62819 5.74311 6.55092 5.84591 6.44964 5.92083C6.34836 5.99575 6.22745 6.03955 6.10168 6.04686L1.49231 6.34374C0.890744 6.3828 0.656369 7.15624 1.11731 7.53905L4.64856 10.4844C4.74726 10.566 4.82089 10.6738 4.86097 10.7954C4.90106 10.9171 4.90596 11.0476 4.87512 11.1719L3.82043 15.3281C3.64074 16.0312 4.39074 16.6016 4.99231 16.2187L8.65637 13.8984C8.75912 13.8331 8.87836 13.7984 9.00012 13.7984C9.12188 13.7984 9.24112 13.8331 9.34387 13.8984V13.8984Z"
      stroke="#53637A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconStar extends Vue {}
</script>
