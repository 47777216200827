import { Component, Mixins, Watch } from "vue-property-decorator";
import NavigatorMixin from "@/mixins/Navigator.mixin";
import ChatDetailController from "@/controllers/ChatDetail.controller";
import ChatDetailTransformer from "@/transformers/ChatDetail.transformer";
import { CHAT_DETAIL_PAGE_SIZE } from "@/constants/app";
import ChatListController from "@/controllers/ChatList.controller";
import SCOPE from "@/scope";
import { RoomType } from "@/enums/room.enum";
import MESSAGE_TYPE from "@/constants/message-type";

@Component
export default class ChatDetailMixin extends Mixins(NavigatorMixin) {
  messages: Map<any, any> = new Map();
  message: string | null = null;
  isFocus = false;
  isLoadingMore = false;
  count = null;
  offset = 0;
  user: any = null;
  channel: any = null;
  room: any = null;

  get scope() {
    return this.$store.state.global.scope;
  }

  get device() {
    return this.$store.state.global.device;
  }

  get account() {
    return this.$store.state.account;
  }

  get roomId() {
    if (
      this.scope === SCOPE.MARKETPLACE &&
      (this.device === "mobile" || !this.device)
    ) {
      return this.activePage.params.id;
    } else if (this.scope === SCOPE.MARKETPLACE && this.device === "desktop") {
      return this.$store.state.marketplace.navigator.currentRoomId;
    }
  }

  get roomType() {
    if (this.scope === SCOPE.MARKETPLACE) {
      return this.$store.state.marketplace.navigator.currentRoomType;
    }

    return null;
  }

  get roomUnreadCount() {
    switch (this.$store.state.global.scope) {
      case SCOPE.MARKETPLACE:
        return this.getMarketplaceUnreadCount();

      case SCOPE.OPERATOR:
        return this.getOperatorUnreadCount();
    }
  }

  get details() {
    return this.$store.state.global.details;
  }

  get isShowChat() {
    return this.$store.state.marketplace.navigator.isShowChat;
  }

  @Watch("details", { deep: true })
  public onDetailsChange(val: any) {
    if (val) {
      this.parseMessages();
    }
  }

  public async created() {
    if (
      this.activePage.params &&
      this.activePage.params.id &&
      this.activePage.query &&
      this.activePage.query.direct
    ) {
      await new ChatListController().getChatList(0);
      await this.getRoom(this.activePage.params.usernames);
    } else {
      this.room = this.activePage.room;
    }

    if (!this.details.has(this.roomId)) {
      this.getChatDetail();
    } else {
      this.parseMessages();
    }
  }

  public getMarketplaceUnreadCount() {
    return this.$store.state.marketplace.conversation.data.get(this.roomId)
      .unread;
  }

  public getOperatorUnreadCount() {
    return this.$store.state.global.users.get(this.roomId)
      ? this.$store.state.global.users.get(this.roomId).unread
      : 0;
  }

  public async parseMessages() {
    const messages = this.details.get(this.roomId);
    this.messages = ChatDetailTransformer.groupByDate(messages);

    if (this.roomUnreadCount) {
      if (
        !(
          this.scope === SCOPE.MARKETPLACE &&
          this.device === "desktop" &&
          !this.isShowChat
        )
      ) {
        await this.$http.markRoomAsRead(this.roomId);
      }

      window.CHAT_SDK.$totalNotificationRoot =
        await this.$http.getTotalUnread();
      window.CHAT_SDK.totalUnread();
    }
  }

  async getRoom(usernames?: string[]) {
    const room = this.$store.state.marketplace.conversation.data.get(
      this.roomId
    );

    if (room) {
      this.room = room;
    } else {
      const username = (usernames as string[]).filter(
        (username: string) => username !== this.account.user_name
      )[0];

      const { users } = await this.$http.listUsers({
        fields: JSON.stringify({ name: 1, emails: 0 }),
        query: JSON.stringify({ username: username }),
      });

      this.room = {
        _id: users[0]._id,
        name: users[0].name,
        username: users[0].username,
        status: users[0].status,
        avatar: process.env.VUE_APP_API_HOST + "avatar/" + users[0].username,
      };
    }
  }

  getUserFromStore() {
    const room = this.$store.state.global.users.get(this.roomId);

    if (room) {
      this.user = room.user;
    }

    return room;
  }

  async getUser(usernames?: string[]) {
    const room = this.getUserFromStore();

    if (!room && usernames) {
      const username = usernames.filter(
        (username: string) => username !== this.account.user_name
      )[0];

      const { users } = await this.$http.listUsers({
        fields: JSON.stringify({ name: 1, emails: 0 }),
        query: JSON.stringify({ username: username }),
      });

      this.user = {
        _id: users[0]._id,
        name: users[0].name,
        username: users[0].username,
        status: users[0].status,
        avatar: process.env.VUE_APP_API_HOST + "avatar/" + users[0].username,
      };
    }
  }

  public async getChatDetailByType() {
    let data = null;
    const controller = new ChatDetailController(this.roomId);

    switch (this.roomType) {
      case RoomType.CHANNEL:
        data = await controller.getMessagesChannel(this.offset);
        break;

      case RoomType.DIRECT:
      default:
        data = await controller.getMessages(this.offset);
        break;
    }

    return data;
  }

  public async getChatDetail() {
    if (this.count === 0 || !this.roomId) return;

    const data = await this.getChatDetailByType();

    this.count = (data as any).count;
    this.offset = (data as any).offset + CHAT_DETAIL_PAGE_SIZE;

    this.$store.dispatch("global/SET_DETAILS", {
      rid: this.roomId,
      data: data,
    });
  }

  public async loadMore() {
    this.isLoadingMore = true;
    await this.getChatDetail();
    this.isLoadingMore = false;
  }

  public toggleFocus() {
    this.isFocus = !this.isFocus;
  }

  public onChangeMessage(e: InputEvent) {
    (e.target as any).style.height = "3em";
    (e.target as any).style.height = (e.target as any).scrollHeight + "px";
  }

  public async sendTextMessage(payload?: any) {
    if (!payload) {
      if (!this.message?.trim()) return;

      await this.$store.dispatch("global/SEND_TEXT_MESSAGE", {
        rid: this.roomId,
        type: MESSAGE_TYPE.PLAIN_TEXT,
        message: this.message,
      });
    } else {
      await this.$store.dispatch("global/SEND_TEXT_MESSAGE", {
        rid: this.roomId,
        type: payload.type,
        message: payload.msg,
      });
    }

    this.message = null;
  }

  public async sendFileMessage(payload: any) {
    await this.$store.dispatch("global/SEND_FILE_MESSAGE", {
      rid: this.roomId,
      file: payload.file,
    });
  }
}
