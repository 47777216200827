import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import type {
  ICallMethodResponse,
  IListDirectRequest,
  IListDirectRoomResponse,
  IListMessageRequest,
  IListMessageResponse,
  IListSubscriptionRequest,
  IListSubscriptionResponse,
  IListUserPresenceResponse,
  IListUserRequest,
  IListUserResponse,
  IResponse,
  IRocketChatOptions,
  IRoomInfoRequest,
  IRoomInfoResponse,
  ISendMessageFile,
  ISendMessageResponse,
  ISendMessageText,
  IUserInfoRequest,
  IUserInfoResponse,
  IUserPresenceRequest,
  IListChannelsResponse,
  IChannelCustomFieldsRequest,
  IListAssigneeRequest,
  IGetListResponse,
  IListSampleMessageRequest,
} from "@/interfaces/rocket-chat";
import { RocketChatMethod, RocketChatMsgTypes } from "@/enums";
import { randomString } from "@/helpers/string.helper";
import {
  ICallCoreAPIMethodResponse,
  IDataInfoCustomerRequest,
  INoteCustomerRequest,
  IPaginationRequest,
} from "@/interfaces/rocket-chat";

export class HttpAPI {
  protected http: AxiosInstance;

  private _options?: IRocketChatOptions | null;

  constructor(url?: string, _onError?: (_e: AxiosError) => void) {
    const http = axios.create({
      baseURL: url || process.env.VUE_APP_API_HOST + "api/v1",
      timeout: 15000,
    });

    http.interceptors.request.use((config: AxiosRequestConfig): any => {
      if (config.headers) {
        config.headers["x-auth-token"] = this._options?.authToken;
        config.headers["x-user-id"] = this._options?.userId;
      }

      return config;
    });

    http.interceptors.response.use(
      (resp: AxiosResponse) => resp.data,
      (err: any) => {
        console.error("RocketChat.HttpAPI err: ", err);
        const errMessage =
          err?.response?.data?.message || err?.response?.data?.error;
        this._onError(errMessage ? new Error(errMessage) : err);
        if (errMessage) {
          return Promise.reject(new Error(errMessage));
        }
        return Promise.reject(err);
      }
    );

    this.http = http;
    if (_onError) this._onError = _onError;
  }

  _onError(err: any) {
    console.error("RocketChat._onError err: ", err);
  }

  _genId(): string {
    return randomString(32);
  }

  connect(options: IRocketChatOptions) {
    this._options = options;
    return this;
  }

  disconnect() {
    this._options = null;
  }

  async callMethod(method: RocketChatMethod, data: any) {
    return this.http.post(`/method.call/${method}`, data);
  }

  async sendMessageText(data: ISendMessageText): Promise<ISendMessageResponse> {
    const result: ICallMethodResponse = await this.http.post(
      "/method.call/sendMessage",
      {
        message: JSON.stringify({
          msg: RocketChatMsgTypes.Method,
          method: RocketChatMethod.SendMessage,
          id: this._genId(),
          params: [data],
        }),
      }
    );
    if (result?.success && result?.message) {
      return {
        success: true,
        message: JSON.parse(result.message)?.result,
      };
    }
    return {
      success: false,
    };
  }

  async listDirectRooms(
    req?: IListDirectRequest
  ): Promise<IListDirectRoomResponse> {
    const params: any = { ...req };
    if (req?.sort) {
      params.sort = JSON.stringify(req.sort);
    }
    const data: IListDirectRoomResponse = await this.http.get("/im.list", {
      params,
    });
    return data;
  }

  async sendMessageFile(data: ISendMessageFile) {
    const formData = new FormData();
    formData.append("file", data.file);

    return this.http.post(`/rooms.upload/${data.rid}`, formData);
  }

  async listMessages(
    params: IListMessageRequest
  ): Promise<IListMessageResponse> {
    const data: IListMessageResponse = await this.http.get("/im.messages", {
      params,
    });
    return data;
  }

  async listUserPresence(
    params?: IUserPresenceRequest
  ): Promise<IListUserPresenceResponse> {
    const data: IListUserPresenceResponse = await this.http.get(
      "/users.presence",
      { params }
    );
    return data;
  }

  async getRoomInfo(params: IRoomInfoRequest): Promise<IRoomInfoResponse> {
    return this.http.get<any, IRoomInfoResponse>("/rooms.info", { params });
  }

  async listUsers(params?: IListUserRequest): Promise<IListUserResponse> {
    return this.http.get<any, IListUserResponse>("/users.list", { params });
  }

  async getUserInfo(params?: IUserInfoRequest): Promise<IUserInfoResponse> {
    return this.http.get<any, IUserInfoResponse>("/users.info", { params });
  }

  async listSubscriptions(
    params?: IListSubscriptionRequest
  ): Promise<IListSubscriptionResponse> {
    return this.http.get<any, IListSubscriptionResponse>("/subscriptions.get", {
      params,
    });
  }

  async markRoomAsRead(rid: string): Promise<IResponse> {
    return this.http.post<any, IResponse>("/subscriptions.read", { rid });
  }

  async markMessageAsRead(msgIds: string[]) {
    return this.http.post("/method.call/readMessages", {
      message: JSON.stringify({
        msg: RocketChatMsgTypes.Method,
        method: RocketChatMethod.readMessages,
        id: this._genId(),
        params: msgIds,
      }),
    });
  }
  async addCustomFieldForConversation(
    data: IChannelCustomFieldsRequest
  ): Promise<any> {
    const result: ICallCoreAPIMethodResponse = await this.http.post(
      "/channels/set-custom-fields",
      data
    );
    if (result?.msg == "Success") {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  }

  async assignUserConversation(
    data: IChannelCustomFieldsRequest
  ): Promise<any> {
    const result: ICallCoreAPIMethodResponse = await this.http.post(
      "/channels/assign-user-conversation",
      data
    );
    if (result?.msg == "Success") {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  }

  async listChannels(req?: IListDirectRequest): Promise<IListChannelsResponse> {
    const params: any = { ...req };
    if (req?.sort) {
      params.sort = JSON.stringify(req.sort);
    }
    if (req?.query) {
      params.query = JSON.stringify(req.query);
    }
    const data: IListChannelsResponse = await this.http.get("/channels.list", {
      params,
    });
    return data;
  }

  async listMessagesChannel(
    params: IListMessageRequest
  ): Promise<IListMessageResponse> {
    const data: IListMessageResponse = await this.http.get(
      "/channels.messages",
      { params }
    );
    return data;
  }

  async listAssignee(params: IListAssigneeRequest): Promise<IGetListResponse> {
    const data: IGetListResponse = await this.http.get("/resources/assignee", {
      params,
    });
    return data;
  }

  async getInfoCustoms(params: IDataInfoCustomerRequest): Promise<any> {
    const data: any = await this.http.get("/channels/info-customer", {
      params,
    });
    return data;
  }

  async getInfoNotesCustom(params: IPaginationRequest): Promise<any> {
    const data: any = await this.http.get("/channels/list-note-customers", {
      params,
    });
    return data;
  }

  async addNoteForCustomer(data: INoteCustomerRequest): Promise<any> {
    const result: ICallCoreAPIMethodResponse = await this.http.post(
      "/channels/create-note-for-customer",
      data
    );
    if (result?.msg == "Success") {
      return {
        success: true,
        data: result.data,
      };
    }
    return {
      success: false,
    };
  }

  async deleteNote(data: any): Promise<any> {
    const result: any = await this.http.post(
      "/channels/delete-note-for-customer",
      data
    );
    if (result?.msg == "Success") {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  }

  async listSampleMessages(
    params: IListSampleMessageRequest
  ): Promise<IGetListResponse> {
    const data: IGetListResponse = await this.http.get("/sample-messages", {
      params,
    });
    return data;
  }

  async createSampleMessage(data: any): Promise<any> {
    const result: any = await this.http.post("/sample-messages/create", data);
    if (result?.msg == "Success") {
      return true;
    }
    return false;
  }

  async deleteSampleMessage(id: number): Promise<any> {
    const result: any = await this.http.post("/sample-messages/delete/" + id);
    if (result?.msg == "Success") {
      return true;
    }
    return false;
  }

  async getTotalUnread(): Promise<any> {
    const result: any = await this.http.get("/subscriptions.get");
    if (result?.success) {
      const data = result.update.filter((item: any) => item.unread > 0);
      return data ? data.length : 0;
    }
    return 0;
  }

  public async getRooms(): Promise<any> {
    const result: any = await this.http.get("/rooms.get");
    if (result?.success) {
      return result.update;
    }

    return [];
  }

  async getIdUnread(): Promise<any> {
    const result: any = await this.http.get("/subscriptions.get");
    if (result?.success) {
      const data: any = [];
      result.update.forEach((item: any) => {
        if (item.unread > 0) {
          data.push(item.rid);
        }
      });
      return data;
    }
    return 0;
  }

  async getReportCustomer(params: any): Promise<any> {
    const result: any = await this.http.get("/channels/report-customer", {
      params: params,
    });
    return result;
  }

  async getHistoryCustomer(params: any): Promise<any> {
    const result: any = await this.http.get(
      "/channels/merchant-activity-history",
      {
        params: params,
      }
    );
    return result;
  }
}
