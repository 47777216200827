/* eslint-disable no-empty */
import { Component, Mixins, Watch } from "vue-property-decorator";
import NavigatorMixin from "@/mixins/Navigator.mixin";
import ChatDetailController from "@/controllers/ChatDetail.controller";
import ChatDetailTransformer from "@/transformers/ChatDetail.transformer";
import { CHAT_DETAIL_PAGE_SIZE } from "@/constants/app";
import { setStateRoom, getStateRoom } from "@/helpers/localstorage.helper";
import { cloneDeep } from "lodash";
import CommonMixin from "@/mixins/operator/Common.mixin";
import { checkPermission } from "@/helpers/permission.helper";
import PERMISSION from "@/constants/permission";
import { randomString } from "@/helpers/string.helper";
@Component
export default class ChatDetailMixin extends Mixins(
  NavigatorMixin,
  CommonMixin
) {
  messages: Map<any, any> = new Map();
  message: string | null = null;
  isFocus = false;
  isLoadingMore = false;
  count = null;
  offset = 0;
  user: any = null;
  dataStateOfRoom: any = null;
  fileUpload: any = null;
  base64ImageUpload: any = null;
  allowedExtensions = ["png", "jpg", "jpeg"];
  MAX_ALLOW_FILE_SIZE = 5 * 1024 * 1024;
  MAX_ALLOW_FILE_SIZE_TEXT = "5MB";
  scrollToFirstMessage = false;
  arrayDraftImages: any = {};
  showBlockSampleMessage = false;
  wrapper!: HTMLElement;
  idTypingEvent: any;
  showAttachFile = false;
  get roomId() {
    if (this.conversationDetail) {
      return this.conversationDetail.roomId;
    }
    return null;
  }

  get roomName() {
    if (this.conversationDetail) {
      return this.conversationDetail.roomName;
    }
    return null;
  }

  get roomUnreadCount() {
    return this.$store.state.global.users.get(this.roomId)
      ? this.$store.state.global.users.get(this.roomId).unread
      : 0;
  }

  get details() {
    return this.$store.state.global.details;
  }

  get conversationDetail() {
    return this.$store.state.global.conversationDetail;
  }

  get firstCharacter() {
    if (this.conversationDetail.customFields?.user_create_name) {
      return this.conversationDetail.customFields?.user_create_name.charAt(0);
    }
    return "";
  }

  @Watch("details", { deep: true })
  public onDetailsChange(val: any) {
    if (val) {
      this.parseMessages();
    }
  }

  @Watch("conversationDetail", { deep: true, immediate: true })
  public async onRoomChange(val: any, old: any) {
    if ((old && val.roomId !== old.roomId) || !old) {
      this.scrollToFirstMessage = false;
      this.$nextTick(() => {
        this.scrollToLastMessage();
      });
      this.setDraftStatusRoom(old);
      this.showBlockSampleMessage = false;
      if (this?.$refs?.message_input) {
        (this.$refs.message_input as HTMLElement).focus();
      }
    }
    if (val) {
      this.offset = 0;
      this.dataStateOfRoom = await getStateRoom(this.roomId);
      this.message = this.dataStateOfRoom?.message;
      this.fileUpload = this.arrayDraftImages[this.roomId]?.file;
      this.base64ImageUpload = this.arrayDraftImages[this.roomId]?.base64;
      if (this.message) {
        setTimeout(() => {
          this.setHeightChatBox(null);
        }, 100);
      } else {
        this.setDefaultHeightFooter(this.fileUpload ? 152 : 96);
      }
      this.initData();
      const oldRoomId = old && old.roomId ? old.roomId : "";
      this.initSubscribe(oldRoomId);
    }
  }

  public setDraftStatusRoom(room: any) {
    if (room) {
      const dataDraftRoom = this.arrayDraftImages[room.roomId];
      if (dataDraftRoom && Object.keys(dataDraftRoom).length) {
        setStateRoom({
          roomId: room.roomId,
          state: dataDraftRoom.base64 ? "draft" : "",
          message: dataDraftRoom.message,
        });
      }
    }
  }

  get userByConversationDetail() {
    return this.conversationDetail ? this.conversationDetail.user : null;
  }

  public initData() {
    if (
      !this.details.has(this.roomId) ||
      !this.details.get(this.roomId).length
    ) {
      this.getChatDetail();
    } else {
      this.parseMessages();
    }
  }

  public initSubscribe(oldRoomId?: string) {
    // un-subscribe conversation message change
    if (oldRoomId) {
      this.$socket.unsubEvent(oldRoomId);
    }
    this.$socket.unsubEvent(this.roomId);
    // subscribe conversation message change
    if (this.roomId) {
      this.$socket.subscribeRoomMessages(this.roomId, this.roomId);
    }
    if (this.idTypingEvent) {
      this.$socket.unsubEvent(this.idTypingEvent);
    }
    this.idTypingEvent = randomString(32);
    this.$socket.subscribeRoomTyping(this.roomId);
  }
  public parseMessages() {
    const messages = this.details.get(this.roomId);
    if (messages) {
      const messageByDates = ChatDetailTransformer.groupByDate(messages);
      const tempMessage = new Map();
      const ownerRoom = this.conversationDetail?.customFields?.user_create_id;
      messageByDates.forEach((item, key) => {
        const newMessages = cloneDeep(item);
        const messageGroupByUser = ChatDetailTransformer.groupByUser(
          newMessages,
          ownerRoom
        );
        tempMessage.set(key, messageGroupByUser);
      });
      this.messages = tempMessage;
    } else {
      this.messages = new Map();
    }
    if (this.roomUnreadCount) {
      this.$http.markRoomAsRead(this.roomId);
      window.CHAT_SDK.$totalNotificationRoot = this.$http.getTotalUnread;
      window.CHAT_SDK.totalUnread();
    }
    if (this.message) {
      setTimeout(() => {
        this.setHeightChatBox(null);
      }, 100);
    }
  }

  public async getChatDetail() {
    if (this.roomId) {
      const data = await new ChatDetailController(
        this.roomId
      ).getMessagesChannel(this.offset);
      this.count = (data as any)?.count;
      if (data?.messages.length) {
        this.offset = (data as any)?.offset + CHAT_DETAIL_PAGE_SIZE;
        this.$store.dispatch("global/SET_DETAILS", {
          rid: this.roomId,
          data: { messages: data?.messages },
        });
      }
      if (data?.count < CHAT_DETAIL_PAGE_SIZE) {
        this.scrollToFirstMessage = true;
      }
      this.isLoadingMore = false;
    }
  }

  public toggleFocus() {
    this.isFocus = !this.isFocus;
  }

  public toggleFocusOut() {
    this.isFocus = !this.isFocus;
    // update store message sending va state cua store
    setStateRoom({
      roomId: this.roomId,
      state: this.message ? "draft" : "",
      message: this.message,
    });
  }

  public onChangeMessage(e: InputEvent) {
    this.setHeightChatBox(e.target as HTMLTextAreaElement);
  }

  public async sendTextMessage() {
    if (!this.message?.trim() && !this.fileUpload) return;
    const hasPermission = await checkPermission(PERMISSION.CHAT_SEND);
    if (!hasPermission) {
      this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
      return;
    }
    const payload = {
      roomId: this.roomId,
      state: "sending",
      message: this.message,
      attachment: {
        file: "",
        base64: "",
      },
    };
    if (this.fileUpload) {
      this.message = "";
      payload.attachment = {
        file: this.fileUpload,
        base64: this.base64ImageUpload,
      };
    }
    setStateRoom(payload);
    if (this.message?.trim()) {
      this.$store.dispatch("global/SEND_TEXT_MESSAGE", {
        rid: this.roomId,
        message: this.message,
      });
    } else {
      this.$store.dispatch("global/SEND_FILE_MESSAGE", {
        rid: this.roomId,
        file: this.fileUpload,
      });
      delete this.arrayDraftImages[this.roomId];
    }
    this.message = null;
    this.fileUpload = null;
    this.base64ImageUpload = null;
    this.setDefaultHeightFooter();
    setTimeout(() => {
      this.scrollToLastMessage();
    }, 500);
  }

  public async addCustomFieldForConversation(state: string) {
    if (this.roomId) {
      let stateToChange = state;
      const customFields = this.conversationDetail.customFields
        ? this.conversationDetail.customFields
        : {};
      const oldStatus = customFields["status"];
      if (oldStatus == state) {
        customFields["status"] = "normal";
        stateToChange = "normal";
      } else {
        customFields["status"] = state;
      }
      const data = await new ChatDetailController(
        this.roomId
      ).addCustomFieldForConversation({
        roomId: this.roomId,
        customFields: customFields,
        roomName: this.conversationDetail.roomName,
      });
      if (data && data.success) {
        // update vào store trạng thái statusRoom của conversation
        this.$store.dispatch("global/UPDATE_CONVERSATION_DETAIL_CUSTOM_FIELD", {
          roomId: this.roomId,
          customFields: customFields,
          statusRoom: stateToChange,
        });
      }
    }
  }

  public async listSampleMessages(params: any) {
    if (this.roomId) {
      const data = await new ChatDetailController(
        this.roomId
      ).listSampleMessages(params);
      return data.data;
    }
  }

  public attachFile() {
    this.showAttachFile = true;
    setTimeout(() => {
      (this.$refs?.fileUpload as HTMLElement).click();
    }, 100);
  }

  public onChangeFileUpload() {
    const fileUpload = (this.$refs?.fileUpload as any).files[0];
    this.showAttachFile = false;
    if (fileUpload) {
      if (!this.validateImage(fileUpload)) {
        return;
      }
      this.setDefaultHeightFooter(152);
      this.fileUpload = fileUpload;
      this.convertImageToBase64(fileUpload)
        .then((base64String) => {
          this.base64ImageUpload = base64String;
          this.arrayDraftImages[this.roomId] = {
            base64: this.base64ImageUpload,
            file: this.fileUpload,
          };
        })
        .catch((error) => {
          console.error("Error converting image to base64:", error);
        });
    }
  }

  public validateImage(file: File) {
    const fileType = file?.type?.split("/")?.pop()?.toLowerCase();
    const fileSize = file?.size;
    if (fileType && !this.allowedExtensions.includes(fileType)) {
      this.toastCustom(
        "Định dạng ảnh không được hỗ trợ. Vui lòng chọn ảnh PNG, JPG, JPEG",
        "error",
        3000
      );
      return false;
    }
    if (fileSize && fileSize > this.MAX_ALLOW_FILE_SIZE) {
      this.toastCustom(
        "Ảnh đã chọn vượt quá " +
          this.MAX_ALLOW_FILE_SIZE_TEXT +
          ", vui lòng chọn ảnh có kích thước nhỏ hơn",
        "error",
        3000
      );
      return false;
    }
    return true;
  }

  public convertImageToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  public closeAttachImage() {
    (this.$refs.fileUpload as HTMLInputElement).value = "";
    this.fileUpload = null;
    this.base64ImageUpload = null;
    this.setDefaultHeightFooter();
    setStateRoom({
      roomId: this.roomId,
      state: this.base64ImageUpload ? "draft" : "",
      message: this.message,
    });
    if (this.arrayDraftImages[this.roomId]) {
      delete this.arrayDraftImages[this.roomId];
    }
  }

  public setDefaultHeightFooter(height = 0) {
    if (this.$refs.footer)
      (this.$refs.footer as HTMLElement).style.minHeight =
        (height ? height : 96) + "px";
  }

  public setHeightChatBox(data: HTMLTextAreaElement | null) {
    let textarea = this.$refs.message_input as HTMLTextAreaElement;
    if (data) textarea = data;
    if (textarea) {
      const footer = this.$refs.footer;
      (footer as HTMLElement).style.minHeight = "auto";
      const value = textarea.value;
      const nowHeight = textarea.scrollHeight;
      const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
      const numLines = Math.ceil(nowHeight / lineHeight) + 1;
      let newHeight = Math.min(numLines * lineHeight, lineHeight * 9) + "px";
      if (numLines >= 5) textarea.style.overflow = "auto";
      if (!value || numLines <= 5) {
        newHeight = "96px";
      }
      if (value) {
        textarea.focus();
      }
      (footer as HTMLElement).style.minHeight = newHeight;
      textarea.scrollTop = textarea.scrollHeight;
    }
  }

  public async createSampleMessage(params: any) {
    try {
      if (this.roomId) {
        const data = await new ChatDetailController(
          this.roomId
        ).createSampleMessage(params);
        return data;
      }
    } catch (error: any) {
      if (error?.response && error?.response?.status === 403) {
        this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
        return;
      }
      this.toastCustom("Có lỗi xảy ra", "error");
    }
  }

  public handleScrollChat(event: Event) {
    const { scrollTop, clientHeight, scrollHeight } = this.wrapper;
    if (
      scrollHeight - Math.abs(scrollTop) <= clientHeight + 1 &&
      !this.isLoadingMore &&
      (this.count == null || this.count > 0)
    ) {
      this.isLoadingMore = true;
      this.getChatDetail().then(() => {
        this.scrollToLastMessage(scrollTop);
      });
    }
  }

  public scrollToLastMessage(height = 0) {
    const chatBox = this.$refs.chatBox as HTMLElement;
    if (height < 0) {
      if (chatBox) chatBox.scrollTop = height;
      return;
    }
    if (chatBox) chatBox.scrollTop = chatBox.scrollHeight;
  }

  public async deleteSampleMessage(id: number) {
    if (this.roomId) {
      const response = await new ChatDetailController(
        this.roomId
      ).deleteSampleMessage(id);
      return response;
    }
  }
}
