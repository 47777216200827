import { Account } from "@/@types/sdk.type";
import ChatDetailTransformer from "@/transformers/ChatDetail.transformer";
import { CHAT_DETAIL_PAGE_SIZE } from "@/constants/app";

class ChatDetailController {
  private $appRoot: any;

  private account: Account;

  private roomId: string;

  constructor(roomId: string) {
    this.$appRoot = window.CHAT_SDK.$appRoot;
    this.account = this.$appRoot.$store.state.account;
    this.roomId = roomId;
  }

  async getMessages(offset: number) {
    try {
      const response = await this.$appRoot.$http.listMessages({
        roomId: this.roomId,
        sort: JSON.stringify({ ts: -1 }),
        count: CHAT_DETAIL_PAGE_SIZE,
        offset,
      });

      return {
        messages: ChatDetailTransformer.transform(response.messages),
        offset: response.offset,
        total: response.total,
        count: response.count,
      };
    } catch (error) {
      console.log(error);
    }
  }

  async getMessagesChannel(offset: number) {
    try {
      const response = await this.$appRoot.$http.listMessagesChannel({
        roomId: this.roomId,
        sort: JSON.stringify({ ts: -1 }),
        query: JSON.stringify({
          $or: [{ md: { $exists: true } }, { file: { $exists: true } }],
        }),
        count: 20,
        offset,
      });
      return {
        messages: ChatDetailTransformer.transform(response.messages),
        offset: response.offset,
        total: response.total,
        count: response.count,
      };
    } catch (error) {
      console.log(error);
    }
  }

  async addCustomFieldForConversation(payload: any) {
    try {
      return this.$appRoot.$httpCoreApi.addCustomFieldForConversation(payload);
    } catch (error) {
      return null;
    }
  }

  async assignUserConversation(payload: any) {
    try {
      return this.$appRoot.$httpCoreApi.assignUserConversation(payload);
    } catch (error) {
      return null;
    }
  }

  async listAssignee(params: any) {
    try {
      return this.$appRoot.$httpCoreApi.listAssignee(params);
    } catch (error) {
      console.log(error);
    }
  }

  async getInfoCustoms(userCreateIdCore: string) {
    try {
      return this.$appRoot.$httpCoreApi.getInfoCustoms({
        userCreateIdCore,
        roomId: this.roomId,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getReportCustomer(retailerId: string) {
    try {
      return this.$appRoot.$httpCoreApi.getReportCustomer({
        retailer_id: retailerId,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getHistoryCustomer(retailerId: string) {
    try {
      return this.$appRoot.$httpCoreApi.getHistoryCustomer({
        retailer_id: retailerId,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async addNoteForCustomer(payload: any) {
    try {
      payload.roomId = this.roomId;
      return this.$appRoot.$httpCoreApi.addNoteForCustomer(payload);
    } catch (error) {
      console.log(error);
    }
  }

  async getInfoNotesCustom(params: any) {
    try {
      return this.$appRoot.$httpCoreApi.getInfoNotesCustom(params);
    } catch (error) {
      console.log(error);
    }
  }

  async deleteNote(noteId: number) {
    try {
      const roomId = this.roomId;
      return this.$appRoot.$httpCoreApi.deleteNote({ roomId, noteId });
    } catch (error) {
      console.log(error);
    }
  }

  async listSampleMessages(params: any) {
    try {
      return this.$appRoot.$httpCoreApi.listSampleMessages(params);
    } catch (error) {
      console.log(error);
    }
  }

  async createSampleMessage(params: any) {
    try {
      return this.$appRoot.$httpCoreApi.createSampleMessage(params);
    } catch (error) {
      console.log(error);
    }
  }

  async deleteSampleMessage(id: number) {
    try {
      return this.$appRoot.$httpCoreApi.deleteSampleMessage(id);
    } catch (error) {
      console.log(error);
    }
  }
}

export default ChatDetailController;
