import { Component, Vue } from "vue-property-decorator";
import { IPage } from "@/interfaces/page.interface";

@Component
export default class NavigatorMixin extends Vue {
  get pageStack() {
    return this.$store.state.global.pageStack;
  }

  get activePage() {
    return this.pageStack[this.pageStack.length - 1];
  }

  get activeComponent() {
    return this.$store.state.global.typeComponent;
  }

  public async gotoPage(page: IPage) {
    await this.$store.dispatch("global/PUSH_PAGE", page);
  }

  public async goBackPage() {
    if (this.pageStack.length > 1) {
      await this.$store.dispatch("global/POP_PAGE");
    } else {
      await this.$store.dispatch("global/CLEAR_STACK");
      window.CHAT_SDK.closeApp();
    }
  }
}
