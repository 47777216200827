<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27419_110793)">
      <path
        d="M10 6.25V10.625"
        stroke="#53637A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8516 2.5H7.14844C7.06728 2.49972 6.98687 2.51544 6.9118 2.54628C6.83672 2.57711 6.76847 2.62245 6.71094 2.67969L2.67969 6.71094C2.62245 6.76847 2.57711 6.83672 2.54628 6.9118C2.51544 6.98687 2.49972 7.06728 2.5 7.14844V12.8516C2.49972 12.9327 2.51544 13.0131 2.54628 13.0882C2.57711 13.1633 2.62245 13.2315 2.67969 13.2891L6.71094 17.3203C6.76847 17.3776 6.83672 17.4229 6.9118 17.4537C6.98687 17.4846 7.06728 17.5003 7.14844 17.5H12.8516C12.9327 17.5003 13.0131 17.4846 13.0882 17.4537C13.1633 17.4229 13.2315 17.3776 13.2891 17.3203L17.3203 13.2891C17.3776 13.2315 17.4229 13.1633 17.4537 13.0882C17.4846 13.0131 17.5003 12.9327 17.5 12.8516V7.14844C17.5003 7.06728 17.4846 6.98687 17.4537 6.9118C17.4229 6.83672 17.3776 6.76847 17.3203 6.71094L13.2891 2.67969C13.2315 2.62245 13.1633 2.57711 13.0882 2.54628C13.0131 2.51544 12.9327 2.49972 12.8516 2.5V2.5Z"
        stroke="#53637A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 14.0625C10.3452 14.0625 10.625 13.7827 10.625 13.4375C10.625 13.0923 10.3452 12.8125 10 12.8125C9.65482 12.8125 9.375 13.0923 9.375 13.4375C9.375 13.7827 9.65482 14.0625 10 14.0625Z"
        fill="#53637A"
      />
    </g>
    <defs>
      <clipPath id="clip0_27419_110793">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconWarning extends Vue {}
</script>
