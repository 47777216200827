const MESSAGE_TYPE = {
  PARAGRAPH: "PARAGRAPH",
  PLAIN_TEXT: "PLAIN_TEXT",
  BREAK_LINE: "BREAK_LINE",
  IMAGE: "IMAGE",
  LINK: "LINK",
  PRODUCT: "PRODUCT",
  ORDER: "ORDER",
  ATTACHMENT: "ATTACHMENT",
  NOT_SUPPORT: "NOT_SUPPORT",
  EMOJI: "EMOJI",
};

export default MESSAGE_TYPE;
