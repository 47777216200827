<template>
  <div class="operator-chat-right-list">
    <div class="operator-chat-right-list__header">
      <div class="operator-chat-right-list__header__avatar">
        {{ firstCharacter }}
      </div>
      <div class="operator-chat-right-list__header__name">
        <a @click.prevent="merchantDetail">{{ infoMerchant?.retailer_code }}</a>
      </div>
    </div>
    <div class="operator-chat-right-list__content">
      <div class="d-flex">
        <div class="label">
          <span class="icon-label">
            <IconIndustry />
          </span>
          Ngành hàng
        </div>
        <div class="information">
          {{ infoMerchant ? infoMerchant.industry_name : "---" }}
        </div>
      </div>
      <div class="d-flex">
        <div class="label">
          <span class="icon-label">
            <IconUser />
          </span>
          Sale phụ trách
        </div>
        <div class="information">
          {{ infoMerchant ? infoMerchant.sale : "---" }}
        </div>
      </div>
      <div class="d-flex">
        <div class="label">
          <span class="icon-label">
            <IconClock />
          </span>
          Truy cập gần nhất
        </div>
        <div class="information">
          {{ infoMerchant ? infoMerchant.last_login : "---" }}
        </div>
      </div>
      <div class="d-flex">
        <div class="label">
          <span class="icon-label">
            <IconHandbag />
          </span>
          Mua hàng gần nhất
        </div>
        <div class="information">
          {{ infoMerchant ? infoMerchant.last_order : "---" }}
        </div>
      </div>
    </div>
    <div class="operator-chat-right-list__report">
      <div class="d-flex">
        <b class="w-50">Thống kê</b>
      </div>
      <div class="item-list">
        <div
          class="item-content"
          v-for="(item, index) in dataReport"
          :key="index"
        >
          <div class="title">{{ item.name }}</div>
          <div class="total">{{ formatPrice(item.value) }}</div>
        </div>
      </div>
    </div>
    <div class="operator-chat-right-list__history" v-if="dataHistory.length">
      <div class="d-flex">
        <b class="w-50">Lịch sử hoạt động</b>
      </div>
      <div class="item-list">
        <div
          class="item-container"
          v-for="(item, index) in dataHistory"
          :key="index"
        >
          <div class="title">{{ buildTextName(item.key) }}</div>
          <div class="item-content">
            <div class="code">
              <a :href="buildUrl(item)">{{ item.code }}</a>
            </div>
            <div :class="buildClassStatus(item)">
              {{ buildTextStatus(item) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="operator-chat-right-list__note">
      <div class="d-flex">
        <b class="w-50">Ghi chú</b>
        <div @click="createNote()" class="w-50 button-create">Tạo mới</div>
        <ModalTrash
          v-show="showModalTrash"
          :isOpen="showModalTrash"
          @closeModal="showModalTrash = $event"
          @updateModal="removeNote()"
        />
      </div>
      <div v-if="isCreate">
        <textarea
          class="description"
          placeholder="Nhập ghi chú mới"
          @keyup="searchInputOnEnter"
          v-model="note.description"
          rows="4"
        />
        <div class="d-flex footer">
          <button @click="isCreate = !isCreate" class="cancel-note">
            Bỏ qua
          </button>
          <button
            @click="submit()"
            class="save"
            :class="activeSave && note.description ? 'active' : 'disabled-zone'"
          >
            Lưu
          </button>
        </div>
      </div>
      <div v-else-if="!listNote.length" class="label">Chưa có ghi chú</div>
      <div
        class="block-note"
        @scroll="handleScroll"
        v-if="listNote && listNote.length"
      >
        <div
          class="item-note"
          :style="showModalTrash ? 'z-index: -1' : ''"
          v-for="(note, index) in listNote"
          :key="note.id"
        >
          <b>{{ note.user_name }}</b>
          <div class="time">{{ note.created_at }}</div>
          <div
            class="content-note"
            :style="
              buttonLabel(note, index) === 'Thu gọn' ? 'display: block' : ''
            "
          >
            {{ note.description }}
          </div>
          <span
            v-if="shouldTruncate(note, index)"
            class="toggle-collapse"
            @click="toggleCollapse(note, index)"
          >
            {{ buttonLabel(note, index) }}
          </span>
          <div @click="removeItem(note)" class="trash">
            <IconTrash />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { Component, Vue } from "vue-property-decorator";
import IconInformation from "@/assets/images/operator/iconInformation.vue";
import IconIndustry from "@/assets/images/operator/iconIndustry.vue";
import IconUser from "@/assets/images/operator/iconUser.vue";
import IconClock from "@/assets/images/operator/iconClock.vue";
import IconHandbag from "@/assets/images/operator/iconHandbag.vue";
import IconTrash from "@/assets/images/operator/iconTrash.vue";
import ModalTrash from "@/scopes/operator/components/modal/ModalTrash.vue";
import CommonMixin from "@/mixins/operator/Common.mixin";
import ChatDetailController from "@/controllers/ChatDetail.controller";
import { QuotationRequestStatusText, InvoiceStatusText, OrderStatusText, InvoiceStatusClassName, OrderStatusClassName, QuotationRequestStatusClassName } from "@/enums";

@Component({
  mixins: [CommonMixin],
  components: {
    IconInformation,
    IconIndustry,
    IconUser,
    IconClock,
    IconHandbag,
    IconTrash,
    ModalTrash,
  },
  computed: {
    conversationDetail() {
      return this.$store.state.global.conversationDetail;
    },
    roomId() {
      if (this.conversationDetail) {
        return this.conversationDetail.roomId;
      }
      return null;
    },
    conversationUser() {
      return this.$store.state.global.conversationDetail.user;
    },
    account() {
      return this.$store.state.account;
    },
    firstCharacter() {
      if (this.conversationDetail?.customFields?.user_create_name) {
        return this.conversationDetail?.customFields?.user_create_name.charAt(0).toUpperCase()
      }
      return ""
    }
  },
  data() {
    return {
      dataNull: true,
      isCreate: false,
      note: {
        description: null,
      },
      activeSave: false,
      listNote: [],
      dataReport: [
        { name: 'DDH hoàn thành', value: 0, key: 'order_counting' },
        { name: 'GMV hoàn thành', value: 0, key: 'gmv_hoan_thanh' },
        { name: 'Giá trị trung bình đơn', value: 0, key: 'gmv_order' }
      ],
      dataHistory: [],
      showModalTrash: false,
      itemDelete: null,
      heightNote: 42,
      isCollapsed: [],
      infoMerchant: null,
      isCreateTing: false,
      offsetNote: 0,
      limitNote: 10,
      totalNote: 0,
      totalPageNote: 0,
    };
  },
  watch: {
    conversationDetail: {
      handler: function (val, oldVal) {
        if (!oldVal || (val && val.roomId !== oldVal.roomId)) {
          this.getInfoCustomer(val);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.conversationDetail) {
      this.getInfoCustomer(this.conversationDetail)
    }
  },
  methods: {
    merchantDetail() {
      window.dispatchEvent(
        new CustomEvent("dispatch", {
          detail: {
            name: "goToLink",
            link: "/merchants?retailer_code=" + this.infoMerchant?.retailer_code,
            target: "_blank",
          },
          bubbles: true,
        })
      );
    },
    async getInfoCustomer(data) {
      if (data) {
        this.dataHistory = [];
        let userCreateIdCore = data?.customFields?.user_create_id_core;
        let promises = [
          new ChatDetailController(this.roomId).getInfoCustoms(userCreateIdCore),
          new ChatDetailController(this.roomId).getInfoNotesCustom({
            roomId: this.roomId,
            offset: this.offsetNote,
            count: this.limitNote,
          }),
          new ChatDetailController(this.roomId).getReportCustomer(userCreateIdCore),
          new ChatDetailController(this.roomId).getHistoryCustomer(userCreateIdCore),
        ];

        const dataInfo = await Promise.all(promises);
        let dataMerchant = dataInfo[0];
        let dataListNote = dataInfo[1];
        let dataReport = dataInfo[2];
        let dataHistory = dataInfo[3];
        if (dataMerchant.s) {
          this.infoMerchant = dataMerchant.data;
        }
        if (dataListNote.s) {
          this.listNote = dataListNote.data.data;
          this.totalNote = dataListNote.data.total;
          this.totalPageNote = dataListNote.data.total_page;
          this.setCollapsed(this.listNote)
        }
        if (dataReport.s) {
          if (dataReport) {
            this.dataReport = this.dataReport.map((item) => ({
              name: item.name,
              value: dataReport?.data[item.key] || 0,
              key: item.key
            }))
          }
        }
        if (dataHistory.s) {
          this.dataHistory = dataHistory.data;
        }
      } else {
        this.listNote = [];
        this.totalNote = 0;
        this.totalPageNote = 0;
        this.infoMerchant = null;
      }

    },
    setCollapsed(data) {
      data.forEach(element => {
        this.isCollapsed.push(true)
      });
    },
    async getListNote() {
      let promises = [
        new ChatDetailController(this.roomId).getInfoNotesCustom({
          roomId: this.roomId,
          offset: this.offsetNote,
          count: this.limitNote,
        }),
      ];

      const dataInfo = await Promise.all(promises);
      let dataListNote = dataInfo[0];
      if (dataListNote.s) {
        this.listNote = this.listNote.concat(dataListNote.data.data)
        this.totalNote = dataListNote.data.total;
        this.totalPageNote = dataListNote.data.total_page;
        this.setCollapsed(dataListNote.data.data)
      }
    },
    createNote() {
      this.note.user_name = this.account.display_name;
      this.note.description = null;
      this.isCreate = true;
    },
    async submit() {
      if (this.isCreateTing) {
        return;
      }
      this.isCreateTing = true;
      const dataInfo = await new ChatDetailController(
        this.roomId
      ).addNoteForCustomer(this.note);
      this.isCreateTing = false;
      this.isCreate = false;
      if (dataInfo.success && dataInfo.data) {
        this.toastCustom('Thêm ghi chú thành công')
        let newNote = dataInfo.data;
        newNote.user_name = this.account.display_name;
        this.listNote.unshift(newNote);
        this.note.description = null;
        this.conversationDetail()
      } else {
        this.toastCustom('Có lỗi xảy ra, vui lòng thử lại!', "error")
      }
    },
    searchInputOnEnter() {
      this.activeSave = true;
    },
    removeItem(item = null) {
      this.showModalTrash = true;
      this.itemDelete = item;
    },
    async removeNote(val) {
      this.showModalTrash = val;
      if (this.itemDelete) {
        const resultDelete = await new ChatDetailController(
          this.roomId
        ).deleteNote(this.itemDelete.id);
        if (resultDelete.success) {
          let index = this.listNote.findIndex(
            (item) => item.id == this.itemDelete.id
          );
          if (index > -1) {
            this.listNote.splice(index, 1);
          }
          this.toastCustom('Xóa ghi chú thành công')
          this.conversationDetail()
        } else {
          this.toastCustom('Có lỗi xảy ra, vui lòng thử lại!', "error")
        }
      }
    },
    toggleCollapse(note, index) {
      this.isCollapsed[index] = !this.isCollapsed[index];
      this.isCollapsed = JSON.parse(JSON.stringify(this.isCollapsed));
    },
    buttonLabel(note, index) {
      return this.isCollapsed[index] ? "Xem thêm" : "Thu gọn";
    },
    shouldTruncate(note, index) {
      return this.getNumberOfLines(note.description) > 3;
    },
    getNumberOfLines(text) {
      const lineHeight = 19; // Điều chỉnh giá trị này cho phù hợp với CSS của bạn
      const containerHeight = 3 * lineHeight;
      const dummyElement = document.createElement("div");
      dummyElement.innerHTML = text;
      dummyElement.style.height = "auto";
      dummyElement.style.position = "absolute";
      dummyElement.style.visibility = "hidden";
      dummyElement.style.width = "25%";
      document.body.appendChild(dummyElement);
      const height = dummyElement.offsetHeight;
      document.body.removeChild(dummyElement);
      return Math.ceil(height / lineHeight);
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        let offsetPage = this.offsetNote + 10
        if ((offsetPage / 10) + 1 <= this.totalPageNote) {
          this.offsetNote = offsetPage
          this.getListNote()
        }
      }
    },
    buildTextName(key) {
      switch (key) {
        case 'order':
          return 'Đơn đặt hàng gần nhất';
        case 'rfq_request':
          return 'Yêu cầu hỏi mua gần nhất';
        case 'invoice':
          return 'Yêu cầu hoàn tiền gần nhất';
        default:
          return '';
      }
    },
    buildUrl(data) {
      let url = process.env.VUE_APP_CORE_OPERATOR_URL;
      switch (data.key) {
        case 'order':
          return `${url}/orders?order_code=${data.code}`;
        case 'rfq_request':
          return `${url}/rfq-service/quotation-request?key_search=${data.code}`;
        case 'invoice':
          return `${url}/invoice?code=${data.code}`;
        default:
          return url + '/';
      }
    },
    buildTextStatus(data) {
      switch (data.key) {
        case 'order':
          return OrderStatusText[data.status];
        case 'rfq_request':
          return QuotationRequestStatusText[data.status];
        case 'invoice':
          return InvoiceStatusText[data.status];
        default:
          return '';
      }
    },
    buildClassStatus(data) {
      switch (data.key) {
        case 'order':
          return OrderStatusClassName[data.status];
        case 'rfq_request':
          return QuotationRequestStatusClassName[data.status];
        case 'invoice':
          return InvoiceStatusClassName[data.status];
        default:
          return '';
      }
    },
  },
})
export default class ChatList extends Vue { }
</script>
