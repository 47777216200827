<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_27290_4022)">
      <path
        d="M4.75 7.5C6.26878 7.5 7.5 6.26878 7.5 4.75C7.5 3.23122 6.26878 2 4.75 2C3.23122 2 2 3.23122 2 4.75C2 6.26878 3.23122 7.5 4.75 7.5Z"
        fill="#9CA5B3"
      />
      <path
        d="M11.25 7.5C12.7688 7.5 14 6.26878 14 4.75C14 3.23122 12.7688 2 11.25 2C9.73122 2 8.5 3.23122 8.5 4.75C8.5 6.26878 9.73122 7.5 11.25 7.5Z"
        fill="#9CA5B3"
      />
      <path
        d="M4.75 14C6.26878 14 7.5 12.7688 7.5 11.25C7.5 9.73122 6.26878 8.5 4.75 8.5C3.23122 8.5 2 9.73122 2 11.25C2 12.7688 3.23122 14 4.75 14Z"
        fill="#9CA5B3"
      />
      <path
        d="M13 10.75H11.75V9.5C11.75 9.36739 11.6973 9.24021 11.6036 9.14645C11.5098 9.05268 11.3826 9 11.25 9C11.1174 9 10.9902 9.05268 10.8964 9.14645C10.8027 9.24021 10.75 9.36739 10.75 9.5V10.75H9.5C9.36739 10.75 9.24021 10.8027 9.14645 10.8964C9.05268 10.9902 9 11.1174 9 11.25C9 11.3826 9.05268 11.5098 9.14645 11.6036C9.24021 11.6973 9.36739 11.75 9.5 11.75H10.75V13C10.75 13.1326 10.8027 13.2598 10.8964 13.3536C10.9902 13.4473 11.1174 13.5 11.25 13.5C11.3826 13.5 11.5098 13.4473 11.6036 13.3536C11.6973 13.2598 11.75 13.1326 11.75 13V11.75H13C13.1326 11.75 13.2598 11.6973 13.3536 11.6036C13.4473 11.5098 13.5 11.3826 13.5 11.25C13.5 11.1174 13.4473 10.9902 13.3536 10.8964C13.2598 10.8027 13.1326 10.75 13 10.75Z"
        fill="#9CA5B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_27290_4022">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconIndustry extends Vue {}
</script>
