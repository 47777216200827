import MESSAGE_TYPE from "@/constants/message-type";
import { groupBy } from "lodash";
import {
  getMessageTypeAndValue,
  getPathImageMessage,
} from "@/helpers/message.helper";
class ChatDetailTransformer {
  parseDate(date: string) {
    const [day, month, year] = date.split("/");
    return new Date(`${month}/${day}/${year}`).getTime();
  }

  groupByDate(payload: any[]) {
    const messages = groupBy(payload, "date");
    const sortedDate = Object.keys(messages).sort(
      (a, b) => this.parseDate(b) - this.parseDate(a)
    );

    const sortedMessages = new Map();

    sortedDate.forEach((date) => {
      const sortedMessageItem = messages[date].sort((a, b) => b.ts - a.ts);
      sortedMessages.set(date, sortedMessageItem.reverse());
    });

    return sortedMessages;
  }

  groupByUser(payload: any[], ownerRoom: string) {
    const sortedMessages = new Map();
    let currentUser = "";
    let currentKey = "";

    payload.forEach((item, key) => {
      const user = item.user;
      const userId = user._id;
      const userName = user.name;
      if (userId !== currentUser) {
        currentKey = userId + "_" + key;
        currentUser = userId;
      }
      item.receive = userId == ownerRoom;
      const currentData =
        sortedMessages && sortedMessages.get(currentKey)
          ? sortedMessages.get(currentKey)
          : {
              _id: userId,
              key: currentKey,
              author: userName,
              receive: item.receive,
              messages: [],
            };
      currentData.messages.push(item);
      sortedMessages.set(currentKey, currentData);
    });

    return sortedMessages;
  }

  transform(payload: any[]) {
    return payload.map((item) => this.transformItem(item));
  }

  getMessageByType(type: string, payload: any) {
    switch (type) {
      case MESSAGE_TYPE.PLAIN_TEXT:
        return payload.msg.split("\n").join("<br/>");

      case MESSAGE_TYPE.LINK:
      case MESSAGE_TYPE.PRODUCT:
      case MESSAGE_TYPE.ORDER:
        return payload.msg;

      case MESSAGE_TYPE.IMAGE:
        return getPathImageMessage(
          payload.attachments[0].title_link || payload.attachments[0].image_url
        );
      default:
        return "Đã gửi một định dạng chưa được hỗ trợ";
    }
  }

  transformItem(payload: any) {
    const account = window.CHAT_SDK.$appRoot.$store.state.account;
    const date = new Date(payload.ts);
    const dateStr = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;

    const { type } = getMessageTypeAndValue(payload);

    return {
      _id: payload._id,
      type: type,
      content: this.getMessageByType(type, payload),
      receive: payload.u.username !== account.user_name,
      ts: payload.ts,
      date: dateStr,
      user: payload.u,
    };
  }
}

export default new ChatDetailTransformer();
