var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat-detail__block"},[_c('div',{staticClass:"chat-detail__block-date"},[_vm._m(0),_c('div',{staticClass:"item-date"},[_vm._v(_vm._s(_vm.date))]),_vm._m(1)]),_vm._l((_vm.dataSource.keys()),function(key){return _c('div',{key:'group' + key,staticClass:"chat-detail__block-content"},[(!_vm.dataSource.get(key).receive)?_c('div',{staticClass:"author"},[_vm._v(" Được hỗ trợ bởi "),_c('span',{staticClass:"author__name"},[_vm._v(_vm._s(_vm.dataSource.get(key).author ? _vm.dataSource.get(key).author : ""))])]):_vm._e(),_vm._l((_vm.dataSource.get(key).messages),function(message){return _c('chat-detail-item',{key:message._id,attrs:{"message":message}})}),(
        _vm.dataSource.get(key).messages &&
        _vm.dataSource.get(key).messages[_vm.dataSource.get(key).messages.length - 1]
          .status_read &&
        key == _vm.dataSource.length - 1
      )?_c('div',{staticClass:"read-time"},[_vm._v(" Đã đọc lúc "+_vm._s(_vm.dataSource.get(key).messages[_vm.dataSource.get(key).messages.length - 1] ?.read_at)+" ")]):_vm._e()],2)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item__cross"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item__cross"})])
}]

export { render, staticRenderFns }