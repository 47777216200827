import MESSAGE_TYPE from "@/constants/message-type";
import { getMessageTypeAndValue } from "@/helpers/message.helper";
import { RoomType } from "@/enums/room.enum";

class ChatListTransformer {
  transform(payload: any[]) {
    const ims = payload.map((item) => this.transformItem(item));
    const result = new Map();
    ims.forEach((im: any) => result.set(im.id, im));
    return result;
  }

  roomTransform(payload: Map<string, any>) {
    const rooms = new Map();

    Array.from(payload.keys())
      .map((item: any) => this.roomTransformItem(payload.get(item)))
      .filter(
        (item: any) =>
          !(item.type === RoomType.DIRECT && !item.lastMessage) &&
          item.type !== RoomType.PRIVATE &&
          item.name
      )
      .sort((a: any, b: any) => {
        const aHasLastMessage = !!a.lastMessage;
        const bHasLastMessage = !!b.lastMessage;

        if (aHasLastMessage && !bHasLastMessage) {
          return -1;
        } else if (!aHasLastMessage && bHasLastMessage) {
          return 1;
        } else if (!aHasLastMessage && !bHasLastMessage) {
          return 0;
        } else {
          return (
            new Date(b.lastMessage.ts).getTime() -
            new Date(a.lastMessage.ts).getTime()
          );
        }
      })
      .forEach((item: any) => rooms.set(item.id, item));

    return rooms;
  }

  getMessageByType(type: string, payload: any) {
    switch (type) {
      case MESSAGE_TYPE.PLAIN_TEXT:
        return payload.msg.split("\n")[0];
      // return payload.md[0].value[0].value;
      case MESSAGE_TYPE.LINK:
      case MESSAGE_TYPE.PRODUCT:
        return "Đã gửi một liên kết";

      case MESSAGE_TYPE.IMAGE:
        return "Đã gửi một ảnh";

      case MESSAGE_TYPE.ORDER:
        return "Đã gửi 1 đơn hàng";

      default:
        return "Đã gửi một định dạng chưa được hỗ trợ";
    }
  }

  getStatusRoom(customFields: any) {
    if (!customFields) {
      return "";
    }
    return customFields.status;
  }

  transformItem(payload: any) {
    const account = window.CHAT_SDK.$appRoot.$store.state.account;
    const { im, user, update } = payload;
    const { type, value } = getMessageTypeAndValue(im.lastMessage);
    const lastMessage = im.lastMessage
      ? this.getMessageByType(type, im.lastMessage)
      : "";
    const lastMessageTs = im.lastMessage ? im.lastMessage.ts : im._updatedAt;
    const receive =
      im.lastMessage && im.lastMessage.u.username !== account.user_name;
    const isOpsRep =
      im.lastMessage &&
      !im.lastMessage.u.username.toLowerCase().includes("marketplace");
    const userCurator =
      im.customFields && im.customFields.userCurator
        ? im.customFields.userCurator
        : null;
    const statusRoom = this.getStatusRoom(im.customFields);

    const displayUsername = im.name || user.name;

    return {
      id: im._id,
      name: im.name,
      statusRoom: statusRoom,
      customFields: im.customFields,
      userCurator: userCurator,
      unread: update ? update.unread : 0,
      updatedAt: im._updatedAt,
      lastMessage: {
        type: type,
        msg: lastMessage,
        ts: lastMessageTs,
      },
      receive: receive,
      isOpsRep: isOpsRep,
      user: {
        _id: user ? user._id : "",
        name: displayUsername,
        username: user ? user.username : "",
        status: user ? user.status : "",
        avatar: process.env.VUE_APP_API_HOST + "avatar/" + im.username,
      },
    };
  }

  getRoomName(sub: any) {
    switch (sub.t) {
      case RoomType.DIRECT:
        return sub.fname;
      case RoomType.PRIVATE:
        return sub.name;
      case RoomType.CHANNEL:
        return sub.customFields.description;
    }
  }

  getLastMessage(room: any) {
    if (!room.lastMessage) return null;

    const { type } = getMessageTypeAndValue(room.lastMessage);

    return {
      type: type,
      msg: this.getMessageByType(type, room.lastMessage),
      ts: room.lastMessage.ts,
    };
  }

  getReceive(room: any) {
    if (!room.lastMessage) return null;

    const account = window.CHAT_SDK.$appRoot.$store.state.account;
    return room.lastMessage.u.username !== account.user_name;
  }

  getAvatar(sub: any) {
    switch (sub.t) {
      case RoomType.DIRECT:
        return process.env.VUE_APP_API_HOST + "avatar/" + sub.name;
      case RoomType.PRIVATE:
        return process.env.VUE_APP_API_HOST + "avatar/" + sub.name;
      case RoomType.CHANNEL:
        return process.env.VUE_APP_API_HOST + "avatar/room/" + sub.rid;
    }
  }

  getSupplierName(sub: any) {
    if (sub.t === RoomType.DIRECT) {
      return sub.name;
    }
    return undefined;
  }

  getSupplierReferId(sub: any) {
    if (sub.t === RoomType.DIRECT) {
      return +sub.name.replace("supplier", "");
    }
    return undefined;
  }

  roomTransformItem(payload: any) {
    const { room, sub } = payload;

    return {
      id: room._id,
      name: this.getRoomName(sub),
      unread: sub.unread,
      lastMessage: this.getLastMessage(room),
      receive: this.getReceive(room),
      avatar: this.getAvatar(sub),
      type: room.t,
      supplier_refer_id: this.getSupplierReferId(sub),
      supplier_name: this.getSupplierName(sub),
      updatedAt: room._updatedAt,
      isCustomerService: room.customFields?.type === "customer_service",
    };
  }
}

export default new ChatListTransformer();
