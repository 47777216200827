import { Component, Vue } from "vue-property-decorator";
import Toasted from "vue-toasted";
Vue.use(Toasted);
import ToastSuccess from "@/assets/images/toast-success.svg";
import ToastFail from "@/assets/images/toast-fail.svg";

@Component({
  components: {
    ToastSuccess: ToastSuccess,
    ToastFail: ToastFail,
  },
})
export default class CommonMixin extends Vue {
  public toastCustom(message = "", type = "success", duration = 1000) {
    //type in 'success', 'warning', 'error'
    let customHTML = "";
    switch (type) {
      case "success":
        customHTML =
          `<div class="icon-content icon-success"><img class="" src="${ToastSuccess}"></div>` +
          `<ToastSuccess />`;
        break;
      case "error":
        customHTML =
          `<div class="icon-content icon-success"><img class="" src="${ToastFail}"></div>` +
          `<ToastSuccess />`;
        break;
      case "warning":
        customHTML = `<div class="icon-content icon-warning"><img src="/imgs/toast-warning.svg"></div>`;
        break;
      case "infor":
        customHTML = `<div class="icon-content icon-infor"><img src="/imgs/toast-infor.svg"></div>`;
        break;
      default:
        break;
    }
    const msg = `${customHTML}<div class="toasting-content">${message}</div>`;
    const classCss = ["toasting", "toast-custom"];
    classCss.push(type);
    Vue.toasted.show(msg, {
      className: classCss,
      position: "top-center",
      duration: duration,
      action: {
        text: "X",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });
  }

  public formatPrice(amount: number | string, decimalCount = 2) {
    try {
      return parseFloat(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toLocaleString("en-US");
    } catch (e) {
      console.log(e);
      return amount;
    }
  }

  public replaceLinkMessage(message: string): string {
    return message.replaceAll(
      /(https?:\/\/[^\s]+)/g,
      '<a href="$1" target="_blank">$1</a>'
    );
  }
}
