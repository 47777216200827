<template>
  <div class="filter-search">
    <div class="filter-search__sample-message-search">
      <div class="header">
        <div class="title">Tin nhắn mẫu đã lưu</div>
        <div
          class="link-list"
          @click.prevent="
            showBlockListSampleMessage = !showBlockListSampleMessage
          "
        >
          <a href="#">Quản lý</a>
        </div>
      </div>
      <div class="search-input">
        <input
          @keyup.enter="filterData"
          class="search-input__input-search"
          type="text"
          v-model="keySearchData"
          placeholder="Tìm tin nhắn mẫu"
        />
        <span class="search-input__search-icon">
          <IconSearch />
        </span>
      </div>
      <div class="block-content">
        <ul class="item-list" v-if="dataFilter.length" @scroll="handleScroll">
          <li
            v-for="item in dataFilter"
            :key="item.id"
            class="item-list__item"
            @click.prevent="chooseMessage(item)"
          >
            <div class="item__name">
              <div class="item__title-message">
                {{ item.title }}
              </div>
              <div class="item__content-message">
                {{ item.content }}
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="no-content">Chưa có tin nhắn mẫu</div>
      </div>
      <div class="block-footer" @click.prevent="checkCreateSampleMessage">
        <div class="icon-plus">
          <IconPlus />
        </div>
        <a href="#">Thêm tin nhắn mẫu</a>
      </div>
    </div>
    <div v-if="showBlockCreateSampleMessage">
      <SampleMessageCreate
        @closeModalCreate="closeModal"
        @triggerCreate="triggerCreate()"
      />
    </div>
    <div v-if="showBlockListSampleMessage">
      <SampleMessageList
        @closeModalList="closeModal"
        @updateModalList="triggerCreateFromList()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import IconAngleDown from "@/assets/images/operator/iconAngleDown.vue";
import IconChecked from "@/assets/images/operator/iconChecked.vue";
import IconSearch from "@/assets/images/operator/iconSearch.vue";
import ChatDetailMixin from "@/mixins/operator/ChatDetail.mixin";
import IconPlus from "@/assets/images/operator/iconPlus.vue";
import ModalConfirm from "@/scopes/operator/components/modal/ModalConfirm.vue";
import CommonMixin from "@/mixins/operator/Common.mixin";
import SampleMessageCreate from "@/scopes/operator/components/modal/SampleMessageCreate.vue";
import SampleMessageList from "@/scopes/operator/components/modal/SampleMessageList.vue";
import { checkPermission } from "@/helpers/permission.helper";
import PERMISSION from "@/constants/permission";

@Component({
  components: {
    SampleMessageList,
    SampleMessageCreate,
    ModalConfirm,
    IconPlus,
    IconAngleDown,
    IconChecked,
    IconSearch,
  },
})
export default class FilterSampleMessage extends Mixins(
  ChatDetailMixin,
  CommonMixin
) {
  keySearchData = "";
  dataFilter: any[] = [];
  page = 1;
  totalPage = 1;
  isLoadingMore = true;
  showBlockCreateSampleMessage = false;
  showBlockListSampleMessage = false;
  filterData() {
    this.page = 1;
    this.getData();
  }

  chooseMessage(message: any) {
    this.$emit("dataMessage", message);
  }

  async getData() {
    const response = await this.listSampleMessages({
      keyword: this.keySearchData,
      page: this.page,
    });

    this.page = response.page;
    this.totalPage = response.total_page;
    if (response.data.length && Number(response.page) > 1) {
      this.dataFilter.push(...response.data);
    } else {
      this.dataFilter = response.data;
    }
  }

  created() {
    this.getData();
  }

  handleScroll(event: Event) {
    const { target } = event as unknown as { target: HTMLElement };
    const { scrollTop, clientHeight, scrollHeight } = target;

    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      let nextPage = this.page + 1;
      if (nextPage <= this.totalPage) {
        this.page = nextPage;
        this.getData();
      }
    }
  }

  closeModal(data: any) {
    if (data && data.type == "remove") {
      this.dataFilter = this.dataFilter.filter((v) => v.id != data.id);
      return;
    }
    if (data && Object.keys(data).length) {
      let index = this.dataFilter.findIndex((v) => v.id == data.id);
      if (index > -1) {
        this.dataFilter[index] = data;
      }
    }
    this.showBlockCreateSampleMessage = false;
    this.showBlockListSampleMessage = false;
    this.$emit("triggerCreate", false);
  }

  triggerCreate() {
    this.keySearchData = "";
    this.page = 1;
    this.getData();
    this.showBlockCreateSampleMessage = false;
  }

  triggerCreateFromList() {
    this.$emit("triggerCreate", true);
    this.showBlockListSampleMessage = false;
    this.showBlockCreateSampleMessage = true;
  }

  async checkCreateSampleMessage() {
    const hasPermission = await checkPermission(
      PERMISSION.CHAT_CREATE_SAMPLE_MESSAGE
    );
    if (!hasPermission) {
      this.toastCustom("Bạn không có quyền truy cập tính năng này", "error");
      return;
    }
    this.showBlockCreateSampleMessage = !this.showBlockCreateSampleMessage;
  }
}
</script>
