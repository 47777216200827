<template>
  <div class="chat-attachment-product">
    <div class="chat-attachment-product__image">
      <img :src="data.image" alt="" @error="handleErrorImg" />
    </div>

    <div class="chat-attachment-product__info">
      <div class="chat-attachment-product__name">{{ data.name }}</div>

      <div class="chat-attachment-product__packing">{{ data.packing }}</div>

      <div class="chat-attachment-product__price">
        {{ data.price | currency }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { IProductAttachment } from "@/interfaces/marketplace/message.interface";
import PreviewImage from "@/assets/images/marketplace/preview.svg";

@Component
export default class ProductAttachment extends Vue {
  @Prop({ required: true }) private readonly data!: IProductAttachment;

  public handleErrorImg(event: any) {
    event.target.src = PreviewImage;
  }
}
</script>
